import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'aphrodite/no-important'
import Link from '../containers/link'
import styles from './header-menu-item.styles'

const HeaderMenuItemInactive = ({href, children}) => {
  return (
    <Link href={href} className={css(styles.inactive)}>
      {/* (Label == children)
        * The active menu label is slightly larger than its inactive conterpart.
        * The menu items adjust based on the width its labels. To not have the
        * menu items width jump when switching from active to inactive we make
        * sure that the inactive menu item is the same width as the active
        * menu item by creating a hidden active label that will set the width.
        */}
      <div className={css(styles.labelActive)} style={{maxHeight: '0px', overflow: 'hidden'}}>
        {children}
      </div>
      <div className={css(styles.labelInactive)}>{children}</div>
      <div className={css(styles.stateIndicatorInactive)} />
    </Link>
  )
}

HeaderMenuItemInactive.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
}

const HeaderMenuItemActive = ({children}) => {
  // eslint-disable-line react/no-multi-comp
  return (
    <div className={css(styles.active)}>
      <div className={css(styles.labelActive)}>{children}</div>
      <div className={css(styles.stateIndicatorActive)} />
    </div>
  )
}

HeaderMenuItemActive.propTypes = {
  children: PropTypes.string.isRequired,
}

const HeaderMenuItem = (props) => {
  // eslint-disable-line react/no-multi-comp
  return props.active ? <HeaderMenuItemActive {...props} /> : <HeaderMenuItemInactive {...props} />
}

HeaderMenuItem.propTypes = {
  active: PropTypes.bool.isRequired,
}

export default HeaderMenuItem
