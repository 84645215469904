import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import RouteNotFound from '../components/route-not-found'
import {getIsAdmin, getIsLoading, getLocationPath} from '../selectors'
import {
  emptyRoute,
  singleSignOnRoute,
  loginRoute,
  profileRoute,

  domainsRoute,
  domainVersionsRoute,

  adminRoute,
  adminDomainPublicationRoute,
  adminRealtimeRoute,
  adminSensorEventsRoute,
  adminSensorEventsRoomIdRoute,
  adminDevicesRoute,
  adminDeviceRoute,
  adminJobRoute,
  adminJobsRoute,
  adminGraphiqlRoute,
  adminVersionRoute,
  adminVersionsRoute,
  adminHealthStatusesRoute,
  // adminDomainConfigsRoute,
  // adminDomainConfigRoute,
  adminResourceRoute,
  adminUserAnalyticsRoute,
  adminUserListRoute,
} from '../routes'
import SingleSignOn from './single-sign-on'
import Loading from './loading'
import Login from './login'
import Profile from './profile'
import Domains from './domains'
import DomainVersions from './domain-versions'
// import AdminDomainConfigs from './admin-domain-configs'
// import AdminDomainConfig from './admin-domain-config'
import AdminResource from './admin-resource'
import AdminRealtime from './admin-realtime'
import AdminSensorEvents from './admin-sensor-events'
import AdminSensorEventsRoom from './admin-sensor-events-room'
import AdminDevices from './admin-devices'
import AdminDevice from './admin-device'
import AdminJob from './admin-job'
import AdminJobs from './admin-jobs'
import AdminGraphiql from './admin-graphiql'
import AdminVersion from './admin-version'
import AdminVersions from './admin-versions'
import AdminPublication from './admin-publication'
import AdminContainer from './admin-container'
import AdminHealthStatuses from './admin-health-statuses'
import AdminUserAnalytics from './admin-user-analytics'
import AdminUserList from './admin-user-list'


const Router = ({isAdmin, isLoading, path}) => {
  if (isLoading) {return <Loading />}

  const loginRouteMatch = loginRoute.match(path)
  if (loginRouteMatch) {
    return <Login />
  }

  const singleSignOnRouteMatch = singleSignOnRoute.match(path)
  if (singleSignOnRouteMatch) {
    return <SingleSignOn />
  }

  const profileRouteMatch = profileRoute.match(path)
  if (profileRouteMatch) {
    return (
      <AdminContainer>
        <Profile />
      </AdminContainer>
    )
  }

  const domainsRouteMatch = domainsRoute.match(path)
  if (domainsRouteMatch) {
    return isAdmin ? (
      <AdminContainer>
        <Domains {...domainsRouteMatch} />
      </AdminContainer>
    ) : <div>Not authorized</div>
  }

  const domainVersionsRouteMatch = domainVersionsRoute.match(path)
  if (domainVersionsRouteMatch) {
    return isAdmin ? (
      <AdminContainer>
        <DomainVersions {...domainVersionsRouteMatch} />
      </AdminContainer>
    ) : <div>Not authorized</div>
  }

  const adminResourceRouteMatch = adminResourceRoute.match(path)
  if (adminResourceRouteMatch) {
    return isAdmin ? (
      <AdminContainer>
        <AdminResource {...adminResourceRouteMatch} />
      </AdminContainer>
    ) : <div>Not authorized</div>
  }

  // const adminDomainConfigsRouteMatch = adminDomainConfigsRoute.match(path)
  // if (adminDomainConfigsRouteMatch) {
  //   return isAdmin ?
  //     <AdminContainer>
  //       <AdminDomainConfigs />
  //     </AdminContainer> :
  //     <div>Not authorized</div>
  // }

  const emptyRouteMatch = emptyRoute.match(path)
  const adminRouteMatch = adminRoute.match(path)
  const adminJobsRouteMatch = adminJobsRoute.match(path)
  if (emptyRouteMatch || adminRouteMatch || adminJobsRouteMatch) {
    return isAdmin ?
      <AdminContainer>
        <AdminJobs />
      </AdminContainer> :
      <div>Not authorized</div>
  }

  const adminJobRouteMatch = adminJobRoute.match(path)
  if (adminJobRouteMatch) {
    return isAdmin ?
      <AdminContainer>
        <AdminJob {...adminJobRouteMatch} />
      </AdminContainer> :
      <div>Not authorized</div>
  }

  if (adminRealtimeRoute.match(path)) {
    return isAdmin ?
      <AdminContainer>
        <AdminRealtime />
      </AdminContainer> :
      <div>Not authorized</div>
  }

  const adminSensorEventsRouteMatch = adminSensorEventsRoute.match(path)
  if (adminSensorEventsRouteMatch) {
    return isAdmin ?
      <AdminContainer>
        <AdminSensorEvents />
      </AdminContainer> :
      <div>Not authorized</div>
  }

  const adminHealthStatusesRouteMatch = adminHealthStatusesRoute.match(path)
  if (adminHealthStatusesRouteMatch) {
    return isAdmin ?
      <AdminContainer>
        <AdminHealthStatuses />
      </AdminContainer> :
      <div>Not authorized</div>
  }
  const adminUserAnalyticsMatch = adminUserAnalyticsRoute.match(path)
  if (adminUserAnalyticsMatch) {
    return isAdmin ?
      <AdminContainer>
        <AdminUserAnalytics />
      </AdminContainer> :
      <div>Not authorized</div>
  }
  const adminUserListMatch = adminUserListRoute.match(path)
  if (adminUserListMatch) {
    return isAdmin ?
      <AdminContainer>
        <AdminUserList />
      </AdminContainer> :
      <div>Not authorized</div>
  }

  const adminSensorEventsRoomIdRouteMatch = adminSensorEventsRoomIdRoute.match(path)
  if (adminSensorEventsRoomIdRouteMatch) {
    return isAdmin ?
      <AdminContainer>
        <AdminSensorEventsRoom />
      </AdminContainer> :
      <div>Not authorized</div>
  }

  const adminDevicesRouteMatch = adminDevicesRoute.match(path)
  if (adminDevicesRouteMatch) {
    return isAdmin ?
      <AdminContainer>
        <AdminDevices />
      </AdminContainer> :
      <div>Not authorized</div>
  }

  const adminDeviceRouteMatch = adminDeviceRoute.match(path)
  if (adminDeviceRouteMatch) {
    return isAdmin ?
      <AdminContainer>
        <AdminDevice />
      </AdminContainer> :
      <div>Not authorized</div>
  }

  const adminGraphiqlRouteMatch = adminGraphiqlRoute.match(path)
  if (adminGraphiqlRouteMatch) {
    return isAdmin ?
      <AdminContainer>
        <AdminGraphiql />
      </AdminContainer> :
      <div>Not authorized</div>
  }

  const adminVersionRouteMatch = adminVersionRoute.match(path)
  if (adminVersionRouteMatch) {
    return isAdmin ?
      <AdminContainer>
        <AdminVersion {...adminVersionRouteMatch} />
      </AdminContainer> :
      <div>Not authorized</div>
  }

  const adminVersionsRouteMatch = adminVersionsRoute.match(path)
  if (adminVersionsRouteMatch) {
    return isAdmin ?
      <AdminContainer>
        <AdminVersions />
      </AdminContainer> :
      <div>Not authorized</div>
  }

  const adminDomainPublicationRouteMatch = adminDomainPublicationRoute.match(path)
  if (adminDomainPublicationRouteMatch) {
    return isAdmin ?
      <AdminContainer>
        <AdminPublication {...adminDomainPublicationRouteMatch} />
      </AdminContainer> :
      <div>Not authorized</div>
  }

  return <RouteNotFound />
}

Router.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    isAdmin: getIsAdmin(state),
    isLoading: getIsLoading(state),
    path: getLocationPath(state) || '',
  }
}

export default connect(mapStateToProps)(Router)
