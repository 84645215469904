import {StyleSheet} from 'aphrodite'

export default StyleSheet.create({
  vr: {
    minWidth: '20px',
    maxWidth: '40px',
    flex: '0 1 40px',
  },
  buttonContainer: {
    display: 'flex',
    marginRight: '30px',
  },
  button: {
    flex: '0 0 auto',
    height: '30px',
    margin: '8px 10px 0 0',
    padding: '0 10px',
    borderRadius: '4px',
    fontSize: '11px',
    lineHeight: '30px',
    color: '#fff',
    fontWeight: '700',
    textTransform: 'uppercase',
    backgroundColor: '#12aaeb',
    boxShadow: '0 1px 3px rgba(77,77,77,0.1)',
    cursor: 'pointer',
    ':hover': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
    ':focus': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
    ':active': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
  },
  activeButton: {
    backgroundColor: '#b6b6b6',
  },
  inputContainer: {
    display: 'flex !important',
    flex: '1 1 auto !important',
    margin: '8px 10px 0 0',
    height: '30px',
  },
  label: {
    display: 'flex',
    flex: '1 1 100%',
    alignItems: 'center',
  },
  labelTextClass: {
    display: 'block',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '20px',
    textTransform: 'uppercase',
    color: '#b4bcc3',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  input: {
    flex: '1 1 auto',
    width: '30px',
    height: '30px',
    margin: '0 20px 0 10px',
    padding: '0 10px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '30px',
    color: '#777',
    background: '#f5f5f5',
    boxShadow: '0 1px 3px rgba(77,77,77,0.3)',
    ':-ms-input-placeholder': {
      color: '#b6b6b6',
    },
    '::-moz-placeholder': {
      color: '#b6b6b6',
    },
    '::-webkit-input-placeholder': {
      color: '#b6b6b6',
    },
    ':hover': {
      opacity: '0.8',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
    ':focus': {
      opacity: '1',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
  },
})
