// export const POST_VERSIONS = 'POST_VERSIONS'
// export const POST_VERSIONS_DONE = 'POST_VERSIONS_DONE'
export const CLEAR_SENSOR_EVENTS = 'CLEAR_SENSOR_EVENTS'
export const CLEAR_SENSOR_EVENTS_ROOM = 'CLEAR_SENSOR_EVENTS_ROOM'
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'
export const POST_JOB = 'POST_JOB'
export const POST_JOB_DONE = 'POST_JOB_DONE'
export const REMOVE_AUTHENTICATE_ERROR = 'REMOVE_AUTHENTICATE_ERROR'
export const REMOVE_EMAIL = 'REMOVE_EMAIL'
export const REMOVE_LOGIN_REDIRECT = 'REMOVE_LOGIN_REDIRECT'
export const REMOVE_PIN = 'REMOVE_PIN'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_ANALYTICS = 'SET_ANALYTICS'
export const SET_ANALYTICS_SEARCH_RESULT = 'SET_ANALYTICS_SEARCH_RESULT'
export const SET_ANALYTICS_SEARCH_TEXT = 'SET_ANALYTICS_SEARCH_TEXT'
export const SET_AUTHENTICATE_ERROR = 'SET_AUTHENTICATE_ERROR'
export const SET_AUTHENTICATION_BY_CREDENTIALS_ERROR = 'SET_AUTHENTICATION_BY_CREDENTIALS_ERROR'
export const SET_AUTHENTICATION_ERROR = 'SET_AUTHENTICATION_ERROR'
export const SET_AUTHORIZATION = 'SET_AUTHORIZATION'
export const SET_AUTHORIZED_ERROR = 'SET_AUTHORIZED_ERROR'
export const SET_CHANNELS = 'SET_CHANNELS'
export const SET_CHECKED_AUTHENTICATION = 'SET_CHECKED_AUTHENTICATION'
export const SET_CURRENT_AVAILABILITY = 'SET_CURRENT_AVAILABILITY'
export const SET_DEVICES = 'SET_DEVICES'
export const SET_FLOORPLANS = 'SET_FLOORPLANS'
export const SET_EMAIL = 'SET_EMAIL'
export const SET_EMAIL_CODE = 'SET_EMAIL_CODE'
export const SET_EXPANDED_JOB_ERROR_INFO = 'SET_EXPANDED_JOB_ERROR_INFO'
export const SET_EXPIRE_IN = 'SET_EXPIRE_IN'
export const SET_GIT_BRANCHES = 'SET_GIT_BRANCHES'
export const SET_IP_TOKEN = 'SET_IP_TOKEN'
export const SET_JOBS = 'SET_JOBS'
export const SET_LOCATION = 'SET_LOCATION'
export const SET_LOGIN_REDIRECT = 'SET_LOGIN_REDIRECT'
export const SET_NO_CONNECTION_REDIRECT = 'SET_NO_CONNECTION_REDIRECT'
export const SET_PENDING_AUTHENTICATION = 'SET_PENDING_AUTHENTICATION'
export const SET_PIN = 'SET_PIN'
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
export const SET_ROUTE = 'SET_ROUTE'
export const SET_SENSOR_EVENTS = 'SET_SENSOR_EVENTS'
export const SET_SENSOR_EVENTS_LOADING_END = 'SET_SENSOR_EVENTS_LOADING_END'
export const SET_SENSOR_EVENTS_LOADING_START = 'SET_SENSOR_EVENTS_LOADING_START'
export const SET_SENSOR_EVENTS_ROOM = 'SET_SENSOR_EVENTS_ROOM'
export const SET_SENSOR_EVENTS_ROOM_LOADING_END = 'SET_SENSOR_EVENTS_ROOM_LOADING_END'
export const SET_SENSOR_EVENTS_ROOM_LOADING_START = 'SET_SENSOR_EVENTS_ROOM_LOADING_START'
export const SET_TOKENS = 'SET_TOKENS'
export const SET_USER = 'SET_USER'
export const SET_USERS = 'SET_USERS'
export const SET_VERSIONS = 'SET_VERSIONS'
export const SIGN_OUT = 'SIGN_OUT'
export const TOGGLE_JOB_ERROR_INFO = 'TOGGLE_JOB_ERROR_INFO'
export const UPDATE_VERSION = 'UPDATE_VERSION'
export const RENEW_ACCESS_TOKEN = 'RENEW_ACCESS_TOKEN'

