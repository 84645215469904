import * as _ from 'immutable'

export const keyIn = (...keys) => {
  const keySet = _.Set(keys)
  return function (v, k) {
    return keySet.has(k)
  }
}

export const matchesProperty = (path, srcValue) => {
  const pathToFind = typeof path === 'string' ? [path] : path
  return function (v) {
    return v.getIn(pathToFind) === srcValue
  }
}
