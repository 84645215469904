import React from 'react'
import {css} from 'aphrodite/no-important'
import styles from './admin-sensor-events-header.styles'

const AdminSensorEventsHeader = () => {
  return (
    <div className={css(styles.header)}>
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItem)}>Sensor Id</div>
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItemDivider)} />
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItem)}>Sensor Type</div>
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItemDivider)} />
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItem, styles.headerItemFlex)}>Resource Id</div>
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItemDivider)} />
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItem, styles.headerItemFlex)}>Resource Name</div>
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItemDivider)} />
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItem)}>Event Type</div>
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItemDivider)} />
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItem)}>Rssi</div>
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItemDivider)} />
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItem, styles.headerItemTime)}>Time</div>
      <div className={css(styles.headerItemPadding)} />
    </div>
  )
}

export default AdminSensorEventsHeader
