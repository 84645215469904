import * as _ from 'immutable'
import * as types from '../action/types'

export default (state = _.Map(), action) => {
  // eslint-disable-line complexity
  switch (action.type) {
    case types.SET_SENSOR_EVENTS:
      return state.set('all', action.payload)
    case types.SET_SENSOR_EVENTS_LOADING_START:
      return state.set('isLoadingSensorEvents', true)
    case types.SET_SENSOR_EVENTS_LOADING_END:
      return state.set('isLoadingSensorEvents', false)
    case types.CLEAR_SENSOR_EVENTS:
      return state.set('all', _.List())
    case types.CLEAR_USER_DATA:
      return state
    default:
      return state
  }
}
