import React from 'react'
import {connect} from 'react-redux'
import {StyleSheet, css} from 'aphrodite/no-important'
import AdminRealtimeComponent from '../components/admin-realtime'

const styles = StyleSheet.create({
  header: {
    margin: '68px 0 16px',
    fontSize: '30px',
    lineHeight: '60px',
    fontWeight: '200',
    color: '#656559',
    textShadow: '0 1px 0 #fff',
  },

  container: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    padding: '0 0 40px',
  },
  padding: {
    display: 'block',
    flex: '0 1 60px',
  },
  content: {
    display: 'block',
    width: '100%',
    flex: '1 1 100%',
    overflow: 'hidden',
  },
})


const AdminRealtime = (props) => {
  return (
    <main className={css(styles.container)}>
      <div className={css(styles.padding)} />
      <div className={css(styles.content)}>
        <div className={css(styles.header)}>Subscriptions</div>
        <AdminRealtimeComponent {...props} />
      </div>
      <div className={css(styles.padding)} />
    </main>
  )
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.getIn(['session', 'access_token'], ''),
  }
}

export default connect(mapStateToProps, {})(AdminRealtime)
