import defaultQuery from './default-query'
import fetcher from './fetcher'
import getParameters from './get-parameters'
import updateQueryArguments from './update-query-arguments'

export default ({accessToken}, {token}) => {
  const parameters = getParameters()

  return {
    fetcher: fetcher.bind(undefined, token || accessToken),

    // OPTIONAL PARAMETERS
    // GraphQL artifacts
    query: parameters.query || defaultQuery,
    variables: parameters.variables,
    // response: '',

    // GraphQL Schema
    // If `undefined` is provided, an introspection query is executed
    // using the fetcher.
    // schema: undefined,

    // Useful to determine which operation to run
    // when there are multiple of them.
    operationName: parameters.operationName,
    // storage: null,
    // defaultQuery: null,

    // Custom Event Handlers
    onEditQuery: updateQueryArguments.bind(undefined, 'query'),
    onEditVariables: updateQueryArguments.bind(undefined, 'variables'),
    onEditOperationName: updateQueryArguments.bind(undefined, 'operationName'),

    // GraphiQL automatically fills in leaf nodes when the query
    // does not provide them. Change this if your GraphQL Definitions
    // should behave differently than what's defined here:
    // (https://github.com/graphql/graphiql/blob/master/src/utility/fillLeafs.js#L75)
    // getDefaultFieldNames: null,
  }
}
