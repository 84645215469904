import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  container: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'row',
    paddingLeft: '10px',
  },
})
