import config from 'config'
import getParameters from './get-parameters'

export default (accessToken, graphQLParams) => {
  const {channel, token, url} = getParameters()
  const channelQuery = channel ? `channel=${channel}` : ''
  const defaultURL = `${config.GRAPHQL}`

  return fetch(`${url || defaultURL}?${channelQuery}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token || accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(graphQLParams),
  })
    .then((r) => r.json())
    .catch((e) => {
      throw e
    })
}
