import {delay} from 'redux-saga'
import {call, put, take, fork, select} from 'redux-saga/effects' // eslint-disable-line import/order
import config from 'config' // eslint-disable-line import/no-unresolved
import * as types from '../action/types'
import setLoginRedirect from '../action/creators/set-login-redirect'
import {getLocation} from '../selectors/index'

// import setAuthenticationError from './set-authentication-error'
import clearUserData from '../action/creators/clear-user-data'
import setAccessToken from '../action/creators/set-access-token'
import setExpireIn from '../action/creators/set-expire-in'
import {setRefreshToken, removeRefreshToken} from '../services/auth'
import setCookie from '../utils/setCookie'
import setTokens from '../action/creators/set-tokens'


const fetchByRefreshToken = (refreshToken) => {
  return fetch(`${config.AUTH_URL}/token`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }),
  }).then((r) => {
    if (!r.ok && r.status == 400) {
      throw new Error('watch-authenticate-by-refresh: Unauthorized')
    }
    return r.json()
  })
}

const waitForOnLine = function* () {
  while (true) {
    if (window.navigator.onLine) {
      break
    }
    yield delay(1000 * 3)
  }
}

export default function* (history) {
  while (true) {
    const {payload: refreshToken} = yield take(types.SET_REFRESH_TOKEN)
    yield call(waitForOnLine)
    try {
      if (refreshToken) {
        const {expires_in, access_token, refresh_token} = yield call(fetchByRefreshToken, refreshToken)
        if (expires_in && access_token) {
          // eslint-disable-line camelcase
          if (refresh_token) {
            yield call(setRefreshToken, refresh_token)
            yield put(setTokens({access_token, refresh_token}))
          }
          yield put(setAccessToken(access_token))
          yield put(setExpireIn(expires_in))
          // '/login' is an invalid route if the user already is logged in
          if (history.location.pathname === '/login') {
            history.replace('/')
          }

          setCookie(access_token, {expires_in})
        } else {
          throw new Error('watch-authenticate-by-refresh: missing {expires_in, access_token}')
        }
      } else {
        throw new Error('watch-authenticate-by-refresh: missing {payload: refreshToken}')
      }
    } catch (e) {
      console.error('watch-authenticate-by-refresh', e) // eslint-disable-line no-console
      console.error('REFRESH: Catch_all') // eslint-disable-line no-console
      if (e.message === 'watch-authenticate-by-refresh: Unauthorized') {
        const location = yield select(getLocation)
        yield put(clearUserData())
        yield call(removeRefreshToken)
        yield put(setLoginRedirect(location))
        yield history.replace('/login')
      }
    }
  }
}
