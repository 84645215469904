import React from 'react'
// import PropTypes from 'prop-types'
// import * as _ from 'immutable'
import format from 'date-fns/format'
import getClasses from '../../utils/getClasses'

const c = getClasses({
  contentContainer: {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
  },
  innerContainer: {
    width: '50%',
    paddingBottom: 10,
  },
  contentInnerContainer: {
    paddingBottom: 20,
  },
  contentHeader: {
    fontSize: '14px',
    lineHeight: '30px',
    fontWeight: '700',
    color: '#777',
  },
  contentInset: {
    display: 'inline-block',
    margin: '5px 10px 10px 0',
    padding: 20,
    background: '#F6F8FA',
  },
  contentP: {
    display: 'block',
    fontSize: '18px',
  },
})

const getFormattedTime = (time) => {
  return format(time, 'YYYY-MM-DD HH:mm Z')
}

const AdminVersionInfo = ({
  version: {
    createdAt,
    domainId,
    gitCommitMessage,
    gitCommitSha,
    publications,
    id,
  },
  users,
}) => {
  return (
    <div className={c.contentContainer}>
      <div className={c.innerContainer} style={{maxWidth: 700}}>
        <div className={c.contentInnerContainer}>
          <div className={c.contentHeader}>Domain ID</div>
          <div className={c.contentP}>{domainId}</div>
        </div>
        <div className={c.contentInnerContainer}>
          <div className={c.contentHeader}>Version ID</div>
          <div className={c.contentP}>{id}</div>
        </div>
        <div className={c.contentInnerContainer}>
          <div className={c.contentHeader}>Created </div>
          <div className={c.contentP}>{getFormattedTime(createdAt)}</div>
        </div>
        <div className={c.contentInnerContainer}>
          <div className={c.contentHeader}>Commit</div>
          <a
            className={c.contentP}
            href={`https://github.com/Nimway/so-domain-data/commit/${gitCommitSha}`}
          >{gitCommitSha}</a>
        </div>
        <div className={c.contentInnerContainer}>
          <div className={c.contentHeader}>Commit Message</div>
          <div className={c.contentP}>{gitCommitMessage ? gitCommitMessage : '-'}</div>
        </div>
      </div>
      <div className={c.innerContainer}>
        <div className={c.contentHeader}>Published To Channels</div>
        <div className={c.contentP}>
          {
            publications.length ? publications.map(({channelId, createdAt: pubCreated, user}) => {
              const {id: userId} = user || {}

              return (
                <div key={channelId} className={c.contentInset}>
                  <div className={c.contentInnerContainer}>
                    <div className={c.contentHeader}>Channel ID</div>
                    <div className={c.contentP}>{channelId}</div>
                  </div>
                  <div className={c.contentInnerContainer}>
                    <div className={c.contentHeader}>Published</div>
                    <div className={c.contentP}>{getFormattedTime(pubCreated)}</div>
                  </div>
                  <div className={c.contentInnerContainer}>
                    <div className={c.contentHeader}>By user</div>
                    <div className={c.contentP}>
                      {users.getIn([userId, 'fullname'], '-')} &lt;{users.getIn([userId, 'email'], '')}&gt;
                    </div>
                  </div>
                </div>
              )
            }) : '–'
          }
        </div>
      </div>
    </div>
  )
}

export default AdminVersionInfo
