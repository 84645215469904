import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  seperator: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '6px',
  },
  seperatorDot: {
    margin: '3px 0',
    height: '2px',
    width: '2px',
    background: '#c8ccd1',
  },
})
