import {css} from 'aphrodite/no-important'
import * as _ from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import Link from '../containers/link'
import styles from './header-user.styles'

const HeaderUser = ({user}) => {
  return user.isEmpty() ?
    <div className={css(styles.container)} /> :
    <div className={css(styles.container)}>
      <Link href="/profile" className={css(styles.avatar)}>
        <img src={user.get('avatar')} alt="avatar" width="35px" height="35px" />
      </Link>
    </div>
}

HeaderUser.propTypes = {
  user: PropTypes.instanceOf(_.Map).isRequired,
}

export default HeaderUser
