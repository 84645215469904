import React from 'react'
import PropTypes from 'prop-types'
import LoginHeader from './elements/login-header'
import LoginButton from './elements/login-button'

const LoginEmail = ({authorizedError, setEmail}) => {
  return (
    <form
      onSubmit={function (e) {
        e.preventDefault()
        setEmail(e.currentTarget[0].value)
      }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: '0 auto',
        paddingTop: '100px',
        paddingLeft: '120px',
        paddingBottom: '120px',
        paddingRight: '120px',
      }}
    >
      <LoginHeader>Sign In</LoginHeader>
      <label
        style={{
          width: '100%',
          marginTop: '32px',
          paddingTop: '40px',
        }}
      >
        <div
          style={{
            textTransform: 'uppercase',
            color: '#879092',
            fontWeight: '200',
          }}
        >
          Your E-Mail
        </div>
        <input
          style={{
            height: '50px',
            width: '100%',
            marginTop: '10px',
          }}
          type="email"
          autoFocus
          autoComplete="off"
          required
        />
      </label>
      {authorizedError &&
        <div
          style={{
            fontSize: '14px',
            textAlign: 'center',
            marginTop: '20px',
            color: '#879092',
            fontWeight: '100',
            minHeight: '30px',
            lineHeight: '30px',
          }}
        >
          {authorizedError}
        </div>
      }
      <div style={{height: '100%'}} />
      <div
        style={{
          marginTop: '48px',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <LoginButton>Send Sign In E-mail</LoginButton>
      </div>
    </form>
  )
}

LoginEmail.propTypes = {
  authorizedError: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
}

export default LoginEmail
