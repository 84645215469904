import * as _ from 'immutable'
import * as types from '../action/types'

export default (state = _.Map(), action) => {
  // eslint-disable-line complexity
  switch (action.type) {
    case types.SET_VERSIONS:
      return state.set('all', action.payload)
    case types.UPDATE_VERSION:
      return state.updateIn(['all'], (domains) => {
        return domains.map((domain) => {
          return domain.update('versions', (versions) => {
            return versions.map((version) => {
              return version.get('id') === action.payload.get('id') ? action.payload : version
            })
          })
        })
      })
    // case types.SET_EXPANDED_VERSION_ERROR_INFO:
    //   return state.mergeIn(['expandedErrorInfo'], action.payload)
    // case types.POST_VERSIONS:
    //   return state.set('isPostVersionsRunning', true)
    // case types.POST_VERSIONS_DONE:
    //   return state.set('isPostVersionsRunning', false)
    case types.CLEAR_USER_DATA:
      return state
    default:
      return state
  }
}
