import * as _ from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'aphrodite/no-important'
import AdminSensorEventsHeader from './admin-sensor-events-header'
import AdminSensorEventsRow from './admin-sensor-events-row'
import styles from './admin-sensor-events.styles'

const AdminSensorEvents = ({sensorEvents}) => {
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.containerPadding)} />
      <div className={css(styles.contentContainer)}>
        <AdminSensorEventsHeader />
        {sensorEvents.map((j, i) => {
          const sensorEvent = j.toJS()

          return (
            <AdminSensorEventsRow
              {...sensorEvent}
              rowIndex={i}
              key={`AdminSensorEventsRow-${sensorEvent.sensorId}`}
            />
          )
        })}
      </div>
      <div className={css(styles.containerPadding)} />
    </div>
  )
}

AdminSensorEvents.propTypes = {
  sensorEvents: PropTypes.instanceOf(_.List).isRequired,
}

export default AdminSensorEvents
