import {call, race, take} from 'redux-saga/effects' // eslint-disable-line import/order
import * as types from '../action/types'
import watchAdminNavigateLoadJobs from './watch-admin-navigate-load-jobs'
import watchAdminNavigateLoadSensorEvents from './watch-admin-navigate-load-sensor-events'
import watchAdminNavigateLoadSensorRoomEvents from './watch-admin-navigate-load-sensor-events-room'
import watchAdminNavigateLoadVersions from './watch-admin-navigate-load-versions'

const terminateAdminStatus = (action) => {
  if (action.type !== types.SET_AUTHORIZATION) {
    return
  }
  return !action.payload.get('admin')
}

export default function* (history) {
  while (true) {
    // eslint-disable-line no-constant-condition
    const {payload: authorization} = yield take(types.SET_AUTHORIZATION)

    if (authorization.get('admin')) {
      yield race({
        watchAdminNavigateLoadJobsTask: call(watchAdminNavigateLoadJobs),
        watchAdminNavigateLoadSensorEvents: call(watchAdminNavigateLoadSensorEvents),
        watchAdminNavigateLoadSensorRoomEvents: call(watchAdminNavigateLoadSensorRoomEvents),
        watchAdminNavigateLoadVersions: call(watchAdminNavigateLoadVersions),
        // TODO: add tests, check if all nav jobs are cancelled, and
        // if signout, or authenticate/authorize fails
        adminStatusTerminated: take(terminateAdminStatus),
      })
      console.log('adminStatusTerminated') // eslint-disable-line
    }
  }
}
