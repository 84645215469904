import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'aphrodite/no-important'
import Select from '../elements/select'
import styles from './import-new-form.styles'

const defaultTitle = 'Select branch'
const defaultBranch = 'master'

class ImportNewForum extends React.Component {
  handleSelectOnChange = this.handleSelectOnChange.bind(this)
  handleSubmit = this.handleSubmit.bind(this)
  state={title: defaultTitle}
  handleSelectOnChange(selectedGitBranch) {
    this.selectedGitBranch = selectedGitBranch
    this.setState({title: selectedGitBranch})
  }
  handleSubmit(e) {
    const {postJob, gitBranches} = this.props

    e.preventDefault()
    const domainId = e.target.elements.domain.value
    const gitBranch = this.selectedGitBranch ||
      gitBranches.find((branch) => branch === defaultBranch)

    if (domainId && gitBranch) {
      postJob({domainId, gitBranch})
    }
  }
  render() {
    const {gitBranches} = this.props
    const title = this.state.title === defaultTitle &&
      gitBranches.find((gitBranch) => gitBranch === defaultBranch) ||
      this.state.title

    return (
      <form
        className={css(styles.form)}
        onSubmit={this.handleSubmit}
      >
        <Select
          dropDown={title === defaultTitle}
          title={title}
          onChange={this.handleSelectOnChange}
          options={gitBranches}
        />
        <input className={css(styles.input)} name="domain" placeholder="Domain" />
        <button className={css(styles.button)}>Import</button>
      </form>
    )
  }
}

ImportNewForum.propTypes = {
  postJob: PropTypes.func.isRequired,
}

export default ImportNewForum
