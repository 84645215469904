import * as _ from 'immutable'
import * as types from '../action/types'
import setExpandedJobErrorInfo from '../action/creators/set-expanded-job-error-info'
import {put, select, take} from 'redux-saga/effects'

export default function* () {
  while (true) {
    // eslint-disable-line no-constant-condition
    const {payload: jobId} = yield take(types.TOGGLE_JOB_ERROR_INFO)
    const state = yield select()
    const isExpanded = state.getIn(['jobs', 'expandedErrorInfo', jobId], false)
    yield put(setExpandedJobErrorInfo(_.Map([[jobId, !isExpanded]])))
  }
}
