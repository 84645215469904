import React from 'react'
import getClasses from '../utils/getClasses'
import LoadingElement from '../components/elements/loading'

const c = getClasses({
  container: {
    width: '580px',
    height: '540px',
    margin: '100px auto',
  },
})

const Loading = () => {
  return (
    <div className={c.container}>
      <LoadingElement isLoading />
    </div>
  )
}

export default Loading
