// used for Single Sign On by kibana
import addMilliseconds from 'date-fns/add_milliseconds'

export default (accessToken, {expires_in: expiresIn, expired}) => {
  console.log('setCookie')
  const name = 'token'
  const value = encodeURIComponent(accessToken)
  const expires = expired ? expired : addMilliseconds(Date.now(), expiresIn * 1000 - 1000 * 60).toUTCString()
  const domain = 'nimway.com'
  document.cookie = `${name}=${value};expires=${expires};domain=${domain};secure=true;`
}
