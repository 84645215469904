import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  container: {
    width: '100%',
    padding: '40px 0',
    background: '#fff',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.10)',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '3px',
    // marginTop: '140px',
  },
  containerPadding: {
    minWidth: '20px',
    flex: '0 1 40px',
  },
  contentContainer: {
    width: '100%',
  },
})
