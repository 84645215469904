import React, {useState, useEffect} from 'react'
import config from 'config'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import ReactJsonView from 'react-json-view'
import Main from '../components/elements/main'

import {getAccessToken} from '../selectors'
import rejectOnGraphqlErrors from '../utils/reject-on-graphql-errors'
import Loading from './loading'

function fetchDevice(accessToken, id) {
  console.log({accessToken, id}, 'fetchDevice()')
  return fetch(config.GRAPHQL, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `query
        {
          device(id:"${id}") {
            id
            name
            deviceConfig {
              createdAt
              tabletConfig {
                room {
                  id
                  name
                }
              }
            }
          }
        }`,
      variables: null,
    }),
  })
    .then((r) => r.json())
    .then(rejectOnGraphqlErrors)
    .then((r) => {
      console.log(r.data.device)
      return r.data.device
    })
}

function fetchRoom(accessToken, id) {
  console.log({accessToken, id}, 'fetchRoom()')
  return fetch(config.GRAPHQL, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `query
        {
          room(id:"${id}") {
            id
            name
            type
            bookable
            size
            domain {
              id
            }
            floor {
              name
            }
            building {
              name
            }
          }
        }`,
      variables: null,
    }),
  })
    .then((r) => r.json())
    .then(rejectOnGraphqlErrors)
    .then((r) => {
      console.log(r.data.room)
      return r.data.room
    })
}

function fetchSeat(accessToken, id) {
  console.log({accessToken, id}, 'fetchSeat()')
  return fetch(config.GRAPHQL, {
    method: 'POST',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `query
        {
          seat(id:"${id}") {
            id
            name
            type
            bookable
            size
            domain {
              id
            }
            floor {
              name
            }
            building {
              name
            }
          }
        }`,
      variables: null,
    }),
  })
    .then((r) => r.json())
    .then(rejectOnGraphqlErrors)
    .then((r) => {
      console.log(r.data.seat)
      return r.data.seat
    })
}

function fetchResource(accessToken, resourceType, resourceId) {
  switch (resourceType) {
    case 'tablet':
    case 'edge':
      return fetchDevice(accessToken, resourceId)
    case 'room':
      return fetchRoom(accessToken, resourceId)
  }
}

function AdminResourcesContainer(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [resourceType] = useState(props.resourceType)
  const [resourceId] = useState(props.resourceId)
  const [resource, setResource] = useState(undefined)

  useEffect(() => {
    if (resourceType && resourceId) {
      setIsLoading(true)
      fetchResource(props.accessToken, resourceType, resourceId)
        .then((fetchedResource) => {
          setIsLoading(false)
          setResource(fetchedResource)
        })
    }
  }, [resourceType, resourceId])

  return (
    <Main>
      {isLoading && <Loading />}
      <div>resourceType: {resourceType}</div>
      <div>resourceId: {resourceId}</div>
      {resource &&
        <ReactJsonView
          src={resource}
          name={null}
          displayObjectSize={false}
          displayDataTypes={false}
          iconStyle={'square'}
          collapseStringsAfterLength={80}
        />
      }
    </Main>
  )
}

AdminResourcesContainer.propTypes = {
  accessToken: PropTypes.string.isRequired,
  resourceId: PropTypes.object.isRequired,
  resourceType: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
  return {
    accessToken: getAccessToken(state)
  }
}

export default connect(mapStateToProps, {})(AdminResourcesContainer)
