import React from 'react'
import PropTypes from 'prop-types'
import * as _ from 'immutable'
import {connect} from 'react-redux'
import {css, StyleSheet} from 'aphrodite/no-important'
import Main from '../components/elements/main'
import AdminSensorEvents from '../components/admin-sensor-events'
import Loading from './loading'

const styles = StyleSheet.create({
  header: {
    margin: '68px 0 16px',
    fontSize: '30px',
    lineHeight: '60px',
    fontWeight: '200',
    color: '#656559',
    textShadow: '0 1px 0 #fff',
  },
})

class AdminSensorEventsContainer extends React.Component {
  render() {
    const {props} = this

    return props.isLoadingSensorEvents && !props.sensorEvents.size ?
      <Loading /> :
      <Main>
        <div className={css(styles.header)}>Sensor events – Floor</div>
        <AdminSensorEvents
          sensorEvents={props.sensorEvents}
        />
      </Main>
  }
}

AdminSensorEventsContainer.propTypes = {
  isLoadingSensorEvents: PropTypes.bool.isRequired,
  sensorEvents: PropTypes.instanceOf(_.List).isRequired,
}

const mapStateToProps = (state) => {
  return {
    sensorEvents: state
      .getIn(['sensorEvents', 'all'], _.List())
      .sortBy((sensorEvent) => sensorEvent.get('roomId')),
    isLoadingSensorEvents: state.getIn(['sensorEvents', 'isLoadingSensorEvents'], true),
  }
}

export default connect(mapStateToProps, {})(AdminSensorEventsContainer)
