import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    border: '1px solid #e3e9ea',
    borderRadius: '2px 2px 0 0',
    backgroundColor: '#f4fafb',
  },
  headerItem: {
    justifyContent: 'center',
    paddingTop: '1px',
    fontSize: '14px',
    lineHeight: '30px',
    fontWeight: '700',
    color: '#777',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 100,
    flex: '0 1 100px',
  },
  headerItemFlex: {
    flex: '1 1 50px',
  },
  headerItemBuilding: {
    width: 60,
    flex: '0 1 60px',
  },
  headerItemFloor: {
    width: 40,
    flex: '0 1 40px',
  },
  headerItemRebootTime: {
    width: 50,
    flex: '0 1 50px',
  },
  headerItemPadding: {
    minWidth: '10px',
    flex: '0 1 30px',
  },
  headerItemDivider: {
    minWidth: '1px',
    width: '1px',
    height: '38px',
    backgroundColor: '#e3e9ea',
    borderRadius: '5px 0 0 5px',
  },
})
