import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'aphrodite/no-important'
import styles from './login-authentication-loading.styles'

const LoginAuthenticationLoading = () => {
  return (
    <div>
      <div className={css(styles.spinner)}>
        <div className={css(styles.dot1)} />
        <div className={css(styles.dot2)} />
      </div>
    </div>
  )
}

LoginAuthenticationLoading.propTypes = {
  emailCode: PropTypes.string,
  pin: PropTypes.string.isRequired,
  setPin: PropTypes.func.isRequired,
}

export default LoginAuthenticationLoading
