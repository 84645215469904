import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Main from '../components/elements/main'
import AdminDevice from '../components/admin-device'
import {getAccessToken} from '../selectors'


const AdminDeviceContainer = ({deviceId, accessToken}) => {
  // will get stuck on loading if there are no domains with devices
  return (
    <Main>
      <AdminDevice deviceId={deviceId} accessToken={accessToken} />
    </Main>
  )
}

AdminDeviceContainer.propTypes = {
  accessToken: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    deviceId: state.getIn(['location', 'location', 'pathname']).split('/')[3],
    accessToken: getAccessToken(state)
  }
}

export default connect(mapStateToProps, {})(AdminDeviceContainer)
