import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'aphrodite/no-important'
import ImportNewForm from './import-new-form'
import ImportNewStatus from './import-new-status'
import styles from './import-new.styles'

const ImportNew = ({gitBranches, postJob, isPostJobRunning}) => {
  return (
    <div className={css(styles.container)}>
      {
        isPostJobRunning ?
          <ImportNewStatus /> :
          <ImportNewForm
            postJob={postJob}
            gitBranches={gitBranches}
          />
      }
    </div>
  )
}

ImportNew.propTypes = {
  isPostJobRunning: PropTypes.bool.isRequired,
  postJob: PropTypes.func.isRequired,
}

export default ImportNew
