import clearUserData from '../action/creators/clear-user-data'
import {removeRefreshToken} from '../services/auth'
import {call, put} from 'redux-saga/effects'

export default function* (history) {
  if (history.location.pathname === '/signout') {
    yield put(clearUserData())
    yield call(removeRefreshToken)
    yield history.replace('/login')
  }
}
