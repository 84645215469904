import config from 'config'
import setEmailCode from '../action/creators/set-email-code'
import {call, put} from 'redux-saga/effects'

export const fetchEmailCode = (email) => {
  return fetch(`${config.AUTH_URL}/auth?response_type=email_pin_code&email=${email}`)
    .then((r) => {
      return r.status !== 200 ? Promise.reject(`${r.status} - ${r.statusText}`) : Promise.resolve(r)
    })
    .then((r) => r.json())
    .then(({email_code}) => {
      return email_code ? // eslint-disable-line camelcase
        Promise.resolve(email_code) :
        Promise.reject('[reject] fetch-email-code: no email_code in response')
    })
}

export default function* ({payload}) {
  try {
    const emailCode = yield call(fetchEmailCode, payload)
    yield put(setEmailCode(emailCode))
  } catch (e) {
    // "400 - Bad Request" could indicate that the user typed the wrong email
    // and we would like to not pollute our CloudWatch log with those
    if (e === '400 - Bad Request') {
      console.warn('[catch] fetch-email-code:', e) // eslint-disable-line no-console
    } else {
      console.error('[catch] fetch-email-code:', e) // eslint-disable-line no-console
    }
    // but even if we get an error we set an emailCode to continue the login
    // process. If eventually the api starts returning emailcodes for "wrong"
    // emails we can remove this and add a more appropriate error.
    yield put(setEmailCode('EmailCodeError'))
  }
}
