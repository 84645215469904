import {combineReducers} from 'redux-immutable'
import admin from './admin'
import channels from './channels'
import devices from './devices'
import floorplans from './floorplans'
import domain from './domain'
import gitBranches from './gitBranches'
import jobs from './jobs'
import location from './location'
import login from './login'
import sensorEvents from './sensorEvents'
import sensorEventsRoom from './sensorEventsRoom'
import session from './session'
import user from './user'
import users from './users'
import versions from './versions'

export default combineReducers({
  admin,
  channels,
  domain,
  devices,
  floorplans,
  gitBranches,
  sensorEvents,
  sensorEventsRoom,
  jobs,
  location,
  login,
  session,
  user,
  users,
  versions,
})
