import * as _ from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now'
import {differenceInSeconds} from 'date-fns'
import Table from '../elements/table'
import Link from '../../containers/link'
import StatusIndicator from './import-jobs-row-status-indicator'

const getTimeText = (job) => {
  const createdAt = job.get('createdAt')

  return createdAt ?
    distanceInWordsToNow(createdAt, {addSuffix: true}) :
    ''
}

const getVersionLink = (job) => {
  const jobId = job.get('id')

  return jobId ?
    <Link href={`jobs/${jobId}`} title={jobId}>Info</Link> :
    ''
}

const getGitLink = (job) => {
  const gitCommitSha = job.get('gitCommitSha') || ''

  return gitCommitSha ? (
    <a
      title={job.get('gitCommitMessage', '')}
      href={`https://github.com/Nimway/so-domain-data/commit/${gitCommitSha}`}
    >{job.get('gitCommitMessage', '')}</a>
  ) : ''
}
const secondsToHms = (ds) => {
  const d = Number(ds)
  const h = Math.floor(d / 3600)
  const m = Math.floor(d % 3600 / 60)
  const s = Math.floor(d % 3600 % 60)

  const hDisplay = h > 0 ? h + (h === 1 ? ' h ' : ' h ') : ''
  const mDisplay = m > 0 ? m + (m === 1 ? ' m ' : ' m ') : ''
  const sDisplay = s > 0 ? s + (s === 1 ? ' s' : ' s') : ''
  return hDisplay + mDisplay + sDisplay
}
const getDuration = (job) => {
  const createdAt = job.get('createdAt')
  const modifiedAt = job.get('modifiedAt')
  const status = job.get('status', '')
  if (status !== 'started') {
    return secondsToHms(differenceInSeconds(modifiedAt, createdAt))
  } else {
    return (
      <div style={{flexDirection: 'row', display: 'flex'}}>
        <StatusIndicator status={status} />
        {secondsToHms(differenceInSeconds(new Date(), createdAt))}
      </div>
    )
  }
}

const getDomainVersionsLink = (job) => {
  const domainId = job.get('domainId')
  const status = job.get('status', '')
  return (
    <a
      title={'Goto versions'}
      href={`/domains/${domainId}/versions`}
    >{domainId}</a>
  )
}

const getStatus = (job) => {
  const jobId = job.get('id')
  const status = job.get('status', '')

  return status === 'failed' ?
    <Link href={`jobs/${jobId}#error`} title={status}>{status}</Link> :
    (
      <div style={{flexDirection: 'row', display: 'flex'}}>
        <StatusIndicator status={status} />
        {status}
      </div>
    )
}
const ImportJobs = ({jobs}) => {
  const narrowColumnStyling = {flex: '0 1 76px'}
  const createdStyling = {flex: '0 1 130px'}
  const gitBranchStyling = {flex: '0 1 90px'}
  const gitCommitStyling = {flex: '0 1 70px'}
  const gitCommitMessageStyling = {
    flex: '1 1 140px',
    minWidth: 120,
  }
  const headerData = [
    {columnName: 'Created', classStyling: createdStyling},
    // {columnName: 'Branch', classStyling: gitBranchStyling},
    // {columnName: 'Commit SHA', classStyling: gitCommitStyling},
    {columnName: 'Domain', classStyling: narrowColumnStyling},
    {
      columnName: 'Commit Message',
      classStyling: gitCommitMessageStyling,
    },
    // {columnName: 'Command', classStyling: narrowColumnStyling},
    // {columnName: 'Channel', classStyling: narrowColumnStyling},
    {columnName: 'User', classStyling: narrowColumnStyling},
    {columnName: 'Status', classStyling: gitBranchStyling},
    // {columnName: 'Versions', classStyling: gitBranchStyling},
    {columnName: 'Duration', classStyling: gitBranchStyling},
    {columnName: 'More info', classStyling: narrowColumnStyling},
  ]

  const rowData = jobs
    .map((job) => {
      return _.Map({
        key: job.get('id'),
        job: job.toJS(),
        data: [
          {key: '1', content: getTimeText(job), classStyling: createdStyling},
          // {key: '2', content: job.get('gitBranch', ''), classStyling: gitBranchStyling},
          // {key: '3', content: getGitLink(job), classStyling: gitCommitStyling},
          {key: '6', content: getDomainVersionsLink(job), classStyling: narrowColumnStyling},
          {
            key: '3b',
            content: getGitLink(job),
            classStyling: gitCommitMessageStyling,
          },
          // {key: '4', content: job.get('command', ''), classStyling: narrowColumnStyling},
          // {key: '5', content: job.get('channelId', ''), classStyling: narrowColumnStyling},
          {key: '7', content: job.getIn(['user', 'email'], 'GitHub'), classStyling: gitCommitStyling},
          {key: '8', content: getStatus(job), classStyling: gitBranchStyling},
          // {key: '9', content: getDomainVersionsLink(job), classStyling: gitCommitStyling},
          {key: '9', content: getDuration(job), classStyling: gitBranchStyling},
          {key: '0', content: getVersionLink(job), classStyling: narrowColumnStyling},
        ],
      })
    })

  return (
    <Table headerData={headerData} rowsData={rowData} />
  )
}

ImportJobs.propTypes = {
  jobs: PropTypes.instanceOf(_.List).isRequired,
}

export default ImportJobs
