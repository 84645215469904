export default `
# Welcome to GraphiQL
#
# GraphiQL is an in-browser tool for writing, validating, and
# testing GraphQL queries.
#
# Type queries into this side of the screen, and you will see intelligent
# typeaheads aware of the current GraphQL type schema and live syntax and
# validation errors highlighted within the text.
#
# GraphQL queries typically start with a "{" character. Lines that starts
# with a # are ignored.
#
# An example GraphQL query might look like:
#
#     {
#       field(arg: "value") {
#         subField
#       }
#     }
#
# Keyboard shortcuts:
#
#       Run Query:  Ctrl-Enter (or press the play button above)
#
#   Auto Complete:  Ctrl-Space (or just start typing)
#

{
  #sony building
  building(id: "5062") {
    id
    name
    longitude
    latitude
    floors {
      id
      floorplanUrl
      level
      name
      shortName
      rooms {
        id
        name
        type
        accessibility
        floorId
        bookingStatusEnabled
        occupancyStatusEnabled
        deskOccupancyStatusEnabled
      }
    }
  }
}`
