import React from 'react'
import {connect} from 'react-redux'
import {StyleSheet, css} from 'aphrodite/no-important'
import AdminGraphiQLComponent from '../components/admin-graphiql'

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    height: 'calc(100% - 80px)',
    top: '80px',
    left: 0,
    width: '100%',
  },
})

const containerClass = css(styles.container)

const AdminGraphiQL = (props) => {
  return (
    <div className={containerClass}>
      <AdminGraphiQLComponent {...props} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.getIn(['session', 'access_token'], ''),
  }
}

export default connect(mapStateToProps, {})(AdminGraphiQL)
