import * as _ from 'immutable'
import * as types from '../action/types'

export default (state = _.Map(), action) => {
  switch (action.type) {
    case types.SET_GIT_BRANCHES:
      return state.set('all', action.payload)
    case types.CLEAR_USER_DATA:
      return state
    default:
      return state
  }
}
