import config from 'config'
import postJobDone from '../action/creators/post-job-done.js'
import {getAccessToken} from '../selectors'
import rejectOnGraphqlErrors from '../utils/reject-on-graphql-errors'
import {call, put, select} from 'redux-saga/effects'

const postJob = function (accessToken, {domainId, gitBranch}) {
  return fetch(config.GRAPHQL, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      query: `mutation {
        createDomainVersionImportJob(domainId: "${domainId}", gitBranch: "${gitBranch}") {
            id
            status
            gitBranch
            gitCommitSha
            gitCommitMessage
            createdAt
            modifiedAt
          }
        }`,
      variables: null,
    }),
  })
    .then((r) => r.json())
    .then(rejectOnGraphqlErrors)
    .then((r) => {console.log('r', r)})
}

export default function* ({payload}) {
  try {
    const accessToken = yield select(getAccessToken)
    yield call(postJob, accessToken, payload)
    yield put(postJobDone())
  } catch (e) {
    // TODO: display error message if failing
    console.error('[catch] post-job:', e) // eslint-disable-line no-console
    yield put(postJobDone())
  }
}
