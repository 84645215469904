import * as _ from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import getClasses from '../../../utils/getClasses'

const headerItem = {
  display: 'block',
  // justifyContent: 'center',
  paddingTop: '1px',
  fontSize: '14px',
  lineHeight: '30px',
  fontWeight: '700',
  color: '#777',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: 100,
  flex: '0 1 100px',
}

const c = getClasses({
  header: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    border: '1px solid #e3e9ea',
    borderRadius: '2px 2px 0 0',
    backgroundColor: '#f4fafb',
  },
  // headerItemFlex: {
  //   flex: '1 1 50px',
  // },
  // headerItemBuilding: {
  //   width: 60,
  //   flex: '0 1 60px',
  // },
  // headerItemFloor: {
  //   width: 40,
  //   flex: '0 1 40px',
  // },
  // headerItemRebootTime: {
  //   width: 50,
  //   flex: '0 1 50px',
  // },
  // headerItemPadding: {
  //   minWidth: '10px',
  //   flex: '0 1 30px',
  // },
  padding: {
    minWidth: '10px',
    flex: '0 1 30px',
  },
  divider: {
    minWidth: '1px',
    width: '1px',
    height: '38px',
    backgroundColor: '#e3e9ea',
    borderRadius: '5px 0 0 5px',
  },
})

const isLast = (i, data) => {
  const length = data.length || data.size

  return i + 1 === length
}

const Header = ({data}) => {
  return (
    <div className={c.header}>
      {
        data.map(({columnName, classStyling = {}}, i, dataRef) => {
          const {className} = getClasses({className: {...headerItem, ...classStyling}})

          return (
            <React.Fragment key={encodeURIComponent(columnName)}>
              <div className={c.padding} />
              <div className={className}>{columnName}</div>
              <div className={c.padding} />
              {!isLast(i, data) && <div className={c.divider} />}
            </React.Fragment>
          )
        })
      }
    </div>
  )
}

Header.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.instanceOf(_.List).isRequired,
  ]).isRequired,
}

export default Header
