import {css} from 'aphrodite/no-important'
import * as _ from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import HeaderUser from './header-user'
import styles from './header-right.styles'

class HeaderRight extends React.Component {
  render() {
    return (
      <div className={css(styles.userContainer)}>
        <HeaderUser user={this.props.user} />
      </div>
    )
  }
}

HeaderRight.propTypes = {
  user: PropTypes.instanceOf(_.Map).isRequired,
}

export default HeaderRight
