// import util from 'util'
import React, {useState, useEffect, useMemo} from 'react'

import moment from 'moment'
import PropTypes from 'prop-types'
import config from 'config'
import * as _ from 'immutable'
import {connect} from 'react-redux'
import {css, StyleSheet} from 'aphrodite/no-important'
import DataTable from 'react-data-table-component'
import {useTable, useAbsoluteLayout, utils, useSortBy} from 'react-table'
import styled from 'styled-components'
import Main from '../components/elements/main'
import Select from '../components/elements/select'
import AdminHealthStatuses from '../components/admin-health-statuses'
import rejectOnGraphqlErrors from '../utils/reject-on-graphql-errors'
import {getAccessToken} from '../selectors'
// import Link from './link'
//import Loading from './loading'
import Loading from '../components/elements/loading'

import xImg from "./x.svg"
import checkImg from "./check.svg"
import editImg from "./edit.png"

const styles = StyleSheet.create({
  table: {
    border: "1px solid #000",
    maxWidth: "1100px",
    overflowX: "auto",
  },
  rows: {
    overflowY: "auto",
    borderBottom: "1px solid #000",
    height: "32px",
  },
  cell: {
    height: "100%",
    lineHeight: "30px",
    borderRight: "1px solid #000",
    paddingLeft: "5px"
  },
  header: {
    margin: '68px 0 16px',
    fontSize: '30px',
    lineHeight: '60px',
    fontWeight: '200',
    color: '#656559',
    textShadow: '0 1px 0 #fff',
  }
})

const fetchHealthStatusSummaries = (accessToken) => {
  return fetch(
    `${config.API_URL}/healthstatussummaries`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    },
  )
    .then((response) => {
      return response.json()
    })
}

const fetchFailingResources = (accessToken, domainId) => {
  console.log(accessToken)
  const url = new URL(`${config.API_URL}/healthstatuses`)
  const searchParams = new URLSearchParams({queryType:'failingResources', domainId});
  url.search = searchParams

  return fetch(
    url,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken[0]}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    },
  ).then((response) => {
    console.log(response)
    var r = response.json();
    return r
  }).catch((e) => {
    console.log(e);
    return []
  }) 
}

const Styles = styled.div`
  margin: 1rem;
  padding: 1rem;
  background: #ffffff;

  font-size: 12px;

  * {
    box-sizing: border-box;
  }

  td {
    height: 100%;
    line-height: 30px;
    border-right: 1px solid rgb(181, 188, 193);
    padding-left: 5px;
    padding-right: 5px;
    position: relative;

    :last-child {
      border: 0;
    }
  }

  td.number {
    text-align: right;
  }

  td.boolean {
    text-align: center;
  }

  .table {
    margin-bottom: 1rem;
    border-spacing: 0;
    border: 1px solid rgb(181, 188, 193);
    max-width: 1162px;
    position: relative;
  }

  .table-small {
    margin-bottom: 1rem;
    border-spacing: 0;
    border: 1px solid rgb(181, 188, 193);
    max-width: 1172px;
    overflow-x: auto;
  }

  .header {
    border-right: 1px solid rgb(181, 188, 193);
    background: #ffffff;
    font-weight: bold;
    position: relative;
  }

  .rows {
    overflow-y: auto;
  }

  .row {
    background: #ffffff
    border-bottom: 1px solid rgb(181, 188, 193);
    border-right: 1px solid rgb(181, 188, 193);
    height: 32px;
    &.body {
      :last-child {
        border: 0;
      }
    }
  }

  .cell {
    height: 100%;
    line-height: 30px;
    padding-left: 5px;

    :last-child {
      border: 0;
    }
  }
  .new-tooltip{
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    z-index: 100000;
    position:relative;
  }
  .container {
    overflow-y: auto;
  }

  .new-tooltip span {
    position:relative;
      visibility: hidden;
      padding-left:10px; 
      line-height: .4em;
      box-shadow: 10px 10px 5px grey;
      width: 500px;
      max-width: 500px;
      max-height: 700px;
      z-index: 10000;
      position: absolute;
      background-color: #fefefe;
      color: #000;
      border-radius: 6px;
      cursor: pointer;
      margin-left:10px;
    }

    .new-tooltip:hover span {
      position:relative;
      visibility: visible;
      display: inline-block;
    }
    p {
      margin-right: 5px;
      font-size: 12px;
      white-space: pre;
      font-weight:400;
      
    } 
    .description {
      overflow: hidden;
      text-overflow: ellipsis;
    }

`

const DEFAULT_DOMAIN_ID = 'sonymobile'
const DEFAULT_RESOURCE_TYPE = 'tablet'
const DEFAULT_STATUS = 'notOk'
const DEFAULT_USER_EMAIL = 'unknown'

// function TableLoader(props) {
//
//   const [domainId, setDomainId] = useState(props.domainId)
//   const [resourceType, setResourceType] = useState(props.resourceType)
//   const [status, setStatus] = useState(props.status)
//
//   console.log(domainId, resourceType, status, 'TableLoader()')
//
//   useEffect(() => {
//     console.log(domainId, resourceType, status, 'TableLoader.useEffect()')
//     props.onChange(domainId, resourceType, status)
//   }, [domainId, resourceType, status])
//
//   return (
//
//   )
// }

// Create a default prop getter
const defaultPropGetter = () => ({})

// Expose some prop getters for headers, rows and cells, or more if you want!
function HealthStatusSummariesTable({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 60,
      width: 60,
      maxWidth: 500,
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useAbsoluteLayout
  )

  return (
    <div className="container">
      <div {...getTableProps()} className="table">
        <div> 
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="row header-group">
              {headerGroup.headers.map((column, index) => (
                <div
                  // Merge all of the header Props
                  {...utils.mergeProps(
                    column.getHeaderProps(),
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),
                    getHeaderProps(column)
                  )}
                className="cell header"> 
                  {column.render('Header')}
                  <span className="new-tooltip">
                    {headerGroup.headers[index].tipText ? ' ⓘ': '' }
                    {headerGroup.headers[index].tipText && (
                      <span className="new-tooltip"><p >{headerGroup.headers[index].tipText}</p></span>
                    )} 
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              // Merge row props
              <div {...utils.mergeProps(row.getRowProps(), getRowProps(row))} className="row body">
                {row.cells.map((cell) => {
                  return (
                    <td
                      // Merge cell props
                      {...utils.mergeProps(
                        cell.getCellProps(),
                        {
                          className: cell.column.className,
                          style: cell.column.style,
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell)
                      )}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}//

function generateHealthStatusSummariesColumns() {
  console.log('generateHealthStatusSummariesColumns()')
  return [
    {
      Header: 'Domain Id',
      accessor: 'domainId',
      width: 100,
    },
    {
      Header: 'Sensor',
      tipText: `Sensor health is based on periodic heartbeats (4 per hour). A sensor will be: 

ERROR -> No heartbeats recieved for 24 hours
WARNING -> No heartbeats recieved for 1 hour
OK -> Heartbeat recieved last hour
SNOOZED -> Problem acknowledged by admin
MISSING -> Heartbeat never recieved (not installed yet)

Common reasons for problems are: 
- Sensor battery depleted
- Sensor disloged from mounting place and missplaced / thrown away

Error resolution:

Sensor in state ERROR needs to be replaced either by us or the customer
      `,
      columns: [
        {
          Header: 'Error',
          id: 'sensorStatus.errorCount',
          accessor: (row) => row.sensorStatus.errorCount,
          className: 'number'
        },
        {
          Header: 'Warning',
          accessor: 'sensorStatus.warningCount',
          className: 'number'
        },
        {
          Header: 'Ok',
          accessor: 'sensorStatus.okCount',
          className: 'number'
        },
        {
          Header: 'Snoozed',
          accessor: 'sensorStatus.snoozedCount',
          className: 'number'
        },
        {
          Header: 'Missing',
          id: 'sensorStatus.missingCount',
          accessor: (row) => row.sensorStatus.missingCount,
          className: 'number'
        }
      ],
    },
    {
      Header: 'Tablet',
      tipText: `Tablet health is based on periodic heartbeats (12 per hour). A tablet will be: 

ERROR -> No heartbeats recieved for 24 hours
WARNING -> No heartbeats recieved for 1 hour
OK -> Heartbeat recieved last hour
SNOOZED -> Problem acknowledged by admin
MISSING -> Heartbeat never recieved (not installed yet)

Common reasons for problems are: 
- Power or connectivity outage
- Firmware issues (hangs or freezes)
- Nimway app issue

Error resolution:

Tablet in state ERROR should be checked for power and connectivity issues by customer. 
The customer should also try to reboot the tablet. If these actions don't solve the issue
a new tablet needs to be sent to the customer.
      `,
      columns: [
        {
          Header: 'Error',
          id: 'tabletStatus.errorCount',
          accessor: (row) => row.tabletStatus.errorCount,
          className: 'number'
        },
        {
          Header: 'Warning',
          accessor: 'tabletStatus.warningCount',
          className: 'number'
        },
        {
          Header: 'Ok',
          accessor: 'tabletStatus.okCount',
          className: 'number'
        },
        {
          Header: 'Snoozed',
          accessor: 'tabletStatus.snoozedCount',
          className: 'number'
        },
        {
          Header: 'Missing',
          id: 'tabletStatus.missingCount',
          accessor: (row) => row.tabletStatus.missingCount,
          className: 'number'
        }
      ],
    },
    /*{
      Header: 'Floorplan',
      tipText: `Floorplan health is based on periodic heartbeats (12 per hour). A floorplan will be: 

ERROR -> No heartbeats recieved for 24 hours
WARNING -> No heartbeats recieved for 1 hour
OK -> Heartbeat recieved last hour
SNOOZED -> Problem acknowledged by admin
MISSING -> Heartbeat never recieved (not installed yet)

Common reasons for problems are: 
- Same as for the Edge 

Error resolution:

If a floorplan and the corresponding edge is both in state ERROR, follow the 
error resolution steps for the edge first. If a floorplan is in state ERROR 
while the corresponding edge is not, it's either a display or cabling issue.
Have the customer check that the TV has power and that the HDMI cable is 
properly connected. Also have the customer power cycle the disply. If this does
not resolve the issue a new display needs to be sent to the customer.
      `,
      columns: [
        {
          Header: 'Error',
          id: 'floorplanHealthStatus.errorCount',
          accessor: (row) => row.floorplanHealthStatus.errorCount,
          className: 'number'
        },
        {
          Header: 'Warning',
          accessor: 'floorplanHealthStatus.warningCount',
          className: 'number'
        },
        {
          Header: 'Ok',
          accessor: 'floorplanHealthStatus.okCount',
          className: 'number'
        },
        {
          Header: 'Snoozed',
          accessor: 'floorplanHealthStatus.snoozedCount',
          className: 'number'
        },
        {
          Header: 'Missing',
          id: 'floorplanHealthStatus.missingCount',
          accessor: (row) => row.floorplanHealthStatus.missingCount,
          className: 'number'
        }
      ],
    },*/
    {
      Header: 'Edge',
      tipText: `Edge health is based on periodic heartbeats (12 per hour). An edge will be: 

ERROR -> No heartbeats recieved for 24 hours
WARNING -> No heartbeats recieved for 1 hour
OK -> Heartbeat recieved last hour
SNOOZED -> Problem acknowledged by admin
MISSING -> Heartbeat never recieved (not installed yet)

Common reasons for problems are: 
- Power or connectivity outage
- Loose cables

Error resolution:

Edge in state ERROR should be checked for power and connectivity issues by 
customer. The customer should also try to reboot the edge and check that all cables are 
connected (gateway + HDMI).
If these actions don't solve the issue a new edge needs to be sent to the customer.
      `,
      columns: [
        {
          Header: 'Error',
          id: 'edgeHealthStatus.errorCount',
          accessor: (row) => row.edgeHealthStatus.errorCount,
          className: 'number'
        },
        {
          Header: 'Warn',
          accessor: 'edgeHealthStatus.warningCount',
          className: 'number'
        },
        {
          Header: 'Ok',
          accessor: 'edgeHealthStatus.okCount',
          className: 'number'
        },
        {
          Header: 'Snoozed',
          accessor: 'edgeHealthStatus.snoozedCount',
          className: 'number'
        },
        {
          Header: 'Missing',
          id: 'edgeHealthStatus.missingCount',
          accessor: (row) => row.edgeHealthStatus.missingCount,
          className: 'number'
        }
      ],
    },
    {
      Header: 'Failing Resources',
      tipText: `Failing Resources is the resources and users with callendars
that Nimway does not have access to: 

Resources -> Number of unaccessible reources
Users -> Number of unaccessible users

- Error code meaning: 
  400: Bad Request - Something in the resource email address is wrong, 
       could be a / & or @ etc. 
  403: Forbidden:  Nimway does not have the right to read the calendar, 
       mostly due to not being included in the correct security group
  404: Not Found - Resource or user does not exist, 
       could be a misstyped email address or terminated employee. 
  429: To Many Requests - Nimway gets thorttled.

Error resolution:

Check spelling of calendarId and then contact customer to
check for security group inclusion and old emloyees.
      `,
      columns: [
        {
          Header: 'Resource',
          width: 80,
          id: 'failingResourceStatus.resourceCount',
          accessor: (row) => row.failingResourceStatus.resourceCount,
          className: 'number'
        },
        {
          Header: 'User',
          width: 80,
          id: 'failingResourceStatus.userCount',
          accessor: (row) => row.failingResourceStatus.userCount,
          className: 'number'
        }
      ],
    },
  ]
}
const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
              '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
              '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
              '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
              '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
              '(\\#[-a-z\\d_]*)?$','i');
const resourceKeyRegExp = /^(\w*):(.*)$/


function loadDevicesFromCell(cell) {
  const {domainId} = cell.row.values
  const resourceType = cell.column.parent.Header
  const status = cell.column.Header === 'Error' || cell.column.Header === 'Warning' || cell.column.Header === 'Missing' ? 'notOk' : 'ok'
  const url = `/admin/devices?domainId=${domainId}&deviceType=${resourceType}&searchString=${status}`
  window.location.href =  url
}

async function loadResourcesFromCell(cell, accessToken) {
  const {domainId} = cell.row.values
  const resourceType = cell.column.Header.toLowerCase()
  const failingResources = await fetchFailingResources(accessToken, domainId)
  const data = failingResources
    .filter(r => r.resourceType == resourceType)
    .map(r => `${r.resourceAddress},${r.calendarService},${r.resourceType},${r.statusCode},${r.domainId}`)
  data.unshift('address,calendarService,type,statusCode,domainId')
  var wnd = window.open("about:blank", "", "_blank")
  wnd.document.write(data.join('<br>'))
}


var accessToken

function onRowClick(e, t, rowInfo) {
  console.log(rowInfo)
}

function AdminHealthStatusesContainer(props) {
  const accessToken = useState(props.accessToken)
  const [healthStatusSummaries, setHealthStatusSummaries] = useState([])
  const [, setIsLoadingHealthStatusSummaries] = useState(false)
  const [healthStatuses, setHealthStatuses] = useState([])
  const [isLoadingHealthStatuses, setIsLoadingHealthStatuses] = useState(false)
  const [domainId, setDomainId] = useState(props.domainId || DEFAULT_DOMAIN_ID)
  const [resourceType, setResourceType] = useState(props.resourceType || DEFAULT_RESOURCE_TYPE)
  const [status, setStatus] = useState(props.status || DEFAULT_STATUS)

  async function loadHealthStatusSummaries() {
    setIsLoadingHealthStatusSummaries(true)
    const newHealthStatusSummaries = await fetchHealthStatusSummaries(props.accessToken)
    setHealthStatusSummaries(newHealthStatusSummaries)
    setIsLoadingHealthStatusSummaries(false)
  }

  useEffect(() => {
    loadHealthStatusSummaries()
  }, [domainId, resourceType, status])

  // function handleOnLoaderChange(domainId, resourceType, status) {
  //   // setDomainId(newDomainId)
  //   // setResourceType(newResourceType)
  //   // setStatus(newStatus)
  //   updateUrlQuery(domainId, resourceType, status)
  // }
  function setFilter(filter) {
    setDomainId(filter.domainId)
    setResourceType(filter.resourceType)
    setStatus(filter.status)
  }
  // function onFilterChange(newDomainId, newResouceType, newStatus) {
  //   const params = Object.assign({
  //     domainId,
  //     resourceType,
  //     status
  //   }, {
  //     [e.target.name]:
  //   });
  //   setDomainId(params.domainId)
  //   setResourceType(params.resourceType)
  //   setStatus(params.status)
  //   updateUrlQuery(params)
  // }

  const getBackgroundColor = (column) => {
    if (column.parent) {
      if (column.parent.id === 'Sensor') {
        return 'rgba(255, 255, 255, 0.5)'
      }
      if (column.parent.id === 'Tablet') {
        return 'rgba(226, 226, 226, 0.5)'
      }
      /*if (column.parent.id === 'Floorplan') {
        return 'rgba(255, 255, 255, 0.5)'
      }*/
      if (column.parent.id === 'Edge') {
        return 'rgba(226, 226, 226, 0.5)'
      }
      if (column.parent.id === 'Failing Resources') {
        return 'rgba(255, 255, 255, 0.5)'
      }
    }
    return 'white'
  }

  const healthStatusSummariesColumns = useMemo(() => generateHealthStatusSummariesColumns(setFilter), [])

  return (
    <Styles>
      <Loading isLoading={isLoadingHealthStatuses} />
      <HealthStatusSummariesTable
        columns={healthStatusSummariesColumns}
        data={healthStatusSummaries.sort()}
        getCellProps={(cell) => {

          if (cell.column.id.includes('okCount') && cell.value > 0) {
            return {
              style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                cursor: 'pointer',
              },
              onClick: () => {
                const filter = loadDevicesFromCell(cell)
        
              }
            }
          }

          if (cell.column.id.includes('errorCount') && cell.value > 0) {
            return {
              style: {
                backgroundColor: 'rgba(208, 56, 42, 0.9)',
                color: 'white',
                cursor: 'pointer',
              },
              onClick: () => {
                const filter = loadDevicesFromCell(cell)
              }
            }
          }

          if (cell.column.id.includes('warningCount') && cell.value > 0) {
            return {
              style: {
                backgroundColor: 'rgba(248, 148, 6, 0.9)',
                color: 'white',
                cursor: 'pointer',
              },
              onClick: () => {
                const filter = loadDevicesFromCell(cell)
              }
            }
          }

          if (cell.column.id.includes('missingCount') && cell.value > 0) {
            return {
              style: {
                backgroundColor: 'rgba(240, 141, 174, 0.9)',
                color: 'white',
                cursor: 'pointer',
                borderRight: '3px solid #00000070',
              },
              onClick: () => {
                const filter = loadDevicesFromCell(cell)
              }
            }
          }

          if (cell.column.id.includes('snoozedCount') && cell.value > 0) {
            return {
              style: {
                backgroundColor: 'rgba(72, 137, 183, 0.9)',
                color: 'white',
                cursor: 'pointer',
                
              },
              onClick: () => {
                const filter = loadDevicesFromCell(cell)
              }
            }
          }
          if (cell.column.id.includes('missingCount')) {
            return {
              style: {
                backgroundColor: getBackgroundColor(cell.column),
                borderRight: '3px solid #00000070',
              }
            }
          }

          if (cell.column.id.includes('resourceCount') && cell.value > 0) {
            return {
              style: {
                backgroundColor: 'rgba(248, 148, 6, 0.9)',
                color: 'white',
                cursor: 'pointer',
              },
              onClick: () => {
                const filter = loadResourcesFromCell(cell, accessToken)
              }
            }
          }

          if (cell.column.id.includes('userCount') && cell.value > 0) {
            return {
              style: {
                backgroundColor: 'rgba(248, 148, 6, 0.9)',
                color: 'white',
                cursor: 'pointer',
              },
              onClick: () => {
                const filter = loadResourcesFromCell(cell, accessToken)
              }
            }
          }

          if (cell.column && cell.column.parent && cell.column.parent.id === 'Sensor') {
            return {
              style: {
                backgroundColor: getBackgroundColor(cell.column),
              }
            }
          }
          if (cell.column && cell.column.parent && cell.column.parent.id === 'Tablet') {
            return {
              style: {
                backgroundColor: getBackgroundColor(cell.column),
              }
            }
          }
          /*if (cell.column && cell.column.parent && cell.column.parent.id === 'Floorplan') {
            return {
              style: {
                backgroundColor: getBackgroundColor(cell.column),
              }
            }
          }*/
          if (cell.column && cell.column.parent && cell.column.parent.id === 'Edge') {
            return {
              style: {
                backgroundColor: getBackgroundColor(cell.column),
              }
            }
          }
          if (cell.column && cell.column.parent && cell.column.parent.id === 'Failing Resources') {
            return {
              style: {
                backgroundColor: getBackgroundColor(cell.column),
              }
            }
          }
        }}
      />
    </Styles>
  )
}

AdminHealthStatusesContainer.propTypes = {
  accessToken: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  const urlSearchParams = new URLSearchParams(state.getIn(['location', 'location', 'search']))
  console.log(urlSearchParams.toString(), 'mapStateToProps()')
  return {
    domainId: urlSearchParams.get('domainId'),
    resourceType: urlSearchParams.get('resourceType'),
    status: urlSearchParams.get('status'),
    domainIds: state
      .getIn(['versions', 'all'], _.List())
      .map((versionsData) => versionsData.get('id'))
      .sort()
      .toJS(),
    accessToken: getAccessToken(state),
    userEmail: state.getIn(['user', 'email']),
  }
}

export default connect(mapStateToProps, {})(AdminHealthStatusesContainer)
