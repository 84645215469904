export default () => {
  const search = window.location.search
  const parameters = {}

  search
    .substr(1)
    .split('&')
    .forEach((entry) => {
      const eq = entry.indexOf('=')
      if (eq >= 0 && entry.slice(0, eq) !== 'token') {
        parameters[decodeURIComponent(entry.slice(0, eq))] = decodeURIComponent(entry.slice(eq + 1))
      }
    })

  // if variables was provided, try to format it.
  if (parameters.variables) {
    try {
      parameters.variables = JSON.stringify(JSON.parse(parameters.variables), null, 2)
    } catch (e) {
      // Do nothing, we want to display the invalid JSON as a string, rather
      // than present an error.
    }
  }

  return parameters
}
