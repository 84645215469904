import * as _ from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import getClasses from '../../../utils/getClasses'

const rowItem = {
  display: 'block',
  justifyContent: 'center',
  paddingTop: '1px',
  fontSize: '14px',
  lineHeight: '32px',
  fontWeight: '400',
  color: '#8d8d8d',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: 100,
  flex: '0 1 100px',
}

const row = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  minHeight: '60px',
  border: '1px solid #e3e9ea',
  borderTop: 'none',
}

const c = getClasses({
  rowOdd: {
    ...row,
    backgroundColor: '#fafdff',
  },
  rowEven: {
    ...row,
    backgroundColor: '#fff',
  },
  padding: {
    minWidth: '10px',
    flex: '0 1 30px',
  },
  divider: {
    minWidth: '1px',
    width: '1px',
    height: '38px',
  },
})

const isLast = (i, data) => {
  const length = data.length || data.size

  return i + 1 === length
}

// const isHeaderAndRowOfSameLength = (rowData, headerLength) => {
//   const rowLength = rowData.length || rowData.size || undefined

//   if (rowLength !== headerLength) {
//     console.error('Table Header and Table Row length missmatch')
//   }
// }

const Row = ({data, headerLength, rowIndex, job}) => {
  const rowClass = rowIndex % 2 ? c.rowOdd : c.rowEven

  let style = {}
  if (job && job.status === 'failed') {
    style = {backgroundColor: 'rgba(255, 232, 232, 0.33)'}
  } else if (job && (job.status === 'started' || job.status === 'queued')) {
    style = {backgroundColor: 'rgba(249, 238, 162, 0.33)'}
  }

  return (
    <div className={rowClass} style={style}>
      {
        data.map(({content, key, title, classStyling = {}}, i, dataRef) => {
          const {className} = getClasses({className: {...rowItem, ...classStyling}})

          return (
            <React.Fragment key={encodeURIComponent(key || i)}>
              <div className={c.padding} />
              <div
                className={className}
                title={title || typeof content === 'string' ? content : ''}
              >{content}</div>
              <div className={c.padding} />
              {!isLast(i, data) && <div className={c.divider} />}
            </React.Fragment>
          )
        })
      }
    </div>
  )
}

// Row.propTypes = {
//   data: PropTypes.oneOfType([
//     PropTypes.array.isRequired,
//     PropTypes.instanceOf(_.List).isRequired,
//   ]).isRequired,
// }

// Row.propTypes = {
//   data: function(props, propName, componentName) {
//     debugger
//     if (!Array.isArray(props[propName]) && false) {
//       return 'array'
//     }
//     if (!(/matchme/).test(props[propName])) {
//       return new Error(
//         'Invalid prop `' + propName + '` supplied to' +
//         ' `' + componentName + '`. Validation failed.'
//       )
//     }
//   },
//   headerLength: PropTypes.number.isRequired,
// }


export default Row
