import * as _ from 'immutable'
import * as types from '../action/types'

export default (state = _.Map(), action) => {
  switch (action.type) {
    case types.SET_LOCATION:
      return state.merge(action.payload)
    default:
      return state
  }
}
