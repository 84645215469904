import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'aphrodite/no-important'
import styles from './login-button.styles'

const LoginButton = ({children}) => {
  return <button className={css(styles.button)}>{children}</button>
}

LoginButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export default LoginButton
