import {createSelector} from 'reselect'

const getFloorplans = (state) => state.get('floorplans')

export const getFloorplansPerDomain = createSelector([getFloorplans], (floorplans) => {
  return floorplans
    .filter((f) => f.getIn(['deviceType']) == 'floorplan')
    .filter((f) => f.get('deviceConfig') != null)
    .filter((f) => f.getIn(['deviceConfig', 'floorplanUrl']) != null)
    .filter((f) => f.getIn(['deviceConfig', 'floorplanUrl']).includes('&d=live'))
    .groupBy((f) => f.getIn(['domain', 'id'], 'Unassigned'))
    .sortBy(
      (value, key) => key,
      (a, b) => {
        if (a === 'Unassigned') {
          return 1
        }
        if (b === 'Unassigned') {
          return -1
        }
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        if (a === b) {
          return 0
        }
      },
    )
})
