import {createSelector} from 'reselect'

const getDevices = (state) => state.get('devices')

export const getDevicesPerDomain = createSelector([getDevices], (devices) => {
  return devices.groupBy((f) => f.getIn(['domain', 'id'], 'Unassigned')).sortBy(
    (value, key) => key,
    (a, b) => {
      if (a === 'Unassigned') {
        return 1
      }
      if (b === 'Unassigned') {
        return -1
      }
      if (a < b) {
        return -1
      }
      if (a > b) {
        return 1
      }
      if (a === b) {
        return 0
      }
    },
  )
})
