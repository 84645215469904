import * as _ from 'immutable'
import * as types from '../action/types'

export default (state = _.Map(), action) => {
  // eslint-disable-line complexity
  switch (action.type) {
    case types.SET_JOBS:
      return state.set('all', action.payload)
    case types.SET_EXPANDED_JOB_ERROR_INFO:
      return state.mergeIn(['expandedErrorInfo'], action.payload)
    case types.POST_JOB:
      return state.set('isPostJobRunning', true)
    case types.POST_JOB_DONE:
      return state.set('isPostJobRunning', false)
    case types.CLEAR_USER_DATA:
      return state
    default:
      return state
  }
}
