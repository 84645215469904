import React from 'react'
import PropTypes from 'prop-types'
import getClasses from '../../utils/getClasses'

const c = getClasses({
  header: {
    margin: '38px 0 16px',
    fontSize: '30px',
    lineHeight: '60px',
    fontWeight: '200',
    color: '#656559',
    textShadow: '0 1px 0 #fff',
  },
})

const MainHeader = ({children}) => {
  return (
    <div className={c.header}>{children}</div>
  )
}

MainHeader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainHeader
