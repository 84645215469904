import React from 'react'
import PropTypes from 'prop-types'
import * as _ from 'immutable'
import config from 'config'
import format from 'date-fns/format'
import addMinutes from 'date-fns/add_minutes'
import ProfileAdminItem from '../profile-admin-item'
import triggerEnterEvent from './trigger-enter-event'
import triggerExitEvent from './trigger-exit-event'

const container = {
  margin: '20px 0 0 140px',
  padding: '40px 85px',
  width: 'calc(100% - 340px)',
  minWidth: '800px',
  maxWidth: '800px',
  background: '#fff',
}

const getFirst = (string, n = 5) => {
  return string.split('_')[0]
}

const ProfileAdmin = ({user, accessToken}) => {
  const userId = user.get('id', '')
  /* eslint-disable max-len */
  const graphiqlUserId = '/admin/graphiql?query=%7B%0A%20%20me%20%7B%0A%20%20%20%20id%0A%20%20%7D%0A%7D%0A&channel=beta'
  const graphiqlEmail = '/admin/graphiql?query=%7B%0A%20%20me%20%7B%0A%20%20%20%20email%0A%20%20%7D%0A%7D%0A&channel=beta'
  /* eslint-enable */
  const meetingTime = addMinutes(Date.now(), 14)
  const meetingTimeHeading = format(meetingTime, 'YYYY-MM-DD HH:mm')
  const meetingRoomId = 'cr-seld-glg-f5-partickthistle'
  const meetingRoomOnAnotherFloorId = 'cr-seld-glg-f6-laphroaig'
  const twoRoomIds = ['cr-seld-glg-f6-bowmore1', 'cr-seld-glg-f6-bowmore2']
  const roomIds = [
    'cr-seld-glg-f2-bagpipe',
    'cr-seld-glg-f3-newark5',
    'cr-seld-glg-f4-muckhart2',
    'cr-seld-glg-f5-casablanca',
    'cr-seld-glg-f5-mcgrain2',
    'cr-seld-glg-f5-queenspark',
    // should be compunded into one bubble
    'cr-seld-glg-f6-bowmore2',
    'cr-seld-glg-f6-littlemill1',
    'cr-seld-glg-f6-lochside2',
  ]
  const proximityId = 'TV5Adev'
  const deviceId = `admin-dev-deviceId_${userId}`
  const deviceId2 = `admin-dev-deviceId2_${userId}`
  const proximityUrl = `${config.FLOORPLAN_APP_EVENTS}/${proximityId}/debug?token=addYourCredentialsRetrivedAccessTokenHere`

  return (
    <div>
      <div style={container}>
        <ProfileAdminItem heading="User ID" text={userId} link="GraphiQL (channel=beta)" linkEvent={graphiqlUserId} />
        <ProfileAdminItem
          heading="Email"
          text={user.get('email', '')}
          link="GraphiQL (channel=beta)"
          linkEvent={graphiqlEmail}
        />
        {user
          .get('domains', _.List())
          .map((domain) => {
            const id = domain.get('id', '')
            const graphiqlDomain = `/admin/graphiql?query=%7B%0A%09domain(id%3A%20%22${encodeURIComponent(
              id,
            )}%22)%20%7B%0A%09%20%20id%0A%09%7D%0A%7D%0A&channel=beta` // eslint-disable-line max-len
            return (
              <ProfileAdminItem
                key={id}
                heading="Domain"
                text={id}
                link="GraphiQL (channel=beta)"
                linkEvent={graphiqlDomain}
              />
            )
          })
          .toJS()}
      </div>
      <div style={container}>
        <ProfileAdminItem heading="Access Token – User" text={accessToken} />
      </div>
      <div style={container}>
        <ProfileAdminItem
          heading="Floorplan App Events Debug (TV5Adev)"
          text={proximityUrl}
          link="Link"
          linkEvent={proximityUrl}
        />
      </div>
      <div style={container}>
        <ProfileAdminItem
          heading={`Enter meeting (${getFirst(deviceId)}, ${proximityId}, ${meetingRoomId}, ${meetingTimeHeading})`}
          text={`curl -v -X POST -H "Authorization: Bearer ${
            accessToken
          }" -H "Content-Type: application/json" -d '{"proximityId":"${proximityId}","userId":"${userId}","deviceId":"${
            deviceId
          }","type":"enter","meetings":[{"roomId":"${meetingRoomId}","startTime":"${format(meetingTime)}"}]}' ${
            config.FLOORPLAN_APP_EVENTS
          }`} // eslint-disable-line max-len
          link="Trigger"
          linkEvent={() => {
            triggerEnterEvent(accessToken, {userId, proximityId, deviceId, meetingRoomId, meetingTime})
          }}
        />
        <ProfileAdminItem
          heading={`Enter meeting (${getFirst(deviceId)}, ${proximityId}, ${meetingRoomOnAnotherFloorId}, ${
            meetingTimeHeading
          })`}
          text={`curl -v -X POST -H "Authorization: Bearer ${
            accessToken
          }" -H "Content-Type: application/json" -d '{"proximityId":"${proximityId}","userId":"${userId}","deviceId":"${
            deviceId
          }","type":"enter","meetings":[{"roomId":"${meetingRoomOnAnotherFloorId}","startTime":"${format(
            meetingTime,
          )}"}]}' ${config.FLOORPLAN_APP_EVENTS}`} // eslint-disable-line max-len
          link="Trigger"
          linkEvent={() => {
            triggerEnterEvent(accessToken, {
              userId,
              proximityId,
              deviceId,
              meetingRoomId: meetingRoomOnAnotherFloorId,
              meetingTime,
            })
          }}
        />
        <ProfileAdminItem
          heading={`Enter meeting (${getFirst(deviceId2)}, ${proximityId}, ${meetingRoomOnAnotherFloorId}, ${
            meetingTimeHeading
          })`}
          text={`curl -v -X POST -H "Authorization: Bearer ${
            accessToken
          }" -H "Content-Type: application/json" -d '{"proximityId":"${proximityId}","userId":"${userId}","deviceId":"${
            deviceId2
          }","type":"enter","meetings":[{"roomId":"${meetingRoomOnAnotherFloorId}","startTime":"${format(
            meetingTime,
          )}"}]}' ${config.FLOORPLAN_APP_EVENTS}`} // eslint-disable-line max-len
          link="Trigger"
          linkEvent={() => {
            triggerEnterEvent(accessToken, {
              userId,
              proximityId,
              deviceId: deviceId2,
              meetingRoomId: meetingRoomOnAnotherFloorId,
              meetingTime,
            })
          }}
        />
        <ProfileAdminItem
          heading="Enter 2x meetings on floor 6"
          text={'multi meeting'}
          link="Trigger"
          linkEvent={() => {
            triggerEnterEvent(accessToken, {
              userId,
              proximityId,
              deviceId: deviceId2,
              roomIds: twoRoomIds,
              meetingTime,
            })
          }}
        />
        <ProfileAdminItem
          heading="Enter multi meeting"
          text={'multi meeting'}
          link="Trigger"
          linkEvent={() => {
            triggerEnterEvent(accessToken, {
              userId,
              proximityId,
              deviceId: deviceId2,
              roomIds,
              meetingTime,
            })
          }}
        />
        <ProfileAdminItem
          heading="Exit (after delay)"
          text={`curl -v -X POST -H "Authorization: Bearer ${
            accessToken
          }" -H "Content-Type: application/json" -d '{"proximityId":"${proximityId}","userId":"${userId}","deviceId":"${
            deviceId
          }","type":"exit"}' ${config.FLOORPLAN_APP_EVENTS}`} // eslint-disable-line max-len
          link="Trigger"
          linkEvent={() => {
            triggerExitEvent(accessToken, {proximityId, userId, deviceId})
          }}
        />
        <ProfileAdminItem
          heading={`Enter Enter/Exit bubble flicker (${proximityId}, ${meetingRoomId}, ${meetingTimeHeading})`}
          // text={'Enter -> (15ms) -> Exit -> (1030ms) -> Enter (41ms) -> Exit -> (905ms) -> Enter (6748ms)  -> (338ms) -> Enter (304ms)'} // eslint-disable-line max-len
          text={'Enter Enter/Exit bubble flicker'} // eslint-disable-line max-len
          link="Trigger"
          linkEvent={() => {
            const firstEnterTimeout = 0
            const firstExitTimeout = firstEnterTimeout + 5000
            const secondEnterTimeout = firstExitTimeout + 1000 * 12
            let t
            setTimeout(() => {
              t = format(meetingTime)
              triggerEnterEvent(accessToken, {userId, proximityId, deviceId, meetingRoomId, meetingTime: t})
            }, firstEnterTimeout)

            setTimeout(() => {
              triggerExitEvent(accessToken, {proximityId, userId, deviceId})
            }, firstExitTimeout)

            setTimeout(() => {
              triggerEnterEvent(accessToken, {userId, proximityId, deviceId, meetingRoomId, meetingTime: t})
            }, secondEnterTimeout)
          }}
        />
        <ProfileAdminItem
          heading={`Exit Enter/Exit bubble flicker (${proximityId}, ${meetingRoomId}, ${meetingTimeHeading})`}
          text={'Exit Enter/Exit bubble flicker'} // eslint-disable-line max-len
          link="Trigger"
          linkEvent={() => {
            triggerExitEvent(accessToken, {proximityId, userId, deviceId})
          }}
        />
      </div>
      <div style={container}>
        <ProfileAdminItem
          heading="Set User Avatar"
          text={`curl -i -X PUT -F avatar=@cache.jpg -H "Authorization: Bearer ${
            accessToken
          }" ${config.USER_URL}/users/me/avatar`} // eslint-disable-line max-len
        />
      </div>
    </div>
  )
}

ProfileAdmin.propTypes = {
  accessToken: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
}

export default ProfileAdmin
