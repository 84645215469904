import * as types from '../action/types'

export default (action, r) => {
  if (action.type !== types.SET_LOCATION) {
    return
  }

  const routes = Array.isArray(r) ? r : [r]
  const path = action.payload.getIn(['location', 'pathname'])

  return routes.every((route) => !route.match(path))
  // return !adminRoute.match(path) && !adminJobsRoute.match(path)
}
