import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'aphrodite/no-important'
import styles from './main.styles'

const Main = ({children}) => {
  return (
    <main className={css(styles.container)}>
      <div className={css(styles.padding)} />
      <div className={css(styles.content)}>{children}</div>
      <div className={css(styles.padding)} />
    </main>
  )
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
