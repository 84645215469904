import * as _ from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import getClasses from '../../utils/getClasses'
import Header from './table/header'
import Rows from './table/rows'

const c = getClasses({
  container: {
    width: '100%',
    padding: '40px 0',
    background: '#fff',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.10)',
    flexDirection: 'row',
    display: 'flex',
    borderRadius: '3px',
    // marginTop: '140px',
  },
  containerPadding: {
    minWidth: '20px',
    flex: '0 1 40px',
  },
  table: {
    width: '100%',
  },
})

const Table = ({headerData, rowsData}) => {
  const headerLength = headerData.length || headerData.size

  return (
    <div className={c.container}>
      <div className={c.containerPadding} />
      <div className={c.table}>
        <Header data={headerData} />
        <Rows data={rowsData} headerLength={headerLength} />
      </div>
      <div className={c.containerPadding} />
    </div>
  )
}

Table.propTypes = {
  headerData: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.instanceOf(_.List).isRequired,
  ]).isRequired,
  rowsData: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.instanceOf(_.List).isRequired,
  ]).isRequired,
}

export default Table
