import * as _ from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import Row from './row'

const Rows = ({data, headerLength}) => {
  return data.map((datum, i) => {
    const key = datum.key || datum.get('key')
    const rowData = datum.data || datum.get('data')
    const job = datum.job || datum.get('job')

    return (
      <Row rowIndex={i} data={rowData} job={job} headerLength={headerLength} key={key} />
    )
  })
}

Rows.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.instanceOf(_.List).isRequired,
  ]).isRequired,
  headerLength: PropTypes.number.isRequired,
}

export default Rows
