import React from 'react'
import PropTypes from 'prop-types'
import * as _ from 'immutable'
import {connect} from 'react-redux'
import getClasses from '../utils/getClasses'
import Main from '../components/elements/main'
// import AdminVersions from '../components/admin-versions/admin-versions'
import MainHeader from '../components/elements/main-header'
import Link from '../containers/link'
import Loading from './loading'

const c = getClasses({
  container: {
    padding: '0 0 20px 0',
  },
  header: {
    display: 'block',
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: '200',
    color: '#656559',
    textShadow: '0 1px 0 #fff',
  },
  link: {
    display: 'inline',
  },
  divider: {
    display: 'block',
    width: '100%',
    height: 1,
    background: '#666',
    margin: '0 0 30px',
  },
})
                // <div className={c.divider} />

class AdminDomainsContainer extends React.Component {
  render() {
    const {props} = this
    // will get stuck on loading if there are no domains with versions
    return !props.versions.size ?
      <Loading /> :
      <Main>
        <MainHeader>domains</MainHeader>
        {
          props.versions.map((id) => {
            // const id = domains.get('id')
            return (
              <React.Fragment key={encodeURIComponent(id)}>
                <div className={c.container}>
                  <div className={c.header}>
                    {id} – <Link className={c.link} href={`/domains/${id}/versions`} title={`${id} versions`}>versions</Link>
                  </div>
                </div>
              </React.Fragment>
            )
          })
        }
      </Main>
  }
}

AdminDomainsContainer.propTypes = {
  versions: PropTypes.instanceOf(_.List).isRequired,
}

const mapStateToProps = (state) => {
  return {
    users: state.get('users', _.Map()),
    versions: state
      .getIn(['versions', 'all'], _.List())
      .map((versionsData) => {
        return versionsData.get('id')
      })
      .sortBy((id) => id),
  }
}

export default connect(mapStateToProps, {})(AdminDomainsContainer)
