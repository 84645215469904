export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken')
}

export const setRefreshToken = (refreshToken) => {
  return localStorage.setItem('refreshToken', refreshToken)
}

export const removeRefreshToken = () => {
  return localStorage.removeItem('refreshToken')
}
