export {getDevicesPerDomain} from './getDevicesPerDomain'
export {getFloorplansPerDomain} from './getFloorplansPerDomain'

export const getDomains = (state) => state.getIn(['session', 'domains'])
export const getAccessToken = (state) => state.getIn(['session', 'access_token'])
export const getExpired = (state) => state.getIn(['session', 'expired'])
export const getIsAdmin = (state) => state.getIn(['session', 'admin'], false)
export const getLoginEmailCode = (state) => state.getIn(['login', 'emailCode'])
export const getLoginPin = (state) => state.getIn(['login', 'pin'])
export const getLocation = (state) => state.getIn(['location', 'location'])
export const getSearch = (state) => state.getIn(['location', 'search'])
export const getLocationPath = (state) => state.getIn(['location', 'location', 'pathname'])
export const getRefreshToken = (state) => state.getIn(['session', 'refresh_token'])

export const getInitialRoute = (state) => {
  const redirectUrl = state.getIn(['login', 'redirect'])
  if (redirectUrl) {
    const path = redirectUrl.get('pathname')
    return path === '/login' ? '/' : `${path}${redirectUrl.get('search')}`
  }
}

export const getIsLoading = (state) => {
  // TODO
  return (
    getLocationPath(state) === undefined ||
    getAccessToken(state) === undefined && getDomains(state) === undefined && !state.getIn(['login', 'redirect'])
  )
}
