import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  button: {
    alignItems: 'center',
    width: '222px',
    minHeight: '50px',
    marginTop: '-1px',
    cursor: 'pointer',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    fontWeight: '400',
    boxShadow: '0 3px 8px rgba(0, 0, 0, 0.2)',
    ':hover': {
      boxShadow: '0 2px 7px rgba(0, 0, 0, 0.4)',
    },
    ':focus': {
      marginTop: '0',
      boxShadow: '0 1px 7px rgba(0, 0, 0, 0.55)',
    },
    ':active': {
      marginTop: '0',
      boxShadow: '0 1px 7px rgba(0, 0, 0, 0.55)',
    },
  },
})
