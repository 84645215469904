import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import setRoute from '../action/creators/set-route'

class Link extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }
  handleClick(e) {
    this.props.setRoute(this.props.href)
    e.preventDefault()
  }
  render() {
    const {children, className, title} = this.props

    return (
      <a
        href={this.props.href}
        title={title || ''}
        className={className || ''}
        onClick={this.handleClick}
        style={this.props.style || {}}
      >
        {children}
      </a>
    )
  }
}

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  setRoute: PropTypes.func.isRequired,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    // user: state.get('user'),
  }
}

export default connect(mapStateToProps, {setRoute})(Link)
