import * as _ from 'immutable'
import {createStore, applyMiddleware} from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '../reducers'

export default () => {
  const sagaMiddleware = createSagaMiddleware()
  const initialState = _.fromJS({
    location: {},
    session: {},
  })
  const store = createStore(rootReducer, initialState, applyMiddleware(...[sagaMiddleware]))
  const runSaga = sagaMiddleware.run
  // store.close = () => store.dispatch(END)

  return {
    ...store,
    runSaga,
  }
}
