import React from 'react'
import PropTypes from 'prop-types'
import * as _ from 'immutable'
import {connect} from 'react-redux'
import Main from '../components/elements/main'
import AdminVersions from '../components/admin-versions/admin-versions'
import Loading from './loading'

class AdminVersionsContainer extends React.Component {
  render() {
    const {props} = this
    // will get stuck on loading if there are no domains with versions
    return !props.versions.size ?
      <Loading /> :
      <Main>
        {
          props.versions.map((domains) => {
            const id = domains.get('id')

            return (
              <AdminVersions
                key={encodeURIComponent(id)}
                versions={domains.get('versions')}
                domainId={id}
                groupName={id}
              />
            )
          })
        }
      </Main>
  }
}

AdminVersionsContainer.propTypes = {
  versions: PropTypes.instanceOf(_.List).isRequired,
}

const mapStateToProps = (state) => {
  return {
    users: state.get('users', _.Map()),
    versions: state
      .getIn(['versions', 'all'], _.List())
      .sortBy((versionsData) => versionsData.get('id')),
  }
}

export default connect(mapStateToProps, {})(AdminVersionsContainer)
