import * as types from '../action/types'
import {getInitialRoute} from '../selectors/index'
import removeLoginRedirect from '../action/creators/remove-login-redirect'
import onLoadLocation from './on-load-location'
import {call, put, select, take} from 'redux-saga/effects'

export default function* (history) {
  while (true) {
    // eslint-disable-line no-constant-condition
    yield take(types.SET_AUTHORIZATION)
    // TODO: if no domains (when there is a domain for analytics); fail request
    // TODO: can this fail after we removed analytics
    const initialRoute = yield select(getInitialRoute)
    yield put(removeLoginRedirect())

    if (initialRoute) {
      yield history.replace(initialRoute)
    } else {
      yield call(onLoadLocation, history)
    }
  }
}
