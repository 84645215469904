import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  active: {
    padding: '10px 5px 0',
    display: 'flex',
    flexDirection: 'column',
    ':hover > [class^="labelInactive"]': {
      // color: '#12aaeb',
      color: '#9da5ab',
    },
    ':hover > [class^="stateIndicatorInactive"]': {
      background: '#b5bcc1',
    },
  },
  inactive: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 auto',
    width: 'auto',
    padding: '10px 5px 0',
    cursor: 'pointer',
    ':hover > [class^="labelInactive"]': {
      // color: '#12aaeb',
      color: '#9da5ab',
    },
    // ':hover > [class^="stateIndicatorInactive"]': {
    //   background: '#b5bcc1',
    // },
  },
  labelActive: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 18px',
    fontSize: '12px',
    fontWeight: '700',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#12aaeb',
  },
  labelInactive: {
    display: 'flex',
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 18px',
    fontSize: '12px',
    fontWeight: '700',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#b5bcc1',
  },
  stateIndicatorInactive: {
    height: '4px',
    width: '100%',
    // background: '#12aaeb',
  },
  stateIndicatorActive: {
    height: '4px',
    width: '100%',
    background: '#12aaeb',
  },
})
