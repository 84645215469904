import config from 'config'
import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'aphrodite'
import GraphiQL from 'graphiql'
import getProps from './get-props'
import styles from './styles'
import getParameters from './get-parameters'
import updateURL from './update-url'
import defaultQuery from './default-query'
import updateQueryArguments from './update-query-arguments'

const buttonContainerClass = css(styles.buttonContainer)
const buttonClass = css(styles.button)
const labelClass = css(styles.label)
const inputContainerClass = css(styles.inputContainer)
const labelTextClass = css(styles.labelTextClass)
const inputClass = css(styles.input)
const activeButtonClass = css(styles.activeButton)

class AdminGraphiQL extends React.Component {
  constructor(props) {
    super(props)
    this.state = {parameters: getParameters()}
  }
  componentWillMount() {
    this.setDevChannel = this.updateQueryArguments.bind(this, 'channel', 'dev')
    this.setBetaChannel = this.updateQueryArguments.bind(this, 'channel', 'beta')
    this.setStableChannel = this.updateQueryArguments.bind(this, 'channel', 'stable')
  }
  componentDidMount() {
    if (Object.keys(this.state.parameters).length) {
      updateURL(this.state.parameters)
    } else {
      updateURL({query: defaultQuery})
    }
  }
  updateQueryArguments(key, value) {
    updateQueryArguments(key, value)
    this.setState(() => {
      return {parameters: getParameters()}
    })
  }
  updateStateWith(key, value) {
    this.setState(() => {
      return {parameters: getParameters()}
    })
  }
  render() {
    // eslint-disable-line complexity
    const {token, parameters} = this.state
    const {channel, url} = parameters

    return (
      <GraphiQL
        ref={(c) => {
          this.graphiql = c
        }} // eslint-disable-line
        {...getProps(this.props, this.state)}
      >
        <GraphiQL.Logo>&nbsp;</GraphiQL.Logo>
        <GraphiQL.Toolbar>
          <div className={buttonContainerClass}>
            <div
              className={buttonClass}
              onClick={() => {
                this.graphiql.handlePrettifyQuery()
              }} // eslint-disable-line
            >
              Prettify
            </div>
            <div
              className={buttonClass}
              onClick={() => {
                this.graphiql.handleToggleHistory()
              }} // eslint-disable-line
            >
              History
            </div>
            <div className={css(styles.vr)} />
            <div
              className={`${buttonClass} ${channel === 'dev' ? activeButtonClass : ''}`}
              onClick={this.setDevChannel} // eslint-disable-line
            >
              Channel: Dev
            </div>
            <div
              className={`${buttonClass} ${channel === 'beta' ? activeButtonClass : ''}`}
              onClick={this.setBetaChannel} // eslint-disable-line
            >
              Channel: Beta
            </div>
            <div
              className={`${buttonClass} ${!channel || channel === 'stable' ? activeButtonClass : ''}`}
              onClick={this.setStableChannel} // eslint-disable-line
            >
              Channel: Stable
            </div>
          </div>
          <div className={inputContainerClass}>
            <label className={labelClass}>
              <span className={labelTextClass}>Token</span>
              <input
                className={inputClass}
                defaultValue={token || this.props.accessToken}
                placeholder={this.props.accessToken}
                onChange={({target: {value}}) => {
                  // eslint-disable-line react/jsx-no-bind
                  this.setState((state) => ({...state, token: value}))
                  this.updateStateWith('token', value)
                }}
              />
            </label>
            <label className={labelClass}>
              <span className={labelTextClass}>URL</span>
              <input
                className={inputClass}
                defaultValue={`${url || config.GRAPHQL}`}
                placeholder={`${config.GRAPHQL}`}
                onChange={({target: {value}}) => {
                  // eslint-disable-line react/jsx-no-bind
                  // TODO: check for https – don't allow non https
                  this.updateQueryArguments('url', value)
                }}
              />
            </label>
          </div>
        </GraphiQL.Toolbar>
      </GraphiQL>
    )
  }
}

AdminGraphiQL.propTypes = {
  accessToken: PropTypes.string.isRequired,
}

export default AdminGraphiQL
