import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '60px',
    border: '1px solid #e3e9ea',
    borderTop: 'none',
  },
  rowOdd: {
    backgroundColor: '#fafdff',
  },
  rowEven: {
    backgroundColor: '#fff',
  },
  rowItem: {
    display: 'block',
    justifyContent: 'center',
    paddingTop: '1px',
    fontSize: '14px',
    lineHeight: '32px',
    fontWeight: '400',
    color: '#8d8d8d',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flex: '0 1 100px',
  },
  rowItemFlex: {
    flex: '1 1 100px',
  },
  rowItemTime: {
    flex: '0 1 120px',
  },
  rowItemPadding: {
    minWidth: '10px',
    flex: '0 1 30px',
  },
  rowItemDivider: {
    minWidth: '1px',
    width: '1px',
    height: '38px',
  },
  rowItemStatus: {
    width: '110px',
    flex: '0 0 auto',
  },
  isOld: {
    color: '#fe7474',
  },
})
