import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  statusMessage: {
    width: '150px',
    height: '40px',
    padding: '0 10px',
    fontSize: '13px',
    lineHeight: '40px',
  },
})
