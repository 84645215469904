import {css, StyleSheet} from 'aphrodite/no-important'

export default (styles) => {
  const s = StyleSheet.create(styles)

  return Object.keys(s).reduce((acc, key) => {
    acc[key] = css(s[key])

    return acc
  }, {})
}
