import React from 'react'
import PropTypes from 'prop-types'
import * as _ from 'immutable'
import {connect} from 'react-redux'
import postJob from '../action/creators/post-job'
import Main from '../components/elements/main'
import ImportNew from '../components/admin-jobs/import-new'
import ImportJobs from '../components/admin-jobs/import-jobs'
import Loading from './loading'

class Import extends React.Component {
  // shouldComponentUpdate(newProps) {
  //   return (
  //     !_.is(this.props.jobs, newProps.jobs) ||
  //     this.props.isPostJobRunning && newProps.isPostJobRunning
  //   )
  // }
  render() {
    const {props} = this
    // console.log(!props.jobs.size && !props.gitBranches.size)
    return !props.jobs.size && !props.gitBranches.size ? <Loading /> : (
      <Main>
        <ImportNew {...props} />
        <ImportJobs {...props} />
      </Main>
    )
  }
}

Import.propTypes = {
  gitBranches: PropTypes.instanceOf(_.List).isRequired,
  isPostJobRunning: PropTypes.bool.isRequired,
  jobs: PropTypes.instanceOf(_.List).isRequired,
}

const mapStateToProps = (state) => {
  // console.log(state
  //     .getIn(['gitBranches', 'all'], _.List())
  //     .sortBy((gitBranch) => gitBranch).size)
  return {
    jobs: state
      .getIn(['jobs', 'all'], _.List())
      .sortBy((jobs) => jobs.get('createdAt'))
      .reverse(),
    // jobs: _.List(),
    gitBranches: state
      .getIn(['gitBranches', 'all'], _.List())
      .sortBy((gitBranch) => gitBranch),
    isPostJobRunning: state.getIn(['jobs', 'isPostJobRunning'], false),
  }
}

export default connect(mapStateToProps, {postJob})(Import)
