import * as _ from 'immutable'
import * as types from '../action/types'

const reduce = (state = _.Map(), action) => {
  // eslint-disable-line complexity
  switch (action.type) {
    case types.SET_CURRENT_AVAILABILITY:
      return state.set('currentAvailability', action.payload)
    case types.CLEAR_USER_DATA:
      return state
    default:
      return state
  }
}

export default reduce
