import * as _ from 'immutable'
import * as types from '../action/types'

const reduce = (state = _.Map(), action) => {
  // eslint-disable-line complexity
  switch (action.type) {
    case types.SET_LOGIN_REDIRECT:
      return state.set('redirect', action.payload)
    case types.SET_PENDING_AUTHENTICATION:
      return state.set('pendingAuthentication', action.payload)
    case types.SET_EMAIL:
      return state.set('email', action.payload)
    case types.SET_EMAIL_CODE:
      return state.set('emailCode', action.payload)
    case types.SET_PIN:
      return state.set('pin', action.payload)
    case types.REMOVE_EMAIL:
      return state.delete('email').delete('emailCode')
    case types.SET_AUTHENTICATE_ERROR:
      return state.set('authenticateError', action.payload)
    case types.REMOVE_LOGIN_REDIRECT:
      return state.delete('redirect')
    case types.REMOVE_AUTHENTICATE_ERROR:
      return state.delete('authenticateError')
    case types.REMOVE_PIN:
      return state.delete('pin')
    default:
      return state
  }
}

export default reduce
