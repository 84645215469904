import setRefreshToken from '../action/creators/set-refresh-token'
import setLoginRedirect from '../action/creators/set-login-redirect'
import {getRefreshToken} from '../services/auth'
import {getLocation} from '../selectors/index'
import {call, put, select} from 'redux-saga/effects'

export default function* (history) {
  const refreshToken = yield call(getRefreshToken)

  if (refreshToken) {
    yield put(setRefreshToken(refreshToken))
  } else {
    const location = yield select(getLocation)
    yield put(setLoginRedirect(location))
    history.replace('/login')
  }
}
