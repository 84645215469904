import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'aphrodite/no-important'

import distanceInWordsToNow from 'date-fns/distance_in_words_to_now'
// import parse from 'date-fns/parse'
import subMinutes from 'date-fns/sub_minutes'
import isBefore from 'date-fns/is_before'
import styles from './admin-sensor-events-row.styles'

const AdminSensorEventsRow = ({
  eventType,
  resourceId,
  resourceName,
  rssi,
  sensorId,
  sensorType,
  timestamp,
  txType,

  rowIndex,
}) => {
  const time = subMinutes(timestamp, new Date().getTimezoneOffset())
  const modified = distanceInWordsToNow(time, {addSuffix: true})
  const isOld = isBefore(time, subMinutes(new Date(), 15))
  const rowColoringClass = rowIndex % 2 ? styles.rowOdd : styles.rowEven

  return (
    <div>
      <div className={css(styles.row, rowColoringClass)}>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItem)}>{sensorId}</div>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItemDivider)} />
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItem)}>{sensorType}</div>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItemDivider)} />
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItem, styles.rowItemFlex)}>{resourceName}</div>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItemDivider)} />
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItem, styles.rowItemFlex)}>{resourceId}</div>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItemDivider)} />
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItem)}>{eventType}</div>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItemDivider)} />
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItem)}>{rssi}</div>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItemDivider)} />
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItem, styles.rowItemTime, isOld && styles.isOld)}>{modified}</div>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItemDivider)} />
      </div>
    </div>
  )
}

AdminSensorEventsRow.propTypes = {
  eventType: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  rowIndex: PropTypes.number.isRequired,
  rssi: PropTypes.string.isRequired,
  sensorId: PropTypes.string.isRequired,
  sensorType: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
}

export default AdminSensorEventsRow
