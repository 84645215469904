import React from 'react'
import PropTypes from 'prop-types'

const LoginH2 = ({children}) => {
  return (
    <h2
      style={{
        fontSize: '30px',
        textAlign: 'center',
        fontWeight: '200',
      }}
    >
      {children}
    </h2>
  )
}

LoginH2.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

export default LoginH2
