import * as _ from 'immutable'
import {delay} from 'redux-saga'
import config from 'config' // eslint-disable-line import/no-unresolved
import setGitBranches from '../action/creators/set-git-branches'
import setJobs from '../action/creators/set-jobs'
import setUsers from '../action/creators/set-users'
import renewAccessToken from '../action/creators/renew-access-token'
import * as types from '../action/types'
import {emptyRoute, adminRoute, adminJobsRoute, adminJobRoute} from '../routes'
import {getAccessToken} from '../selectors'
import rejectOnGraphqlErrors from '../utils/reject-on-graphql-errors'
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects'

const fetchJobs = (accessToken) => {
  return fetch(
    config.GRAPHQL,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        query: `
          query adminData {
            admin {
              jobs {
                id
                status
                createdAt
                modifiedAt
                command
                domainId
                gitBranch
                channelId
                gitCommitSha
                gitCommitMessage
                user {
                  id
                  fullname
                  avatarUrl
                  email
                }
                error
              }
              gitBranches
            }
          }
        `,
      }),
    },
  )
    .then((r) => r.json())
    .then(rejectOnGraphqlErrors)
    .then((r) => {
      return _.fromJS(r).getIn(['data', 'admin'])
    })
}

const navigateToJobs = (action) => {
  if (action.type !== types.SET_LOCATION) {
    return
  }
  const path = action.payload.getIn(['location', 'pathname'])
  return emptyRoute.match(path) || adminRoute.match(path) || adminJobsRoute.match(path) || adminJobRoute.match(path)
}

const navigateFromJobs = (action) => {
  if (action.type !== types.SET_LOCATION) {
    return
  }
  const path = action.payload.getIn(['location', 'pathname'])
  return !emptyRoute.match(path) && !adminRoute.match(path) && !adminJobsRoute.match(path) && !adminJobRoute.match(path)
}

const getUser = (jobs) => {
  return jobs
    .map((job) => job.get('user'))
    .filter(Boolean)
    .toSet()
}

const backgroundFetch = function* () {
  while (true) {
    // eslint-disable-line no-constant-condition
    const accessToken = yield select(getAccessToken)

    if (accessToken) {
      try {
        const adminData = yield call(fetchJobs, accessToken)
        const jobs = adminData.get('jobs')
        const gitBranches = adminData.get('gitBranches')

        yield put(setGitBranches(gitBranches))
        yield put(setUsers(getUser(jobs)))
        yield put(setJobs(_.fromJS(jobs)))
      } catch (e) {
        console.error('[catch] watch-admin-navigate-load-jobs', e) // eslint-disable-line no-console
        const token = yield select(getAccessToken)

        if (token) {
          yield put(renewAccessToken())
        }
      }
    }

    yield race({
      navigateToJobs: take(navigateToJobs),
      updateOnNewAccessToken: take(types.SET_EXPIRE_IN),
      updateAfterPostJob: take(types.POST_JOB_DONE),
      continue: call(delay, 1000 * 10),
    })
  }
}

export default function* () {
  while (true) {
    // eslint-disable-line no-constant-condition
    yield take(navigateToJobs)
    const backgroundFetchTask = yield fork(backgroundFetch)
    yield take(navigateFromJobs)
    yield cancel(backgroundFetchTask)
  }
}
