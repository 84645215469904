import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'aphrodite/no-important'

import distanceInWordsToNow from 'date-fns/distance_in_words_to_now'
// import parse from 'date-fns/parse'
import subMinutes from 'date-fns/sub_minutes'
import isBefore from 'date-fns/is_before'
import Link from '../../containers/link'
import styles from './admin-sensor-events-row.styles'

const AdminSensorEventsRow = ({
  eventType,
  rssi,
  resourceId,
  resourceName,
  resourceType,
  sensorId,
  sensorType,
  timestamp,
  txType,

  rowIndex,
}) => {
  const time = subMinutes(timestamp, new Date().getTimezoneOffset())
  const isMissingEvent = !timestamp
  const modified = timestamp ? distanceInWordsToNow(time, {addSuffix: true}) : 'no event received'
  const isOld = isBefore(time, subMinutes(new Date(), 15))
  const rowColoringClass = rowIndex % 2 ? styles.rowOdd : styles.rowEven
  const isError = isOld || isMissingEvent
  const resourceLink = `/admin/sensor-events/${resourceType}/${resourceId}`

  return (
    <div>
      <div className={css(styles.row, rowColoringClass)}>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItem)}>{sensorId}</div>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItemDivider)} />
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItem)}>{sensorType}</div>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItemDivider)} />
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItem, styles.rowItemFlex)}>
          <Link href={resourceLink} className={css(styles.link)}>
            {resourceId}
          </Link>
        </div>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItemDivider)} />
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItem, styles.rowItemFlex)}>{resourceName}</div>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItemDivider)} />
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItem)}>{eventType}</div>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItemDivider)} />
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItem)}>{rssi}</div>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItemDivider)} />
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItem, styles.rowItemTime, isError && styles.isOld)}>{modified}</div>
        <div className={css(styles.rowItemPadding)} />
        <div className={css(styles.rowItemDivider)} />
      </div>
    </div>
  )
}

AdminSensorEventsRow.propTypes = {
  eventType: PropTypes.string.required,
  resourceId: PropTypes.string.required,
  resourceName: PropTypes.string,
  resourceType: PropTypes.string.required,
  rowIndex: PropTypes.number.isRequired,
  rssi: PropTypes.string,
  sensorId: PropTypes.string.isRequired,
  sensorType: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
}

export default AdminSensorEventsRow
