import Route from 'route-parser'

export const adminDevicesRoute = new Route('/admin/devices')
export const adminDeviceRoute = new Route('/admin/devices/:deviceId')
export const domainsRoute = new Route('/domains')
export const domainRoute = new Route('/domains/:domainId')
export const domainVersionsRoute = new Route('/domains/:domainId/versions')
export const domainPublicationsRoute = new Route('/domains/:domainId/publications')
export const adminDomainPublicationRoute = new Route('/domains/:domainId/publications/:channelId')
export const adminGraphiqlRoute = new Route('/admin/graphiql')
export const adminJobRoute = new Route('/jobs/:jobId')
export const adminJobsRoute = new Route('/jobs')
export const adminRealtimeRoute = new Route('/admin/realtime')
export const adminRoute = new Route('/admin')
export const adminSensorEventsRoomIdRoute = new Route('/admin/sensor-events/room/:roomId')
export const adminSensorEventsRoute = new Route('/admin/sensor-events/floor/:floorId')
export const adminVersionRoute = new Route('/domains/:domainId/versions/:versionId')
export const adminVersionsRoute = new Route('/versions')
// export const adminDomainConfigsRoute = new Route('/admin/configs')
// export const adminDomainConfigRoute = new Route('/admin/configs/:domainId')
export const adminResourceRoute = new Route('/admin/resources/:resourceType/:resourceId')
export const adminHealthStatusesRoute = new Route('/admin/health-statuses')
export const adminUserAnalyticsRoute = new Route('/admin/user-analytics')
export const adminUserListRoute = new Route('/admin/user-list')
export const emptyRoute = new Route('/')
export const loginRoute = new Route('/login')
export const profileRoute = new Route('/profile')
export const singleSignOnRoute = new Route('/single-sign-on')

export default {
  domainsRoute,
  domainRoute,
  domainVersionsRoute,
  domainPublicationsRoute,
  adminDevicesRoute,
  adminDeviceRoute,
  adminDomainPublicationRoute,
  adminGraphiqlRoute,
  adminJobRoute,
  adminJobsRoute,
  adminRealtimeRoute,
  adminRoute,
  adminSensorEventsRoomIdRoute,
  adminSensorEventsRoute,
  adminVersionRoute,
  adminVersionsRoute,
  adminHealthStatusesRoute,
  // adminDomainConfigsRoute,
  // adminDomainConfigRoute,
  adminResourceRoute,
  emptyRoute,
  loginRoute,
  singleSignOnRoute,
  adminUserAnalyticsRoute,
  adminUserListRoute,
}
