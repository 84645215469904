import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  form: {
    display: 'flex',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    paddingTop: '100px',
    paddingLeft: '120px',
    paddingBottom: '120px',
    paddingRight: '120px',
  },
  email: {
    fontSize: '20px',
    textAlign: 'center',
    marginTop: '20px',
    color: '#879092',
    fontWeight: '100',
    minHeight: '30px',
    lineHeight: '30px',
  },
  pinLabel: {
    width: '100%',
    marginTop: '22px',
  },
  pin: {
    textTransform: 'uppercase',
    color: '#879092',
    fontWeight: '200',
  },
  pinInput: {
    height: '50px',
    width: '100%',
    marginTop: '10px',
  },
  error: {
    fontSize: '20px',
    textAlign: 'center',
    marginTop: '20px',
    color: '#879092',
    fontWeight: '100',
    minHeight: '30px',
    lineHeight: '30px',
  },
  changeEmailContainer: {
    textAlign: 'center',
    fontSize: '12px',
    minHeight: '30px',
    lineHeight: '30px',
  },
  link: {
    color: '#5C5556',
    cursor: 'pointer',
    ':hover': {
      opacity: '0.8',
    },
    ':focus': {
      opacity: '0.6',
    },
    ':active': {
      opacity: '0.6',
    },
  },
  loginButtonContainer: {
    marginTop: '18px',
    width: '100%',
    alignItems: 'center',
  },
})
