import React from 'react'
import PropTypes from 'prop-types'

const personal = {
  flexDirection: 'row',
  display: 'flex',
  margin: '0 0 0 140px',
  width: 'calc(100% - 340px)',
  minWidth: '800px',
  maxWidth: '800px',
  background: '#fff',
}

const avatarContainer = {
  width: '240px',
  height: '550px',
  background: '#FAF9FA',
  flex: '0 0 auto',
}

const avatarContainerInner = {
  position: 'relative',
  margin: '50px 0 0 -60px',
  width: '240px',
  height: '240px',
}

const border = {
  position: 'absolute',
  top: '3px',
  left: '3px',
  width: 'calc(100% - 6px)',
  height: 'calc(100% - 6px)',
  border: '3px solid #fff',
  borderRadius: '100%',
}

const avatar = {
  borderRadius: '100%',
}

const email = {
  display: 'block',
  padding: '98px 80px 0',
  fontSize: '24px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: '#656559',
}

const signout = {
  margin: '98px 80px 0',
  fontSize: '24px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

const ProfilePersonal = ({user}) => {
  return (
    <div style={personal}>
      <div style={avatarContainer}>
        <div style={avatarContainerInner}>
          <div style={border} />
          <img style={avatar} src={user.get('avatar', '')} alt="my user profile picture" width="240px" height="240px" />
        </div>
      </div>
      <div style={{width: '100%', overflow: 'hidden'}}>
        <div style={email}>{user.get('email', '')}</div>
        <label
          style={{
            width: '360px',
            marginTop: '11px',
            marginLeft: '85px',
            paddingTop: '40px',
          }}
        >
          <div
            style={{
              textTransform: 'uppercase',
              color: '#879092',
              fontWeight: '200',
            }}
          >
            Name
          </div>
          <input
            style={{
              height: '50px',
              width: '100%',
              marginTop: '10px',
            }}
            autoComplete="off"
            disabled
            value={user.get('fullname') || ''}
          />
        </label>
        <a style={signout} href="/signout">
          Sign out
        </a>
      </div>
    </div>
  )
}

ProfilePersonal.propTypes = {
  user: PropTypes.object.isRequired,
}

export default ProfilePersonal
