import React from 'react'
import PropTypes from 'prop-types'
import {css, StyleSheet} from 'aphrodite/no-important'

const s = StyleSheet.create({
  link: {
    textTransform: 'none',
    color: '#0000ee',
    cursor: 'pointer',
    ':active': {
      color: '#ff0000',
    },
  },
})

const ProfileAdminItemLink = ({link, linkEvent}) => {
  /* eslint-disable no-extra-parens, react/jsx-indent-props, react/jsx-closing-bracket-location */
  return typeof linkEvent === 'string' ? (
    <span>
      {' '}
      -{' '}
      <a className={css(s.link)} href={linkEvent}>
        {link}
      </a>
    </span>
  ) : (
    <span>
      {' '}
      -{' '}
      <span className={css(s.link)} onClick={linkEvent}>
        {link}
      </span>
    </span>
  )
  /* eslint-enable */
}

ProfileAdminItemLink.propTypes = {
  link: PropTypes.string.isRequired,
  linkEvent: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.func.isRequired]).isRequired,
}

export default ProfileAdminItemLink
