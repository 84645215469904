import * as _ from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Main from '../components/elements/main'
import ProfilePersonal from '../components/profile-personal'
import ProfileAdmin from '../components/profile-admin'
import {getIsAdmin} from '../selectors'

const header = {
  margin: '82px 0 98px 140px',
  fontSize: '40px',
  lineHeight: '60px',
  fontWeight: '200',
  color: '#656559',
}

const Profile = (props) => {
  return (
    <Main>
      <div style={header}>Profile</div>
      {!props.user.isEmpty() && <ProfilePersonal {...props} />}
      {props.isAdmin && !props.user.isEmpty() && <ProfileAdmin {...props} />}
    </Main>
  )
}

Profile.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  user: PropTypes.instanceOf(_.Map).isRequired,
}

const mapStateToProps = (state) => {
  // TODO: don't display admin stuff to all "users"
  return {
    isAdmin: getIsAdmin(state) || true,
    accessToken: state.getIn(['session', 'access_token'], ''),
    user: state.get('user'),
  }
}

export default connect(mapStateToProps)(Profile)
