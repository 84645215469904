import React from 'react'
import PropTypes from 'prop-types'
import * as _ from 'immutable'
import {connect} from 'react-redux'
import getClasses from '../utils/getClasses'
import Main from '../components/elements/main'
import AdminPublicationInfo from '../components/admin-publication/admin-publication-info'
import Loading from './loading'

const c = getClasses({
  header: {
    margin: '68px 0 16px',
    fontSize: '30px',
    lineHeight: '60px',
    fontWeight: '200',
    color: '#656559',
    textShadow: '0 1px 0 #fff',
  },
  container: {
    width: '100%',
    // minHeight: 970,
    padding: '40px 0',
    background: '#fff',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.10)',
    flexDirection: 'row',
    display: 'flex',
    borderRadius: '3px',
  },
  containerPadding: {
    minWidth: '20px',
    flex: '0 1 40px',
  },
  divider: {
    display: 'block',
    width: '100%',
    height: 1,
    background: '#666',
    margin: '0 0 30px',
  },
})

class AdminPublicationContainer extends React.Component {
  render() {
    const {props} = this
    const domainId = props.publication.getIn(['version', 'domainId'])
    const channelId = props.publication.get('channelId')

    return props.isLoadingVersions || !props.publication.size ?
      <Loading /> :
      <Main>
        <div className={c.header}>{domainId} / publications / {channelId}</div>
        <div className={c.container}>
          <div className={c.containerPadding} />
          <div>
            <AdminPublicationInfo {...props} />
          </div>
          <div className={c.containerPadding} />
        </div>
      </Main>
  }
}

AdminPublicationContainer.propTypes = {
  isLoadingVersions: PropTypes.bool.isRequired,
  publication: PropTypes.instanceOf(_.Map).isRequired,
  users: PropTypes.instanceOf(_.Map).isRequired,
}

const mapStateToProps = (state, {domainId, channelId}) => {
  return {
    // TODO: will get stuck on loading if there are no matching publications
    isLoadingVersions: !state.getIn(['versions', 'all'], _.List()).size,
    users: state.get('users', _.Map()),
    publication: state.getIn(['versions', 'all'], _.List())
      .flatMap((domains) => {
        return domains.get('versions').map((version) => {
          return version.merge({domainId: domains.get('id')})
        })
      })
      .filter((version) => {
        return version.get('domainId') === domainId
      })
      .flatMap((version) => {
        return (version.get('publications') || _.List())
          .map((publication) => {
            return publication.set('version', version)
          })
      })
      .find((publication) => {
        return publication.get('channelId') === channelId
      }) || _.Map(),
  }
}

export default connect(mapStateToProps, {})(AdminPublicationContainer)
