import '@babel/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import {AppContainer} from 'react-hot-loader'
import Root from './root'
import configureStore from './store/configure-store'
import rootSaga from './sagas'

const store = configureStore()
store.runSaga(rootSaga)

const rootEl = document.getElementById('app')

if (module.hot) {
  ReactDOM.render(
    <AppContainer>
      <Root store={store} />
    </AppContainer>,
    rootEl,
  )

  module.hot.accept('./root', () => {
    System.import('./root').then(({default: NextRoot}) => {
      ReactDOM.render(
        <AppContainer>
          <NextRoot store={store} />
        </AppContainer>,
        rootEl,
      )
    })
  })
} else {
  ReactDOM.render(<Root store={store} />, rootEl)
}
