import * as types from '../action/types'
import {take} from 'redux-saga/effects'

export default function* (history) {
  while (true) {
    // eslint-disable-line no-constant-condition
    const {payload: newRoute} = yield take(types.SET_ROUTE)

    yield history.push(newRoute)
  }
}
