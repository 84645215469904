import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    border: '1px solid #e3e9ea',
    borderRadius: '2px 2px 0 0',
    backgroundColor: '#f4fafb',
  },
  headerItem: {
    justifyContent: 'center',
    paddingTop: '1px',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: '700',
    color: '#777',
    flex: '0 1 100px',
  },
  headerItemSmall: {
    flex: '0 1 100px',
  },
  headerItemFlex: {
    flex: '1 1 100px',
  },
  headerItemTime: {
    flex: '0 1 120px',
  },
  headerItemPadding: {
    minWidth: '10px',
    flex: '0 1 30px',
  },
  headerItemDivider: {
    minWidth: '1px',
    width: '1px',
    height: '38px',
    backgroundColor: '#e3e9ea',
    borderRadius: '5px 0 0 5px',
  },
})
