import * as _ from 'immutable'
import config from 'config'
import * as types from '../action/types'
import rejectOnGraphqlErrors from '../utils/reject-on-graphql-errors'
// import setAuthenticationByRefresh from './set-authentication-by-refresh'
import setUser from '../action/creators/set-user'
import {call, fork, put, race, take} from 'redux-saga/effects'

const fetchAvatar = (user, accessToken) => {
  return fetch(user.get('avatarUrl'), {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  })
    .then((r) => r.blob())
    .then((avatar) => {
      return user.set('avatar', URL.createObjectURL(avatar))
    })
}

export const fetchUser = (accessToken) => {
  return fetch(`${config.GRAPHQL}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      query: `
          query userData {
            me {
              id
              avatarUrl
              email
              fullname
              domains{
                id
                versionId
              }
            }
          }
        `,
    }),
  })
    .then((r) => r.json())
    .then(rejectOnGraphqlErrors)
    .then((r) => _.fromJS(r).getIn(['data', 'me']))
    .then((user) => fetchAvatar(user, accessToken))
}

export const loadUser = function* (accessToken) {
  try {
    const user = yield call(fetchUser, accessToken)
    yield put(setUser(user))
  } catch (e) {
    console.error(e) // eslint-disable-line no-console
    console.error('[catch] set-user : Failed to load user') // eslint-disable-line no-console
    // TODO:
    //   * check expire before fetching, if expired fetch accesstoken first, if failing redirect to login
    //   * handle no-connection, by ??
    //   * handle accessToken (fetchUser + fetchAvatar) failure by fetching new and trying again
    //   * handle recuring accessToken by redirecting to login
    //   * handle rejectOnGraphqlErrors, by retrying after 1000ms dalay
  }
}

export default function* () {
  while (true) {
    // eslint-disable-line no-constant-condition
    const raceResult = yield race({
      tokens: take(types.SET_TOKENS),
      accessToken: take(types.SET_ACCESS_TOKEN),
    })
    const accessToken = raceResult.accessToken ? raceResult.accessToken.payload : raceResult.tokens.payload.access_token
    yield fork(loadUser, accessToken)
  }
}
