import config from 'config' // eslint-disable-line import/no-unresolved
import React from 'react'
// import PropTypes from 'prop-types'
import * as _ from 'immutable'
import {Menu, MenuList, MenuButton, MenuItem} from '@reach/menu-button'
import getClasses from '../../utils/getClasses'
import Loading from '../elements/loading'
import rejectOnGraphqlErrors from '../../utils/reject-on-graphql-errors'

const button = {
  marginRight: 20,
  width: '100px',
  height: '40px',
  padding: '0',
  borderRadius: '4px',
  fontSize: '13px',
  lineHeight: '40px',
  color: '#fff',
  fontWeight: '700',
  textTransform: 'uppercase',
  boxShadow: '0 1px 3px rgba(77,77,77,0.1)',
  cursor: 'pointer',
  ':hover': {
    opacity: '0.9',
    boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
  },
  ':focus': {
    opacity: '0.9',
    boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
  },
  ':active': {
    opacity: '0.9',
    boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
  },
}

const c = getClasses({
  publishContainer: {
    border: '1px solid #cfcfcf',
    background: '#F6F8FA',
    padding: '30px',
    minWidth: 422,
    minHeight: 187,
  },
  publishWarning: {
    display: 'block',
    // padding: '0 10px',
    maxWidth: 360,
  },
  publisOptionsContainer: {
    // display: 'flex',
    flexDirection: 'row',
    display: 'flex',
    padding: '30px 0 5px',
  },
  strong: {
    fontWeight: 700,
  },
  menu: {
    maxWidth: 200,
    display: 'block',
    position: 'absolute',
  },
  abortButton: {
    ...button,
    backgroundColor: '#ccc',
  },
  publishButton: {
    ...button,
    backgroundColor: '#12aaeb',
  },
  actionButton: {
    position: 'relative',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    letterSpacing: '.1rem',
    textAlign: 'center',

    width: '160px',
    height: '40px',
    padding: '0 30px 0 30px',
    border: '1px solid #bbb',
    // borderRadius: '4px',
    borderRadius: '2px',
    fontSize: '13px',
    lineHeight: '38px',
    // color: '#fff',
    color: '#555',
    fontWeight: '700',
    textTransform: 'uppercase',
    // backgroundColor: '#12aaeb',
    backgroundColor: '#fff',
    boxShadow: '0 1px 3px rgba(77,77,77,0.1)',
    cursor: 'pointer',
    ':hover': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
    ':focus': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
    ':active': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
  },
  list: {
    display: 'block',
    width: 200,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    border: 'solid 1px hsla(0, 0%, 0%, 0.25)',
    background: 'hsla(0, 100%, 100%, 0.99)',
    outline: 'none',
    padding: '1rem 0',
    fontSize: '85%',
  },
  item: {
    cursor: 'pointer',
    padding: '10px 20px',
    outline: 'none',
    // display: 'block',
    ':hover': {
      background: '#eaeaea',
      outline: 'none',
    },
    '&[data-selected]': {
      background: 'hsl(211, 81%, 36%)',
      color: 'white',
      outline: 'none',
    },
  },
})

class AdminVersionPublish extends React.Component {
  state = {}
  handleConfirm(confirm) {
    this.setState({confirm})
  }
  abort() {
    this.setState({confirm: undefined})
  }
  publish(channelId) {
    const {props: {version: {id}, accessToken}} = this
    this.setState({
      loading: true,
      confirm: undefined,
    })

    fetch(config.GRAPHQL, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        query: `mutation {
          publishDomainVersion(versionId: "${id}", channelId: "${channelId}") {
              id
              createdAt
              message
              gitCommitMessage
              gitCommitSha
              publications {
                channelId
                createdAt
                user {
                  id
                  fullname
                  avatarUrl
                  email
                }
              }
            }
          }`,
        variables: null,
      }),
    })
      .then((r) => r.json())
      .then(rejectOnGraphqlErrors)
      .then((r) => {
        this.props.updateVersion(_.fromJS(r).getIn(['data', 'publishDomainVersion']))
        this.setState({loading: false})
      })
      .catch((e) => {
        this.setState({loading: false})
        throw e
      })
  }
  render() {
    const {props: {channels, version}, state} = this
    const {domainId, id} = version
    const {confirm, loading} = state

    if (loading) {
      return (
        <div className={c.publishContainer} style={{position: 'relative'}}>
          <Loading isLoading />
        </div>
      )
    } else if (confirm) {
      return (
        <div className={c.publishContainer}>
          <div className={c.publishWarning}>
            Publishing version <strong>{id}</strong> will update the <strong>{confirm}</strong> channel on <strong>{domainId}</strong>.
          </div>
          <div className={c.publisOptionsContainer}>
            <button className={c.abortButton} onClick={() => this.abort()}>Abort</button>
            <button className={c.publishButton} onClick={() => this.publish(confirm)}>Publish</button>
          </div>
        </div>
      )
    } else {
      return (
        <Menu className={c.menu}>
          <MenuButton className={c.actionButton}>
            Publish To<span aria-hidden>▾</span>
          </MenuButton>
          <MenuList className={c.list}>
            {
              channels.map((channel) => {
                return (
                  <MenuItem
                    key={channel}
                    className={c.item}
                    onSelect={() => this.handleConfirm(channel)}
                  >{channel}</MenuItem>
                )
              })
            }
          </MenuList>
        </Menu>
      )
    }
  }
}

export default AdminVersionPublish
