import * as _ from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import setRoute from '../action/creators/set-route'
import Header from '../components/header'
import Menu from '../components/header-menu'
import MenuItem from '../components/header-menu-item'
import HeaderRight from '../components/header-right'
import {getLocationPath} from '../selectors'
import {
  emptyRoute,
  adminDevicesRoute,
  adminGraphiqlRoute,
  adminJobsRoute,
  adminRoute,
  adminRealtimeRoute,
  adminSensorEventsRoute,
  adminVersionsRoute,
  adminHealthStatusesRoute,
  adminUserAnalyticsRoute,
  // adminDomainConfigsRoute,
  adminResourceRoute,
  domainsRoute,
  adminUserListRoute,
} from '../routes'

const AdminHeader = ({
  activeDevices,
  activeDomains,
  activeImport,
  activeVersions,
  activeGraphiql,
  activeRealtime,
  activeSensorEvents,
  activeHealthStatuses,
  // activeAdminDomainConfigs,
  activeAdminResource,
  activeUserAnalytics,
  activeUserList,
  setRoute: route,
  user,
}) => {
  return (
    <Header subHeading="Admin">
      <Menu>
        <MenuItem href="/jobs" active={activeImport}>
          Jobs
        </MenuItem>
        <MenuItem href="/domains" active={activeDomains}>
          Domains
        </MenuItem>
        <MenuItem href="/versions" active={activeVersions}>
          Versions
        </MenuItem>

        <MenuItem href="/admin/devices" active={activeDevices}>
          Devices
        </MenuItem>
        <MenuItem href="/admin/graphiql" active={activeGraphiql}>
          GraphiQL
        </MenuItem>
        <MenuItem href="/admin/health-statuses" active={activeHealthStatuses}>
          Health Statuses
        </MenuItem>
        <MenuItem href="/admin/user-analytics" active={activeUserAnalytics}>
          User analytics
        </MenuItem>
        <MenuItem href="/admin/user-list" active={activeUserList}>
          User list
        </MenuItem>
      </Menu>
      <HeaderRight setRoute={route} user={user} />
    </Header>
  )
}

//  <MenuItem href="/admin/resources" active={activeAdminResource}>
//   Resources
//   </MenuItem>
// <MenuItem href="/admin/sensor-events/floor/365" active={activeSensorEvents}>
//   Sensor events
// </MenuItem>

AdminHeader.propTypes = {
  activeDevices: PropTypes.bool.isRequired,
  activeGraphiql: PropTypes.bool.isRequired,
  activeHealthStatuses: PropTypes.bool.isRequired,
  activeUserAnalytics: PropTypes.bool.isRequired,
  activeUserList: PropTypes.bool.isRequired,
  activeImport: PropTypes.bool.isRequired,
  activeRealtime: PropTypes.bool.isRequired,
  activeResource: PropTypes.bool.isRequired,
  activeSensorEvents: PropTypes.bool.isRequired,
  activeVersions: PropTypes.bool.isRequired,
  setRoute: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(_.Map).isRequired,
}

const mapStateToProps = (state) => {
  const path = getLocationPath(state) || ''

  return {
    activeDomains: Boolean(domainsRoute.match(path)),
    activeVersions: Boolean(adminVersionsRoute.match(path)),
    activeDevices: Boolean(adminDevicesRoute.match(path)),
    activeGraphiql: Boolean(adminGraphiqlRoute.match(path)),
    activeHealthStatuses: Boolean(adminHealthStatusesRoute.match(path)),
    activeUserAnalytics: Boolean(adminUserAnalyticsRoute.match(path)),
    activeUserList: Boolean(adminUserListRoute.match(path)),
    // activeAdminDomainConfigs: Boolean(adminDomainConfigsRoute.match(path)),
    activeAdminResource: Boolean(adminResourceRoute.match(path)),
    activeImport: Boolean(emptyRoute.match(path) || adminRoute.match(path) || adminJobsRoute.match(path)),
    activeRealtime: Boolean(adminRealtimeRoute.match(path)),
    activeSensorEvents: Boolean(adminSensorEventsRoute.match(path)),
    user: state.get('user', _.Map()),
  }
}

export default connect(mapStateToProps, {setRoute})(AdminHeader)
