import React from 'react'

const header = {
  padding: '82px 140px 0 140px',
  fontSize: '40px',
  lineHeight: '60px',
  fontWeight: '200',

  color: '#656559',
}

const p = {
  padding: '60px 140px',
  fontSize: '30px',
  lineHeight: '45px',
  fontWeight: '100',

  color: '#656559',
}

const RouteNotFound = (props) => {
  return (
    <main>
      <div style={header}>404</div>
      <div style={p}>Sorry about that :(</div>
    </main>
  )
}

export default RouteNotFound
