// import config from 'config'
import * as _ from 'immutable'
import addMilliseconds from 'date-fns/add_milliseconds'
import * as types from '../action/types'
import {keyIn} from '../utils/predicates'
import getNormalizedExpireIn from '../utils/time/getNormalizedExpireIn'

const reduce = (state = _.Map(), action) => {
  // eslint-disable-line complexity
  switch (action.type) {
    case types.SET_EXPIRE_IN: {
      const expireIn = getNormalizedExpireIn(action.payload)
      // add two minutes to allow token refetch in a background tab to set a
      // new cookie before the cookie expires
      const expired = addMilliseconds(Date.now(), expireIn + 1000 * 60 * 2).toUTCString()

      return state.set('expireIn', expireIn).set('expired', expired)
    }
    case types.SET_ACCESS_TOKEN:
      return state.set('access_token', action.payload)
    case types.SET_TOKENS:
      return state.merge(action.payload)
    case types.SET_NO_CONNECTION_REDIRECT:
      return state.set('redirect', action.payload)
    case types.SET_AUTHENTICATION_ERROR:
      return state.set('error', action.payload)
    case types.SET_AUTHORIZATION:
      return state.merge(action.payload)
    case types.SET_AUTHORIZED_ERROR:
      return state.filter(keyIn('redirect')).set('authorizedError', action.payload)
    case types.RENEW_ACCESS_TOKEN:
      return state.remove('access_token')
    case types.CLEAR_USER_DATA:
      return state
    default:
      return state
  }
}

export default reduce
