import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {css} from 'aphrodite/no-important'
import styles from './admin-container.styles'
import AdminHeader from './admin-header'

class AdminContainer extends React.Component {
  render() {
    return (
      <div className={css(styles.container)}>
        <AdminHeader />
        {this.props.children}
      </div>
    )
  }
}

AdminContainer.propTypes = {
  children: PropTypes.node,
}

const mapStateToProps = (state) => {
  return {
    // user: state.get('user'),
  }
}

export default connect(mapStateToProps)(AdminContainer)
