import React from 'react'
import {css} from 'aphrodite/no-important'
import styles from './header-menu-seperator.styles'

const HeaderMenuSeperator = () => {
  return (
    <div className={css(styles.seperator)}>
      <div className={css(styles.seperatorDot)} />
      <div className={css(styles.seperatorDot)} />
      <div className={css(styles.seperatorDot)} />
      <div className={css(styles.seperatorDot)} />
    </div>
  )
}

export default HeaderMenuSeperator
