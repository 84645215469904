import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  container: {
    width: '100%',
    padding: '80px 0 20px',
    flexDirection: 'row',
    display: 'flex',
  },
})
