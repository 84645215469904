import React from 'react'
import {StyleSheet, css} from 'aphrodite/no-important'
import ReactJson from 'react-json-view'
import getUUID from '../../utils/getUUID'

const styles = StyleSheet.create({
  container: {
    // margin: '120px 0 0',
    width: '100%',
    padding: '40px 0',
    background: '#fff',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.10)',
    flexDirection: 'row',
    borderRadius: '3px',
  },
  containerPadding: {
    display: 'block',
    minWidth: '20px',
    flex: '0 1 40px',
  },
  contentContainer: {
    flex: '1 1 100%',
    width: '100%',
    overflow: 'hidden',
  },
  formContainer: {
    width: '100%',
    flexDirection: 'row',
    paddingBottom: 20,
  },
  leftFormContainer: {
    // display: 'fke',
    width: '100%',
    maxWidth: '1000px',
    paddingLeft: 10,
    borderLeft: '1px solid #eee',
  },
  textAreaForm: {
    flex: '1 1 auto',
    display: 'block',
    width: '100%',
    padding: '20px 10px 0 0',
  },
  form: {
    flex: '1 1 auto',
    justifyContent: 'flex-end',
    padding: '20px 0 0 0',
  },
  statusForm: {
    justifyContent: 'flex-end',
    padding: '20px 0 0 0',
  },
  input: {
    flex: '1 1 auto',
    // width: '30px',
    width: '100%',
    height: '40px',
    margin: '0 20px 20px 0',
    padding: '0 10px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '30px',
    color: '#777',
    background: '#f5f5f5',
    boxShadow: '0 1px 3px rgba(77,77,77,0.3)',
    ':-ms-input-placeholder': {
      color: '#b6b6b6',
    },
    '::-moz-placeholder': {
      color: '#b6b6b6',
    },
    '::-webkit-input-placeholder': {
      color: '#b6b6b6',
    },
    ':hover': {
      opacity: '0.8',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
    ':focus': {
      opacity: '1',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
  },
  textarea: {
    flex: '1 1 auto',
    width: '100%',
    // height: '140px',
    margin: '0 20px 20px 0',
    padding: '0 10px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '30px',
    color: '#777',
    background: '#f5f5f5',
    boxShadow: '0 1px 3px rgba(77,77,77,0.3)',
    ':-ms-input-placeholder': {
      color: '#b6b6b6',
    },
    '::-moz-placeholder': {
      color: '#b6b6b6',
    },
    '::-webkit-input-placeholder': {
      color: '#b6b6b6',
    },
    ':hover': {
      opacity: '0.8',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
    ':focus': {
      opacity: '1',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
  },
  button: {
    width: '160px',
    height: '40px',
    padding: '0',
    borderRadius: '4px',
    fontSize: '13px',
    lineHeight: '40px',
    color: '#fff',
    fontWeight: '700',
    textTransform: 'uppercase',
    backgroundColor: '#12aaeb',
    boxShadow: '0 1px 3px rgba(77,77,77,0.1)',
    cursor: 'pointer',
    ':hover': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
    ':focus': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
    ':active': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
  },
  eventContainer: {
    display: 'flex',
    padding: 10,
    background: '#E3E6E9',
    flexDirection: 'row',
  },
  event: {
    marginLeft: 10,
    fontWeight: 700,
  },
  logContainer: {
    display: 'block',
    width: '100%',
    padding: 20,
    background: '#F6F8FA',
    overflow: 'hidden',
  },
})

const getParsedJSON = (message) => {
  try {
    if (typeof message === 'string') {
      return JSON.parse(message)
    } else if (typeof message === 'object' && message !== null) {
      return JSON.parse(JSON.stringify(message))
    } else {
      return {}
    }
  } catch (e) {
    if (typeof message === 'string') {
      return {response: message}
    } else {
      console.error('getParsedJSON', e)
      return {}
    }
  }
}

const getPaths = (path) => {
  return path.split(',').map((string) => string.trim()).filter(Boolean)
}

class AdminRealtimeComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {logs: []}
  }
  componentDidMount() {
    this.addSocketSubscribtion(this.props.accessToken)
  }
  componentDidUpdate(prevProps) {
    if (this.props.accessToken !== prevProps.accessToken) {
      this.addSocketSubscribtion(this.props.accessToken)
    }
  }
  addSocketSubscribtion(accessToken) {
    if (this.ws) {
      this.appendLog('new accessToken')
      this.ws.close()
    }
    this.ws = new WebSocket(`wss://token:${accessToken}@realtime-dev.nimway.com/realtime/connect`)
    this.ws.onmessage = (response) => {this.appendLog('websocket – onmessage', response.data)}
    this.ws.onclose = (message) => {
      this.appendLog('websocket – onclose', message)
      this.addSocketSubscribtion(this.props.accessToken)
    }
    this.ws.onerror = (message) => {this.appendLog('websocket – onerror', message)}
  }
  appendLog(event, message) {
    this.setState((state) => {
      state.logs.unshift({event, message, id: getUUID()})
      return state
    })
  }
  componentWillUnmount() {
    this.ws.onclose = () => {console.log('componentWillUnmount onclose')}
    this.ws.close()
    delete this.ws
  }
  sendPath(path) {
    const paths = getPaths(path)
    if (!paths.length) {return}

    const message = {paths}
    this.appendLog('send', message)
    this.ws.send(JSON.stringify(message))
  }
  sendStatusRequest() {
    this.appendLog('send', 'status')
    this.ws.send('status')
  }
  sendPost(p, json) {
    const path = p.trim()
    if (!path) {return}
    const body = JSON.stringify(JSON.parse(json))
    this.appendLog('post', {path, body})
    fetch(
      `https://realtime-dev.nimway.com/realtime/${path}`,
      {method: 'post', body},
    ).then((r) => {
      console.log('post response', r)
    })
  }
  render() {
    return (
      <div className={css(styles.container)}>
        <div className={css(styles.containerPadding)} />
        <div className={css(styles.contentContainer)}>
          <div className={css(styles.formContainer)}>
            <form
              className={css(styles.textAreaForm)}
              onSubmit={(e) => {
                e.preventDefault()
                const postpath = e.target.elements.postpath.value
                const json = e.target.elements.json.value
                this.sendPost(postpath, json)
              }}
            >
              <input className={css(styles.input)} name="postpath" placeholder="Post to path" />
              <textarea
                rows="10"
                cols="50"
                className={css(styles.textarea)}
                name="json"
                placeholder="JSON"
              />
              <button className={css(styles.button)}>Trigger Response</button>
            </form>
            <div className={css(styles.leftFormContainer)}>
              <form
                className={css(styles.statusForm)}
                onSubmit={(e) => {
                  e.preventDefault()
                  this.sendStatusRequest()
                }}
              >
                <button className={css(styles.button)}>Get status</button>
              </form>
              <form
                className={css(styles.form)}
                onSubmit={(e) => {
                  e.preventDefault()
                  const path = e.target.elements.path.value
                  this.sendPath(path)
                }}
              >
                <div>
                  <input
                    className={css(styles.input)}
                    style={{marginBottom: 25}}
                    name="path"
                    placeholder="Paths (comma seperated for multiple)"
                  />
                  <button className={css(styles.button)}>Add subscription</button>
                </div>
              </form>
            </div>
          </div>
          <div className={css(styles.logOutput)}>
            {this.state.logs.map(({event, message, id}) => {
              // console.log('what??', event, message)
              const parsedMessage = getParsedJSON(message)

              return (
                <div key={id}>
                  <div className={css(styles.eventContainer)}>
                    event: <span className={css(styles.event)}> {event}</span>
                  </div>
                  <div className={`${css(styles.logContainer)} noColumn`} >
                    {
                      message ?
                        Object.keys(parsedMessage).length > 1 ?
                          <ReactJson
                            src={parsedMessage}
                            name={false}
                            indentWidth={2}
                            collapsed={1}
                            enableClipboard={false}
                            displayDataTypes={false}
                            displayObjectSize={false}
                          /> :
                          <ReactJson
                            src={parsedMessage}
                            name={false}
                            indentWidth={2}
                            collapsed={2}
                            enableClipboard={false}
                            displayDataTypes={false}
                            displayObjectSize={false}
                          /> :
                        '–'
                    }
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={css(styles.containerPadding)} />
      </div>
    )
  }
}

export default AdminRealtimeComponent
