import React from 'react'
import {css} from 'aphrodite/no-important'
import styles from './header.styles'

const AdminDeviceInfo = () => {
  return (
    <div className={css(styles.header)}>
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItem, styles.headerItemFlex)}>ID</div>
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItemDivider)} />
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItem, styles.headerItemFlex)}>Name</div>
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItemDivider)} />
      <div className={css(styles.headerItemPadding)} />
      <div className={css(styles.headerItem)}>Type</div>
      <div className={css(styles.headerItemPadding)} />
    </div>
  )
}

export default AdminDeviceInfo
