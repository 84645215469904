import config from 'config' // eslint-disable-line import/no-unresolved
import React from 'react'
import PropTypes from 'prop-types'
import getClasses from '../utils/getClasses'

const envTag = {
  position: 'absolute',
  bottom: -4,
  padding: '0 3px',
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 700,
  color: '#fff',
}

const c = getClasses({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1,
    minWidth: '1024px',
    display: 'flex',
    flexDirection: 'row',
    height: '80px',
    width: '100%',
    background: '#fff',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.10)',
  },
  icon: {
    position: 'relative',
    background:
      'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+RmlsbCAxIENvcHkgMjwvdGl0bGU+PHBhdGggZD0iTTQ2Ljg3IDE3LjQ4OUM0My4zNzcgMTMuNjA5IDM2LjEyNiA2LjM2NSAzMi4zMjMgMi45NGMtNC40NDYtNC4wMDQtMTAuNjMxLTMuODM4LTE0Ljk2NyAwLTMuNzcgMy4zNC0xMS4wMzEgMTAuNjU0LTE0LjQ1IDE0LjQ1LTMuODQ1IDQuMjctMy45MDQgMTAuNjMgMCAxNC45NjguMjU4LjI4Ni44NDUuODcgMS4xMSAxLjExIDEuMTY4IDEuMDUgMi45MDEgMS4xMDcgNC4wMDggMCAxLjEwNy0xLjEwNy45ODQtMi43NzItLjA2OC0zLjk0LS4yNi0uMjktLjcyNy0uODI5LTEuMDQyLTEuMTc4LTEuODItMi4wMi0xLjc5OS00Ljk1NCAwLTYuOTUyIDMuNDE5LTMuNzk3IDEwLjY1My0xMS4wMzEgMTQuNDUtMTQuNDUgMi4xLTEuODkgNC45MDgtMS44NCA2Ljk1MSAwIDMuODAzIDMuNDI0IDExLjA1MyAxMC42NjkgMTQuNTQ3IDE0LjU0OC44NzguOTc1IDEuMzcxIDIuMTYyIDEuMzcxIDMuNDc1IDAgMS4zMTMtLjQ5MyAyLjUtMS4zNyAzLjQ3Ni0zLjQyIDMuNzk3LTEwLjY1NCAxMS4wMzEtMTQuNDUgMTQuNDUtMi4wMTQgMS44MTItNC45ODcgMS43MDgtNi45NTIgMC0uNzEtLjYxNy0yLjA0Mi0xLjk1My0yLjY2LTIuNjYtMS42ODgtMS45My0xLjYwOC0zLjQ5LjAwNC01LjIyNCAxLjYxMS0xLjczNCAyLjU4MS0yLjYxNCAyLjU4MS0yLjYxNCAzLjA2MiAxLjQzMyA2LjgxNy44OTUgOS4zNDYtMS42MzNBOC4yNjQgOC4yNjQgMCAxIDAgMTkuMDQ1IDE5LjA4Yy0yLjUxNyAyLjUxNi0zLjA2MiA2LjI0Ny0xLjY1NCA5LjMgMCAwLTIuNzkyIDIuNzQ4LTMuNDgzIDMuNTE1LTIuOTkyIDMuMzIyLTIuOTQyIDguMDggMCAxMS40NjYuODE2LjkzOCAyLjU4OCAyLjcxMiAzLjU0NSAzLjU0NEMxOS42NTYgNDguODIgMjIuMjI3IDUwIDI0LjkzNyA1MHM1LjI5Ni0xLjEyNSA3LjQ4My0zLjA5NWMzLjc5Ny0zLjQxOCAxMS4wMzEtMTAuNjUzIDE0LjQ1LTE0LjQ0OSAxLjg5MS0yLjEwMSAyLjk2NC00LjY1NyAyLjk2NC03LjQ4NCAwLTIuODI4LTEuMTA5LTUuNDIyLTIuOTY0LTcuNDgzIiBmaWxsPSIjNzc3IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=) no-repeat 50% 50%', // eslint-disable-line max-len
    backgroundSize: 'contain',
    display: 'block',
    width: 50,
    minWidth: 50,
    height: 50,
    margin: '16px 0 0 31px',

    ':hover': {
      opacity: 0.78,
    },
  },
  enviromentTag: {
    position: 'absolute',
    bottom: -4,
    right: -10,
    padding: '0 3px',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 700,
    color: '#fff',
    // background: '#FFA58F',
    background: '#FF908F',
  },
  envTagDev: {
    ...envTag,
    right: -10,
    background: '#FF908F',
  },
  envTagProd: {
    ...envTag,
    right: -14,
    // background: '#FFA58F',
    background: '#94CD9B',
  },
})

const isDev = () => {
  return config.AUTH_URL.indexOf('dev') > -1
}

const isProd = () => {
  return config.AUTH_URL.indexOf('//auth.nimway') > -1
}

const Header = ({children, subHeading}) => {
  return (
    <div className={c.header}>
      <a className={c.icon} href="/" >
        {isDev() && <div className={c.envTagDev}>Dev</div>}
        {isProd() && <div className={c.envTagProd}>Prod</div>}
      </a>
      {children}
    </div>
  )
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
  subHeading: PropTypes.string.isRequired,
}

export default Header
