import * as _ from 'immutable'
import {delay} from 'redux-saga'
import {call, cancel, fork, put, race, select, take} from 'redux-saga/effects' // eslint-disable-line import/order
import config from 'config' // eslint-disable-line import/no-unresolved
import setVersions from '../action/creators/set-versions'
import setChannels from '../action/creators/set-channels'
import setUsers from '../action/creators/set-users'
import renewAccessToken from '../action/creators/renew-access-token'
import * as types from '../action/types'
import {
  domainsRoute,
  domainVersionsRoute,
  adminVersionRoute,
  adminVersionsRoute,
  adminDomainPublicationRoute,
  adminHealthStatusesRoute,
  adminUserListRoute,
  adminDevicesRoute,
} from '../routes'
import {getAccessToken} from '../selectors'
import rejectOnGraphqlErrors from '../utils/reject-on-graphql-errors'

const fetchAdminData = (accessToken) => {
  return fetch(
    config.GRAPHQL,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        query: `
          query adminData {
            admin {
              channels {
                id
              },
              domains {
                id,
                versions {
                  id,
                  createdAt,
                  message,
                  gitCommitMessage,
                  gitCommitSha,
                  publications {
                    channelId,
                    createdAt
                    user {
                      id
                      fullname
                      avatarUrl
                      email
                    }
                  }
                }
              }
            }
          }
        `,
      }),
    },
  )
    .then((r) => r.json())
    .then(rejectOnGraphqlErrors)
    .then((r) => {
      return _.fromJS(r).getIn(['data', 'admin'])
    })
}

const navigateToVersions = (action) => {
  if (action.type !== types.SET_LOCATION) {return}

  const path = action.payload.getIn(['location', 'pathname'])
  return adminVersionsRoute.match(path) ||
    adminVersionRoute.match(path) ||
    adminDomainPublicationRoute.match(path) ||
    domainsRoute.match(path) ||
    domainVersionsRoute.match(path) ||
    adminUserListRoute.match(path) ||
    adminDevicesRoute.match(path) ||
    adminHealthStatusesRoute.match(path)
}

const navigateFromVersions = (action) => {
  if (action.type !== types.SET_LOCATION) {return}

  const path = action.payload.getIn(['location', 'pathname'])
  return !adminVersionsRoute.match(path) &&
    !adminVersionRoute.match(path) &&
    !adminDomainPublicationRoute.match(path) &&
    !domainsRoute.match(path) &&
    !domainVersionsRoute.match(path) &&
    !adminHealthStatusesRoute.match(path)
}

const getUser = (adminData) => {
  return adminData
    .get('domains', _.List())
    .flatMap(
      (domain) => domain
        .get('versions', _.List())
        .flatMap(
          (version) => version
            .get('publications', _.List())
            .map((publication) => publication.get('user'))))
    .filter(Boolean)
    .toSet()
}

const backgroundFetch = function* () {
  while (true) {
    // eslint-disable-line no-constant-condition
    const accessToken = yield select(getAccessToken)

    if (accessToken) {
      try {
        console.log('fetch versions, domains etc')
        const adminData = yield call(fetchAdminData, accessToken)

        yield put(setChannels(adminData.get('channels')))
        yield put(setUsers(getUser(adminData)))
        yield put(setVersions(adminData.get('domains')))
      } catch (e) {
        console.error('[catch] watch-admin-navigate-load-versions', e) // eslint-disable-line no-console
        const token = yield select(getAccessToken)

        if (token) {
          yield put(renewAccessToken())
        }
      }
    }

    yield race({
      navigateToVersions: take(navigateToVersions),
      updateOnNewAccessToken: take(types.SET_EXPIRE_IN),
      updateAfterPostJob: take(types.POST_JOB_DONE),
      continue: call(delay, 1000 * 10),
    })
  }
}

export default function* () {
  while (true) {
    // eslint-disable-line no-constant-condition
    yield take(navigateToVersions)
    const backgroundFetchTask = yield fork(backgroundFetch)
    yield take(navigateFromVersions)
    yield cancel(backgroundFetchTask)
  }
}
