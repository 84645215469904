import {delay} from 'redux-saga'
import addMilliseconds from 'date-fns/add_milliseconds'
import setLoginRedirect from '../action/creators/set-login-redirect'
import setRefreshToken from '../action/creators/set-refresh-token'
import * as types from '../action/types'
import {getRefreshToken} from '../services/auth'
import {getLocation} from '../selectors/index'
import getNormalizedExpireIn from '../utils/time/getNormalizedExpireIn'
import {call, put, race, select, take} from 'redux-saga/effects'

export default function* (history) {
  while (true) {
    // eslint-disable-line no-constant-condition
    const {payload} = yield take(types.SET_EXPIRE_IN)
    const expireIn = getNormalizedExpireIn(payload)
    console.log('new expireIn set – will renew accessToken at:', addMilliseconds(Date.now(), expireIn))

    const raceResult = yield race({
      renew: take(types.RENEW_ACCESS_TOKEN), //TODO: sigout or clear??
      // signout: take(types.SET_REFRESH_TOKEN), //TODO: sigout or clear??
      expired: call(delay, expireIn),
    })
    console.log('expireIn renew – will fetch new token NOW')

    if (Reflect.has(raceResult, 'expired') || Reflect.has(raceResult, 'renew')) {
      // TODO: remove after writing tests
      console.log('refresh expired token') // eslint-disable-line no-console
      const refreshToken = yield call(getRefreshToken)

      if (refreshToken) {
        yield put(setRefreshToken(refreshToken))
      } else {
        const location = yield select(getLocation)
        yield put(setLoginRedirect(location))
        history.replace('/login')
      }
    }
  }
}
