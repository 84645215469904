import {takeEvery} from 'redux-saga'
import createHistory from 'history/createBrowserHistory'
import * as types from '../action/types'
import onLoadAuthentication from './on-load-authentication'
import onLoadSignout from './on-load-signout'
import onLoadLocation from './on-load-location'
import watchAuthenticateByCredentials from './watch-authenticate-by-credentials'
import watchAuthenticateByRefresh from './watch-authenticate-by-refresh'
import getEmailCode from './get-email-code'
import watchAuthenticate from './watch-authenticate'
import watchAuthenticateExpireIn from './watch-authenticate-expire-in'
import watchAuthorize from './watch-authorize'
import watchHistory from './watch-history'
import watchLoadUser from './watch-load-user'
import watchAdmin from './watch-admin'
import watchToggleJobErrorInfo from './watch-toggle-job-error-info'
import watchRoute from './watch-route'
import postJob from './post-job'
import {call, fork} from 'redux-saga/effects'

export default function* root() {
  const history = createHistory()
  yield call(onLoadSignout, history)
  yield call(onLoadLocation, history)
  yield fork(watchHistory, history)
  yield fork(watchAuthenticateExpireIn, history)

  yield [
    fork(watchAuthenticateByRefresh, history),

    fork(takeEvery, types.SET_EMAIL, getEmailCode),
    fork(watchAuthenticateByCredentials),

    fork(watchAuthenticate),
    fork(watchAuthorize, history),

    fork(watchRoute, history),

    fork(watchLoadUser),
    fork(watchAdmin),

    fork(watchToggleJobErrorInfo),
    fork(takeEvery, types.POST_JOB, postJob),
  ]

  yield call(onLoadAuthentication, history)
}
