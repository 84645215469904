import React from 'react'
import PropTypes from 'prop-types'
import ProfileAdminItemLink from './profile-admin-item-link'

const container = {
  marginTop: '31px',
}

const headingStyle = {
  display: 'block',
  textTransform: 'uppercase',
  color: '#879092',
  fontSize: '12px',
  lineHeight: '12px',
  fontWeight: '200',
}

const infoStyle = {
  minHeight: '50px',
  width: '100%',
  marginTop: '10px',
  whiteSpace: 'nowrap',
  padding: '0 25px',
  background: '#eaeaea',
  fontSize: '20px',
  lineHeight: '49px',
  fontWeight: '300',
  boxShadow: '0 3px 8px rgba(0, 0, 0, 0.2)',
  overflow: 'auto',
}

const ProfileAdminItem = ({heading, text, link, linkEvent}) => {
  return (
    <div style={container}>
      <div style={headingStyle}>
        {heading}
        {link && linkEvent && <ProfileAdminItemLink link={link} linkEvent={linkEvent} />}
      </div>
      <pre style={infoStyle}>{text}</pre>
    </div>
  )
}

ProfileAdminItem.propTypes = {
  heading: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkEvent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  text: PropTypes.string.isRequired,
}

export default ProfileAdminItem
