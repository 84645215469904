import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 content',
    width: '84px',
    paddingTop: '23px',
    paddingLeft: '19px',
  },
  avatar: {
    width: '35px',
    height: '35px',
    borderRadius: '35px',
    overflow: 'hidden',
    cursor: 'pointer',
    ':hover': {
      opacity: '0.8',
    },
    ':focus': {
      opacity: '0.6',
    },
    ':active': {
      opacity: '0.6',
    },
  },
})
