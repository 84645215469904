import React from 'react'
import PropTypes from 'prop-types'
import * as _ from 'immutable'
import {css} from 'aphrodite/no-important'
import HeaderMenuSeperator from './header-menu-seperator'
import styles from './header-menu.styles'

const getSeperators = ({length}) => {
  return _.Range(length).map((id) => <HeaderMenuSeperator key={`HeaderMenuSeperator-${id}`} />)
}

const HeaderMenu = ({children}) => {
  const menuItems = _.List(children)
    .interleave(getSeperators(children))
    .skipLast(1)

  return <div className={css(styles.container)}>{menuItems}</div>
}

HeaderMenu.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
}

export default HeaderMenu
