// @flow weak
import * as React from 'react'
import MDSpinner from 'react-md-spinner'
import getClasses from '../../utils/getClasses'

const opacityTransitionVisible = {
  opacity: 1,
  transition: 'opacity 200ms ease-in 0ms',
  transitionDelay: '0.26s',
}

const opacityTransitionHidden = {
  opacity: 0,
  transition: 'opacity 250ms ease-out 0ms',
}

const loadingElement = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 1,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  willChange: 'opacity',
  zIndex: 800,
  pointerEvents: 'none',
}

const c = getClasses({
  loadingElementVisible: {
    ...loadingElement,
    ...opacityTransitionVisible,
  },
  loadingElementHidden: {
    ...loadingElement,
    ...opacityTransitionHidden,
  },
})

const LoadingElement = ({isLoading}) => {
  return (
    <div className={isLoading ? c.loadingElementVisible : c.loadingElementHidden}>
      <MDSpinner size={60} singleColor="#4B6E99" />
    </div>
  )
}

export default LoadingElement
