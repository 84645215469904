import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  userContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: '0 0 content',
  },
  link: {
    fontSize: '16px',
    fontWeight: '600',
    color: '#b5bcc1',
    textTransform: 'uppercase',
  },
  settings: {
    background:
      'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNzkyIDE3OTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI0QzRDNEMyIgZD0iTTExNTIgODk2cTAtMTA2LTc1LTE4MXQtMTgxLTc1LTE4MSA3NS03NSAxODEgNzUgMTgxIDE4MSA3NSAxODEtNzUgNzUtMTgxem01MTItMTA5djIyMnEwIDEyLTggMjN0LTIwIDEzbC0xODUgMjhxLTE5IDU0LTM5IDkxIDM1IDUwIDEwNyAxMzggMTAgMTIgMTAgMjV0LTkgMjNxLTI3IDM3LTk5IDEwOHQtOTQgNzFxLTEyIDAtMjYtOWwtMTM4LTEwOHEtNDQgMjMtOTEgMzgtMTYgMTM2LTI5IDE4Ni03IDI4LTM2IDI4SDc4NXEtMTQgMC0yNC41LTguNVQ3NDkgMTYzNGwtMjgtMTg0cS00OS0xNi05MC0zN2wtMTQxIDEwN3EtMTAgOS0yNSA5LTE0IDAtMjUtMTEtMTI2LTExNC0xNjUtMTY4LTctMTAtNy0yMyAwLTEyIDgtMjMgMTUtMjEgNTEtNjYuNXQ1NC03MC41cS0yNy01MC00MS05OWwtMTgzLTI3cS0xMy0yLTIxLTEyLjV0LTgtMjMuNVY3ODNxMC0xMiA4LTIzdDE5LTEzbDE4Ni0yOHExNC00NiAzOS05Mi00MC01Ny0xMDctMTM4LTEwLTEyLTEwLTI0IDAtMTAgOS0yMyAyNi0zNiA5OC41LTEwNy41VDQ2NSAyNjNxMTMgMCAyNiAxMGwxMzggMTA3cTQ0LTIzIDkxLTM4IDE2LTEzNiAyOS0xODYgNy0yOCAzNi0yOGgyMjJxMTQgMCAyNC41IDguNVQxMDQzIDE1OGwyOCAxODRxNDkgMTYgOTAgMzdsMTQyLTEwN3E5LTkgMjQtOSAxMyAwIDI1IDEwIDEyOSAxMTkgMTY1IDE3MCA3IDggNyAyMiAwIDEyLTggMjMtMTUgMjEtNTEgNjYuNXQtNTQgNzAuNXEyNiA1MCA0MSA5OGwxODMgMjhxMTMgMiAyMSAxMi41dDggMjMuNXoiLz48L3N2Zz4=) no-repeat 50% 50%', // eslint-disable-line max-len
    backgroundSize: 'contain',
    width: '16px',
    height: '16px',
    marginTop: '31px',
    cursor: 'pointer',
    ':hover': {
      opacity: '0.8',
    },
    ':focus': {
      opacity: '0.6',
    },
    ':active': {
      opacity: '0.6',
    },
  },
})
