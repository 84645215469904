import * as _ from 'immutable'
import jwtDecode from 'jwt-decode'
import setAuthorization from '../action/creators/set-authorization'
import * as types from '../action/types'
import {put, race, take} from 'redux-saga/effects'

// TODO: derived state, use selector
const isAdmin = (scopes) => {
  return scopes.indexOf('domains:write') > -1
}

export default function* () {
  while (true) {
    // eslint-disable-line no-constant-condition
    const raceResult = yield race({
      tokens: take(types.SET_TOKENS), // from login
      accessToken: take(types.SET_ACCESS_TOKEN), // from refresh
    })

    const accessToken = raceResult.accessToken ? raceResult.accessToken.payload : raceResult.tokens.payload.access_token
    const {domainIds, scopes} = jwtDecode(accessToken)

    yield put(
      setAuthorization(
        _.fromJS({
          domains: domainIds,
          admin: isAdmin(scopes),
        }),
      ),
    )
  }
}
