import React from 'react'
import PropTypes from 'prop-types'
import getClasses from '../../utils/getClasses'

const pulse = {
  '0%': {
    opacity: '0.2',
  },
  '50%': {
    opacity: '1',
  },
  '100%': {
    opacity: '0.2',
  },
}

const c = getClasses({
  stopped: {
    display: 'none',
  },
  running: {
    display: 'block',
    width: 9,
    // height: 9,
    flex: '0 0 9px',
    marginRight: 4,
    paddingTop: 1,
    // background: 'red',
    animationName: pulse,
    animationDuration: '2.8s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
})


const isRunning = (status) => {
  // return true
  return ['completed', 'failed'].indexOf(status.toLowerCase()) === -1
}

const getIndicatorContainer = (status) => {
  return isRunning(status) ? c.running : c.stopped
}

const getStatusIndicatorColor = (status) => {
  switch (status) {
    case 'completed':
      return 'transparent'
    case 'failed':
      return 'transparent'
    default:
      // started, queued
      return '#e27819'
  }
}

const ImportJobsRowStatusIndicator = ({status}) => {
  return (
    <div className={getIndicatorContainer(status)}>
      <svg width="9" height="9" viewBox="0 0 100 100">
        <circle cx={50} cy={50} r={25} fill={getStatusIndicatorColor(status)} />
      </svg>
    </div>
  )
}

ImportJobsRowStatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
}

export default ImportJobsRowStatusIndicator
