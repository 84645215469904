// import {browserHistory} from 'react-router'
import {buffers} from 'redux-saga'
import config from 'config'
import setTokens from '../action/creators/set-tokens'
import setExpireIn from '../action/creators/set-expire-in'
import setAuthenticateError from '../action/creators/set-authenticate-error'
import removeAuthenticateError from '../action/creators/remove-authenticate-error'
import setEmailCode from '../action/creators/set-email-code'
import removePin from '../action/creators/remove-pin'
import * as types from '../action/types'
import {setRefreshToken} from '../services/auth'
import setCookie from '../utils/setCookie'
import {actionChannel, call, fork, join, put, race, take} from 'redux-saga/effects'

const fetchByCredentials = (emailCode, pin) => {
  return fetch(`${config.AUTH_URL}/token`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      grant_type: 'email_pin_code',
      email_code: emailCode,
      pin_code: pin,
    }),
  })
    .then((r) => {
      return r.status !== 200 ? Promise.reject(`${r.status} - ${r.statusText}`) : Promise.resolve(r)
    })
    .then((r) => r.json())
}

const authenticate = function* (emailCode, pin) {
  try {
    const {expires_in, access_token, refresh_token} = yield call(fetchByCredentials, emailCode, pin)
    yield call(setRefreshToken, refresh_token)
    yield put(setExpireIn(expires_in))
    yield put(setTokens({access_token, refresh_token}))
    yield put(removeAuthenticateError())
    setCookie(access_token, {expires_in})
  } catch (e) {
    // TODO: seperate handling of different errors???
    console.error(e) // eslint-disable-line no-console
    console.error(`[catch] fetch-email-code: authenticate ${emailCode}`) // eslint-disable-line no-console
    yield put(setAuthenticateError('Login Failed. Please make sure your e-mail and pin is correct'))
    yield put(removePin())
    yield put(setEmailCode(emailCode))
  }
}

const takePin = function* () {
  const {payload: pin} = yield take(types.SET_PIN)
  return pin
}

export default function* () {
  const emailCodeChannel = yield actionChannel(types.SET_EMAIL_CODE, buffers.sliding(1))

  while (true) {
    // eslint-disable-line no-constant-condition
    // TODO: tests!
    const task = yield fork(takePin)
    const raceResult = yield race({
      pin: join(task),
      cancel: take(types.REMOVE_EMAIL),
    })

    if (raceResult.pin) {
      const {payload: emailCode} = yield take(emailCodeChannel)
      yield fork(authenticate, emailCode, raceResult.pin)
    }
  }
}
