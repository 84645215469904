import * as _ from 'immutable'
import * as types from '../action/types'

const reduce = (state = _.Map(), action) => {
  // eslint-disable-line complexity
  switch (action.type) {
    case types.SET_USERS:
      // debugger
      return action.payload.reduce((acc, user) => {
        return acc.update(user.get('id'), (storedUser = _.Map()) => {
          return storedUser.merge(user)
        })
      }, state)
    case types.CLEAR_USER_DATA:
      return state
    default:
      return state
  }
}

export default reduce
