import React from 'react'
import PropTypes from 'prop-types'
import * as _ from 'immutable'
import {Menu, MenuList, MenuButton, MenuItem} from '@reach/menu-button'
import getClasses from '../../utils/getClasses'

const actionButton = {
  position: 'relative',
  whiteSpace: 'nowrap',
  width: 150,
  height: 40,
  padding: '0 10px 0 14px',
  borderRadius: 4,
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '40px',
  textAlign: 'left',
  color: 'rgb(119, 119, 119)',
  background: '#fff',
  border: '1px solid rgba(0,0,0,.24)',
  boxShadow: '0 1px 3px rgba(77,77,77,0.1)',
  cursor: 'pointer',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ':hover': {
    opacity: '0.9',
    boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
  },
  ':focus': {
    opacity: '0.9',
    boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
  },
  ':active': {
    opacity: '0.9',
    boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
  },
  // '::after': {
  //   content: '"▾"',
  //   position: 'absolute',
  //   top: 0,
  //   right: 15,
  // },
}

const c = getClasses({
  menu: {
    maxWidth: 200,
    display: 'block',
    position: 'absolute',
  },
  actionButton,
  actionButtonUnselected: {
    ...actionButton,
    color: '#d6d6d6',
    border: '1px solid rgba(0,0,0,.14)',
  },
  list: {
    display: 'block',
    width: 150,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    borderRadius: 4,
    boxShadow: '0 3px 8px rgba(0, 0, 0, 0.2)',
    background: 'hsla(0, 100%, 100%, 0.99)',
    outline: 'none',
    padding: '10px 0',
  },
  item: {
    display: 'block',
    cursor: 'pointer',
    padding: '10px 12px',
    fontSize: 14,
    outline: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#000',
    ':hover': {
      background: '#eaeaea',
      outline: 'none',
    },
    '&[data-selected]': {
      background: 'hsl(211, 81%, 36%)',
      color: 'white',
      outline: 'none',
    },
  },
})

const Select = ({dropDown, title, options, onChange}) => {
  return (
    <Menu className={`${c.menu}`} >
      <MenuButton className={`${dropDown ? c.actionButtonUnselected : c.actionButton} selectButton`}>
        {title}{dropDown && <span aria-hidden> ▾</span>}
      </MenuButton>
      <MenuList className={c.list}>
        {
          options.map((option) => {
            return (
              <MenuItem
                key={encodeURIComponent(option)}
                className={c.item}
                onSelect={() => onChange(option)}
              >{option}</MenuItem>
            )
          })
        }
      </MenuList>
    </Menu>
  )
}

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.instanceOf(_.List).isRequired,
  title: PropTypes.string.isRequired,
}

export default Select
