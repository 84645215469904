import React from 'react'
import {connect} from 'react-redux'
import setEmail from '../action/creators/set-email'
import setPin from '../action/creators/set-pin'
import removeEmail from '../action/creators/remove-email'
import Login from '../components/login'
import {getLoginEmailCode, getLoginPin} from '../selectors'

const LoginContainer = (props) => {
  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        width: '580px',
        height: '540px',
        margin: '100px auto',
        border: '1px solid #e2e2e2',
      }}
    >
      <Login {...props} />
    </main>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticateError: state.getIn(['login', 'authenticateError']),
    email: state.getIn(['login', 'email']),
    emailCode: getLoginEmailCode(state),
    error: state.getIn(['session', 'error']),
    pin: getLoginPin(state),
  }
}

export default connect(mapStateToProps, {
  setEmail,
  setPin,
  removeEmail,
})(LoginContainer)
