// import _ from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
// import {css} from 'aphrodite/no-important'
// import styles from './loading.styles'
import getParameters from '../components/admin-graphiql/get-parameters'
import setCookie from '../utils/setCookie'
import {getAccessToken, getExpired} from '../selectors'
import Loading from './loading'

const SingleSignOn = ({accessToken, expired}) => {
  if (accessToken && expired) {
    const {redirect_url} = getParameters()
    const regex = /^https?:\/\/(?:\w*\.)?nimway\.com(?:\/.*)?$/

    if (redirect_url.match(regex)) {
      setCookie(accessToken, {expired})
      window.location.replace(redirect_url)
    }
  }

  return (
    <Loading />
  )
}

SingleSignOn.propTypes = {
  accessToken: PropTypes.string.isRequired,
  expired: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    // intentional use of string to use as bool:false
    accessToken: getAccessToken(state) || '',
    expired: getExpired(state) || '',
  }
}

export default connect(mapStateToProps)(SingleSignOn)
