import * as _ from 'immutable'
import * as types from '../action/types'

export default (state = _.List(), action) => {
  // eslint-disable-line complexity
  switch (action.type) {
    case types.SET_FLOORPLANS:
      return action.payload
    default:
      return state
  }
}
