import * as _ from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'aphrodite/no-important'
import AdminSensorEventsHeader from './admin-sensor-events-header'
import AdminSensorEventsRow from './admin-sensor-events-row'
import styles from './admin-sensor-events.styles'

const AdminHealthStatuses = ({healthStatuses}) => {
  console.log(healthStatuses)
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.containerPadding)} />
      <div className={css(styles.contentContainer)}>
        <AdminSensorEventsHeader />
        {healthStatuses.map((j, i) => {
          const healthStatus = j.toJS()

          return (
            <AdminSensorEventsRow
              {...healthStatus}
              rowIndex={i}
              key={`AdminSensorEventsRow-${healthStatus.sensorId}`}
            />
          )
        })}
      </div>
      <div className={css(styles.containerPadding)} />
    </div>
  )
}

AdminHealthStatuses.propTypes = {
  healthStatuses: PropTypes.instanceOf(_.List).isRequired,
}

export default AdminHealthStatuses
