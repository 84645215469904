import * as _ from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now'
import MainHeader from '../elements/main-header'
import Table from '../elements/table'
import Link from '../../containers/link'
import getClasses from '../../utils/getClasses'

const c = getClasses({
  linkContainer: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tag: {
    display: 'inline-block',
    margin: '0 5px 0 0',
    padding: '1px 3px',
    lineHeight: '20px',
    border: '1px solid #E1E1E8',
    borderRadius: '2px',
    background: '#F7F7F9',

    ':hover': {
      opacity: 0.8,
    },
    ':focus': {
      opacity: 0.6,
    },
    ':active': {
      opacity: 0.6,
    },
  },
})

const getTimeText = (versionsData) => {
  const createdAt = versionsData.get('createdAt')

  return createdAt ?
    distanceInWordsToNow(createdAt, {addSuffix: true}) :
    ''
}

const getVersionLink = (versionsData, domainId) => {
  const versionId = versionsData.get('id')

  return versionId ?
    <Link href={`/domains/${domainId}/versions/${versionId}`} title={versionId}>{versionId}</Link> :
    ''
}

const getGitLink = (versionsData) => {
  const gitCommitSha = versionsData.get('gitCommitSha') || ''

  return gitCommitSha ? (
    <a
      title={gitCommitSha}
      href={`https://github.com/Nimway/so-domain-data/commit/${gitCommitSha}`}
    >{gitCommitSha.slice(0, 7)}</a>
  ) : ''
}

const getPublications = (versionsData, groupName) => {
  return versionsData
    .get('publications', _.List())
    .map((channel) => {
      const channelId = channel.get('channelId', '')

      return (
        <div className={c.linkContainer}>
          <Link
            className={c.tag}
            key={encodeURIComponent}
            href={`/domains/${groupName}/publications/${channelId}`}
            title={channelId}
          >
            {channelId}
          </Link>
          {getTimeText(channel)}
        </div>
      )
    })
}


const AdminVersions = ({versions, domainId, groupName}) => {
  const versionIdStyling = {flex: '0 1 70px'}
  const createdStyling = {flex: '0 1 130px'}
  const commitStyling = {flex: '0 1 70px'}
  const gitCommitMessageStyling = {
    flex: '1 1 120px',
    minWidth: 90,
  }
  const publicationStyling = {
    flex: '1 1 200px',
    minWidth: 120,
    maxWidth: 200,
  }
  const headerData = [
    {columnName: 'ID', classStyling: versionIdStyling},
    {columnName: 'Created', classStyling: createdStyling},
    {columnName: 'Commit', classStyling: commitStyling},
    {
      columnName: 'Commit Message',
      classStyling: gitCommitMessageStyling,
    },
    {columnName: 'Publications', classStyling: publicationStyling},
  ]

  const rowData = versions
    .map((versionsData) => {
      return _.Map({
        key: versionsData.get('id'),
        data: [
          {content: getVersionLink(versionsData, domainId), classStyling: versionIdStyling},
          {content: getTimeText(versionsData), classStyling: createdStyling},
          {content: getGitLink(versionsData), classStyling: commitStyling},
          {
            content: versionsData.get('gitCommitMessage', ''),
            classStyling: gitCommitMessageStyling,
          },
          {
            content: getPublications(versionsData, domainId),
            classStyling: publicationStyling,
          },
        ],
      })
    })

  return (
    <React.Fragment>
      <MainHeader>{groupName}</MainHeader>
      <Table headerData={headerData} rowsData={rowData} />
    </React.Fragment>
  )
}

AdminVersions.propTypes = {
  domainId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  versions: PropTypes.instanceOf(_.List).isRequired,
}

export default AdminVersions
