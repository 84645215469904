// TODO: refactor admin-devices to admin-devices
import * as _ from 'immutable'
import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'aphrodite/no-important'
import config from 'config'
import {getAccessToken} from '../../selectors'
import MainHeader from '../elements/main-header'
import rejectOnGraphqlErrors from '../../utils/reject-on-graphql-errors'
import Loading from '../../containers/loading'
import getClasses from '../../utils/getClasses'
import Device from './device'
import styles from './index.styles'
import call from 'redux-saga/effects'

const c = getClasses({
  container: {
    width: '100%',
    // minHeight: 970,
    padding: '40px 40px 0 0',
    background: '#fff',
    flexDirection: 'row',
    display: 'flex',
    borderRadius: '3px',
  },
  horizontalContainer: {
    WebkitBoxDirection: 'normal',
    WebkitBoxOrient: 'horizontal',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    background: 'rgb(255, 255, 255)',
    borderRadius: '3px'
  },
  contentContainer: {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
  },
  fullInnerContainer: {
    width: '100%',
    paddingBottom: 5,
  },
  innerContainer: {
    width: '50%',
    paddingBottom: 5,
  },
  contentInnerContainer: {
    paddingBottom: 10,
  },
  contentHiddenInnerContainer: {
    display: 'none',
    paddingBottom: 10,
  },
  contentHeader: {
    fontSize: '14px',
    lineHeight: '30px',
    fontWeight: '700',
    color: '#777',
  },
  deviceHeader: {
    fontSize: '22px',
    lineHeight: '30px',
    fontWeight: '700',
    display: 'block',
  },
  contentInset: {
    display: 'inline-block',
    margin: '5px 10px 10px 0',
    padding: 20,
    background: '#F6F8FA',
  },
  contentP: {
    display: 'block',
    fontSize: '18px',
    width: 400
  },
  edtitableContentP: {
    display: 'block',
    fontSize: '18px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 400
  },
  configContainer: {
    paddingLeft: 20
  },
  divider: {
    display: 'block',
    width: '100%',
    height: 1,
    background: '#666',
    margin: '0 0 30px',
  },
  actionButton: {
    position: 'relative',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    letterSpacing: '.1rem',
    textAlign: 'center',

    width: '160px',
    height: '40px',
    padding: '0 30px 0 30px',
    border: '1px solid #bbb',
    // borderRadius: '4px',
    borderRadius: '2px',
    fontSize: '13px',
    lineHeight: '38px',
    // color: '#fff',
    color: '#555',
    fontWeight: '700',
    textTransform: 'uppercase',
    // backgroundColor: '#12aaeb',
    backgroundColor: '#fff',
    boxShadow: '0 1px 3px rgba(77,77,77,0.1)',
    cursor: 'pointer',
    ':hover': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
    ':focus': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
    ':active': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    }
  }
})


class AdminDevice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  fetchDevice(accessToken, deviceId) {
    fetch(
      `${config.GRAPHQL}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          query: `
            query devicesData {
              device(id:"${deviceId}"){
                id
                name
                description
                deviceType
                hardwareId
                domain {
                  id
                }
                deviceConfig{
                  id
                  domain {
                    id
                  }
                  building {
                    name
                    id
                  }
                  floor {
                    name
                    id
                  }
                  timezone
                  screenRotation
                  screenResolution
                  screenOnTime
                  screenOffTime
                  configPollInterval
                  rebootTime
                  dnsNameservers
                  proxyAddress
                  environment
                  floorplanUrl
                  gatewayConfig{
                    gatewayId
                    band
                    frequency
                    x
                    y
                  }
                  tabletConfig{
                    roomId
                    room{
                      name
                    }
                  }
                  sensorConfig{
                    type
                    roomId
                    room{
                      name
                    }
                    seatId
                    seat{
                      internalId
                    }
                    edgeId
                    address
                    x
                    y
                  }
                }
              }
            }
          `,
        }),
      },
    )
      .then((r) => r.json())
      //.then(rejectOnGraphqlErrors)
      .then((r) => {
        console.log(r)
        r = r.data.device
        this.setState({device: {
          deviceId: r.id,
          name: r.name,
          description: r.description,
          deviceType: r.deviceType,
          hardwareId: r.hardwareId,
          domainId: r.domain.id,
          deviceConfig: {
            configId: r.deviceConfig.id,
            domainId: r.deviceConfig.domain.id,
            building: r.deviceConfig.building,
            buildingId: r.deviceConfig.building.id,
            floor: r.deviceConfig.floor,
            floorId: r.deviceConfig.floor.id,
            timezone: r.deviceConfig.timezone,
            screenRotation: r.deviceConfig.screenRotation,
            screenResolution: r.deviceConfig.screenResolution,
            screenOnTime: r.deviceConfig.screenOnTime,
            screenOffTime: r.deviceConfig.screenOffTime,
            configPollInterval: r.deviceConfig.configPollInterval,
            rebootTime: r.deviceConfig.rebootTime,
            dnsNameservers: r.deviceConfig.dnsNameservers,
            proxyAddress: r.deviceConfig.proxyAddress,
            environment: r.deviceConfig.environment,
            floorplanUrl: r.deviceConfig.floorplanUrl,
            gatewayConfig: r.deviceConfig.gatewayConfig,
            tabletConfig: r.deviceConfig.tabletConfig,
            sensorConfig: r.deviceConfig.sensorConfig
          }
        }})
      })
  }
  componentWillMount() {
  }
  componentDidMount() {
    this.fetchDevice(this.props.accessToken, this.props.deviceId)
  }
  getDeviceLayout(device) {
    return (<div className={c.contentContainer}>
      <div className={c.innerContainer} style={{maxWidth: 700}}>
        <div className={c.contentInnerContainer}>
          <div className={c.deviceHeader}> Device
          </div>
        </div>
        <div className={this.getClassBasedOnContent(device.domainId)}>
          <div className={c.contentHeader}>Domain ID</div>
          <div className={c.contentP}>{device.domainId}</div>
        </div>
        <div className={this.getClassBasedOnContent(device.deviceId)}>
          <div className={c.contentHeader}>Device ID</div>
          <div className={c.contentP}>{device.deviceId}</div>
        </div>
        <div className={this.getClassBasedOnContent(device.name)}>
          <div className={c.contentHeader}>Name</div>
          <div className={c.contentP}>{device.name}</div>
        </div>
        <div className={this.getClassBasedOnContent(device.description)}>
          <div className={c.contentHeader}>Descriptiion</div>
          <div className={c.contentP}>{device.description}</div>
        </div>
        <div className={this.getClassBasedOnContent(device.deviceType)}>
          <div className={c.contentHeader}>Device Type</div>
          <div className={c.contentP}>{device.deviceType}</div>
        </div>
        <div className={this.getClassBasedOnContent(device.hardwareId)}>
          <div className={c.contentHeader}>Hardware ID</div>
          <div className={c.contentP}>{device.hardwareId}</div>
        </div>
      </div>
    </div>)
  }

  getClassBasedOnContent(content) {
    if (content && content != '') {
      return c.contentInnerContainer;
    } else {
      return c.contentHiddenInnerContainer;
    }
  }

  getSharedLayout(config) {
    var location = "";
    if (config.building && config.building.name) {
      location += config.building.name;
    }
    if (config.floor && config.floor.name) {
      if (location != "") {
        location += " - ";
      }
      location += config.floor.name;
    }
    return (
      <div>
        <div className={this.getClassBasedOnContent(config.configId)}>
          <div className={c.contentHeader}>Config ID</div>
          <div className={c.contentP}>{config.configId}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.domainId)}>
          <div className={c.contentHeader}>Domain ID</div>
          <div contentEditable="false" className={c.edtitableContentP}>{config.domainId}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.domainId)}>
          <div className={c.contentHeader}>Location</div>
          <div contentEditable="false" className={c.edtitableContentP}>{location}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.buildingId)}>
          <div className={c.contentHeader}>Building ID</div>
          <div contentEditable="false" className={c.edtitableContentP}>{config.buildingId}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.floorId)}>
          <div className={c.contentHeader}>Floor ID</div>
          <div contentEditable="false" className={c.edtitableContentP}>{config.floorId}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.timezone)}>
          <div className={c.contentHeader}>Time Zone</div>
          <div contentEditable="false" className={c.edtitableContentP}>{config.timezone}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.screenRotation)}>
          <div className={c.contentHeader}>Screen Rotation</div>
          <div contentEditable="false" className={c.edtitableContentP}>{config.screenRotation}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.screenResolution)}>
          <div className={c.contentHeader}>Screen Resolution</div>
          <div contentEditable="false" className={c.edtitableContentP}>{config.screenResolution}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.screenOffTime)}>
          <div className={c.contentHeader}>Screen Off Time</div>
          <div contentEditable="false" className={c.edtitableContentP}>{config.screenOffTime}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.screenOnTime)}>
          <div className={c.contentHeader}>Screen On Time</div>
          <div contentEditable="false" className={c.edtitableContentP}>{config.screenOnTime}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.configPollInterval)}>
          <div className={c.contentHeader}>Config Poll Interval</div>
          <div contentEditable="false" className={c.edtitableContentP}>{config.configPollInterval}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.rebootTime)}>
          <div className={c.contentHeader}>Reboot Time</div>
          <div contentEditable="false" className={c.edtitableContentP}>{config.rebootTime}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.dnsNameservers)}>
          <div className={c.contentHeader}>DNS Nameservers</div>
          <div contentEditable="false" className={c.edtitableContentP}>{config.dnsNameservers}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.proxyAddress)}>
          <div className={c.contentHeader}>Proxy Address</div>
          <div contentEditable="false" className={c.edtitableContentP}>{config.proxyAddress}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.environment)}>
          <div className={c.contentHeader}>Environment</div>
          <div contentEditable="false" className={c.edtitableContentP}>{config.environment}</div>
        </div>
        <div className={this.getClassBasedOnContent(config.floorplanUrl)}>
          <div className={c.contentHeader}>Floorplan Url</div>
          <div contentEditable="false" className={c.edtitableContentP}>{config.floorplanUrl}</div>
        </div>
      </div>
    )
  }
  getFloorplanLayout(config) {
    return (<div className={c.contentContainer}>
      <div className={c.innerContainer} style={{maxWidth: 700}}>
        <div className={this.getClassBasedOnContent(config.dnsNameservers)}>
          <div className={c.deviceHeader}> Config
          </div>
        </div>
        <div className={c.contentInset}>
          {this.getSharedLayout(config)}
          <div className={c.contentInnerContainer}>
            <div className={c.contentHeader}>Gateway Config</div>
          </div>
          <div className={c.configContainer}>
            <div className={this.getClassBasedOnContent(config.gatewayConfig.band)}>
              <div className={c.contentHeader}>Band</div>
              <div contentEditable="false" className={c.edtitableContentP}>{config.gatewayConfig.band}</div>
            </div>
            <div className={this.getClassBasedOnContent(config.gatewayConfig.frequency)}>
              <div className={c.contentHeader}>Frequency</div>
              <div contentEditable="false" className={c.edtitableContentP}>{config.gatewayConfig.frequency}</div>
            </div>
            <div className={this.getClassBasedOnContent(config.gatewayConfig.x)}>
              <div className={c.contentHeader}>X</div>
              <div contentEditable="false" className={c.edtitableContentP}>{config.gatewayConfig.x}</div>
            </div>
            <div className={this.getClassBasedOnContent(config.gatewayConfig.y)}>
              <div className={c.contentHeader}>Y</div>
              <div contentEditable="false" className={c.edtitableContentP}>{config.gatewayConfig.y}</div>
            </div>
          </div>
        </div>
      </div>
    </div>)
  }
  getTabletLayout(config) {
    return (<div className={c.contentContainer}>
      <div className={c.innerContainer} style={{maxWidth: 700}}>
        <div className={c.contentInnerContainer}>
          <div className={c.deviceHeader}> Config
          </div>
        </div>
        <div className={c.contentInset}>
          {this.getSharedLayout(config)}
          <div className={c.contentInnerContainer}>
            <div className={c.contentHeader}>Tablet Config</div>
          </div>
          <div className={c.configContainer}>
            <div className={this.getClassBasedOnContent(config.tabletConfig.roomId)}>
              <div className={c.contentHeader}>Room Id</div>
              <div contentEditable="false" className={c.edtitableContentP}>{config.tabletConfig.roomId}</div>
            </div>
            <div className={this.getClassBasedOnContent(config.tabletConfig.room.name)}>
              <div className={c.contentHeader}>Room Name</div>
              <div className={c.contentP}>{config.tabletConfig.room.name}</div>
            </div>
          </div>
        </div>
      </div>
    </div>)
  }
  getSensorLayout(config) {
    console.log(config)
    if (config.sensorConfig.seatId) {
      return (<div className={c.contentContainer}>
        <div className={c.innerContainer} style={{maxWidth: 700}}>
          <div className={c.contentInnerContainer}>
            <div className={c.deviceHeader}> Config
            </div>
          </div>
          <div className={c.contentInset}>
            {this.getSharedLayout(config)}
            <div className={c.contentInnerContainer}>
              <div className={c.contentHeader}>Sensor Config</div>
            </div>
            <div className={c.configContainer}>
              <div className={this.getClassBasedOnContent(config.sensorConfig.type)}>
                <div className={c.contentHeader}>Sensor Type</div>
                <div contentEditable="false" className={c.edtitableContentP}>{config.sensorConfig.type}</div>
              </div>
              <div className={this.getClassBasedOnContent(config.sensorConfig.seatId)}>
                <div className={c.contentHeader}>Seat Id</div>
                <div contentEditable="false" className={c.edtitableContentP}>{config.sensorConfig.seatId}</div>
              </div>
              <div className={this.getClassBasedOnContent(config.sensorConfig.seat.internalId)}>
                <div className={c.contentHeader}>Internal Id</div>
                <div className={c.contentP}>{config.sensorConfig.seat.internalId}</div>
              </div>
              <div className={this.getClassBasedOnContent(config.sensorConfig.address)}>
                <div className={c.contentHeader}>Address</div>
                <div contentEditable="false" className={c.edtitableContentP}>{config.sensorConfig.address}</div>
              </div>
              <div className={this.getClassBasedOnContent(config.sensorConfig.edgeId)}>
                <div className={c.contentHeader}>Gateway Id</div>
                <div contentEditable="false" className={c.edtitableContentP}>{config.sensorConfig.edgeId}</div>
              </div>
              <div className={this.getClassBasedOnContent(config.sensorConfig.x)}>
                <div className={c.contentHeader}>X</div>
                <div contentEditable="false" className={c.edtitableContentP}>{config.sensorConfig.x}</div>
              </div>
              <div className={this.getClassBasedOnContent(config.sensorConfig.y)}>
                <div className={c.contentHeader}>Y</div>
                <div contentEditable="false" className={c.edtitableContentP}>{config.sensorConfig.y}</div>
              </div>
            </div>
          </div>
        </div>
      </div>)
    } else {
      return (<div className={c.contentContainer}>
        <div className={c.innerContainer} style={{maxWidth: 700}}>
          <div className={c.contentInnerContainer}>
            <div className={c.deviceHeader}> Config
            </div>
          </div>
          <div className={c.contentInset}>
            {this.getSharedLayout(config)}
            <div className={c.contentInnerContainer}>
              <div className={c.contentHeader}>Sensor Config</div>
            </div>
            <div className={c.configContainer}>
              <div className={this.getClassBasedOnContent(config.sensorConfig.type)}>
                <div className={c.contentHeader}>Sensor Type</div>
                <div contentEditable="false" className={c.edtitableContentP}>{config.sensorConfig.type}</div>
              </div>
              <div className={this.getClassBasedOnContent(config.sensorConfig.roomId)}>
                <div className={c.contentHeader}>Room Id</div>
                <div contentEditable="false" className={c.edtitableContentP}>{config.sensorConfig.roomId}</div>
              </div>
              <div className={this.getClassBasedOnContent(config.sensorConfig.room.name)}>
                <div className={c.contentHeader}>Room Name</div>
                <div className={c.contentP}>{config.sensorConfig.room.name}</div>
              </div>
              <div className={this.getClassBasedOnContent(config.sensorConfig.address)}>
                <div className={c.contentHeader}>Address</div>
                <div contentEditable="false" className={c.edtitableContentP}>{config.sensorConfig.address}</div>
              </div>
              <div className={this.getClassBasedOnContent(config.sensorConfig.edgeId)}>
                <div className={c.contentHeader}>Gateway Id</div>
                <div contentEditable="false" className={c.edtitableContentP}>{config.sensorConfig.edgeId}</div>
              </div>
              <div className={this.getClassBasedOnContent(config.sensorConfig.x)}>
                <div className={c.contentHeader}>X</div>
                <div contentEditable="false" className={c.edtitableContentP}>{config.sensorConfig.x}</div>
              </div>
              <div className={this.getClassBasedOnContent(config.sensorConfig.y)}>
                <div className={c.contentHeader}>Y</div>
                <div contentEditable="false" className={c.edtitableContentP}>{config.sensorConfig.y}</div>
              </div>
            </div>
          </div>
        </div>
      </div>)
    }
  }


  sendRebootAction(accessToken, deviceId) {
    fetch(
      `${config.DEVICE_ACTIONS}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({deviceId, actionType: 'reboot'})
      })
      .then((r) => {
        window.alert('Reboot action sent!')
        this.state.loading = false
      })
  }
  onRebootClicked() {
    if (window.confirm('Are you sure you wish to reboot this device?')) {
      this.state.loading = true
      this.sendRebootAction(this.props.accessToken, this.state.device.deviceId)
    }
  }
  getFooter(deviceId) {
    return (
      <div className={c.horizontalContainer}>
        <div className={c.fullInnerContainer}>
          <div className={c.divider} />
          <button className={c.actionButton} onClick={(deviceId) => this.onRebootClicked(deviceId)}>
            Reboot
          </button>
        </div>
      </div>)
  }
  render() {
    if (!this.state.device || this.state.loading) {
      return (<div>
        <MainHeader>Device {this.props.deviceId}</MainHeader>
        <div className={css(styles.container)}>
          <div className={css(styles.containerPadding)} />
          <Loading />
        </div>
      </div>)
    } else if (this.state.device.deviceType && this.state.device.deviceType === 'floorplan') {
      return (<div>
        <MainHeader>Device {this.props.deviceId}</MainHeader>
        <div className={c.container}>
          <div className={css(styles.containerPadding)} />
          <div className={c.fullInnerContainer}>
            <div className={c.horizontalContainer}>
              {this.getDeviceLayout(this.state.device)}
              {this.getFloorplanLayout(this.state.device.deviceConfig)}
            </div>
            {this.getFooter(this.state.device.id)}
          </div>
        </div>
      </div>)
    } else if (this.state.device.deviceType && this.state.device.deviceType === 'tablet') {
      return (<div>
        <MainHeader>Device {this.props.deviceId}</MainHeader>
        <div className={c.container}>
          <div className={css(styles.containerPadding)} />
          <div className={c.fullInnerContainer}>
            <div className={c.horizontalContainer}>
              {this.getDeviceLayout(this.state.device)}
              {this.getTabletLayout(this.state.device.deviceConfig)}
            </div>
            {this.getFooter(this.state.device.id)}
          </div>
        </div>
      </div>)
    } else if (this.state.device.deviceType && this.state.device.deviceType === 'sensor') {
      return (<div>
        <MainHeader>Device {this.props.deviceId}</MainHeader>
        <div className={c.container}>
          <div className={css(styles.containerPadding)} />
          <div className={c.fullInnerContainer}>
            <div className={c.horizontalContainer}>
              {this.getDeviceLayout(this.state.device)}
              {this.getSensorLayout(this.state.device.deviceConfig)}
            </div>
            {this.getFooter(this.state.device.id)}
          </div>
        </div>
      </div>)
    }
  }
}

AdminDevice.propTypes = {
  accessToken: PropTypes.string.isRequired,
  deviceId: PropTypes.string.isRequired
}

export default AdminDevice
