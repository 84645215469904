import React from 'react'
import PropTypes from 'prop-types'
import LoginEmail from './login-email'
import LoginPin from './login-pin'
import LoginAuthenticationLoading from './login-authentication-loading'

const Login = (props) => {
  const {email, pin} = props

  if (email && pin) {
    return <LoginAuthenticationLoading {...props} />
  } else if (email) {
    return <LoginPin {...props} />
  } else {
    return <LoginEmail {...props} />
  }
}

Login.propTypes = {
  email: PropTypes.string,
  pin: PropTypes.string,
}

export default Login
