import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    padding: '0 0 40px',
  },
  padding: {
    flex: '0 1 60px',
  },
  content: {
    flex: '1 1 100%',
  },
})
