import React from 'react'
import {css} from 'aphrodite/no-important'
import styles from './import-new-status.styles'

const ImportNewStatue = () => {
  return (
    <div className={css(styles.container)}>
      <div className={css(styles.statusMessage)}>Loading...</div>
    </div>
  )
}

export default ImportNewStatue
