export default (parameters) => {
  const queryArguments = Object.keys(parameters)
    .filter((key) => {
      return Boolean(parameters[key])
    })
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(parameters[key])}`
    })
    .join('&')

  const newSearch = `?${queryArguments}`

  history.replaceState(null, null, newSearch)
}
