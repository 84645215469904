import config from 'config'

export default (accessToken, {userId, proximityId, deviceId}) => {
  fetch(config.FLOORPLAN_APP_EVENTS, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      proximityId,
      userId,
      deviceId,
      type: 'exit',
    }),
  })
    .then((r) => {
      return r.ok ? Promise.resolve() : Promise.reject()
    })
    .catch((e) => {
      alert('Failed to trigger floorplan exit event') // eslint-disable-line no-alert
      console.warn(e) // eslint-disable-line no-console
    })
}
