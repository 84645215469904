import './fonts/g_fonts.css'
import './css/g_default.css'

import React from 'react'
import PropTypes from 'prop-types'
import {Provider} from 'react-redux'
import Router from './containers/router'

const Root = ({store}) => {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  )
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

export default Root
