import * as types from '../action/types'

export default (action, r) => {
  if (action.type !== types.SET_LOCATION) {
    return
  }

  const routes = Array.isArray(r) ? r : [r]
  const path = action.payload.getIn(['location', 'pathname'])

  const matchingRoute = routes.find((route) => route.match(path))
  // TODO
  return matchingRoute && matchingRoute.match(path)
}
