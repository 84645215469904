import React from 'react'
import PropTypes from 'prop-types'
import * as _ from 'immutable'
import {connect} from 'react-redux'
import getClasses from '../utils/getClasses'
import Main from '../components/elements/main'
import AdminJobInfo from '../components/admin-job/admin-job-info'
import MainHeader from '../components/elements/main-header'
import Loading from './loading'

const c = getClasses({
  container: {
    width: '100%',
    // minHeight: 970,
    padding: '40px 0',
    background: '#fff',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.10)',
    flexDirection: 'row',
    display: 'flex',
    borderRadius: '3px',
  },
  containerPadding: {
    minWidth: '20px',
    flex: '0 1 40px',
  },
  divider: {
    display: 'block',
    width: '100%',
    height: 1,
    background: '#666',
    margin: '0 0 30px',
  },
})

class AdminJobContainer extends React.Component {
  render() {
    const {props} = this
    // debugger
    const {id} = props.job

    return props.isLoadingJobs ?
      <Loading /> :
      <Main>
        <MainHeader>jobs / {id}</MainHeader>
        <div className={c.container}>
          <div className={c.containerPadding} />
          <AdminJobInfo {...props} />
          <div className={c.containerPadding} />
        </div>
      </Main>
  }
}

AdminJobContainer.propTypes = {
  isLoadingJobs: PropTypes.bool.isRequired,
  job: PropTypes.object.isRequired,
  jobId: PropTypes.string.isRequired,
  users: PropTypes.instanceOf(_.Map).isRequired,
}

const mapStateToProps = (state, {jobId}) => {
  return {
    // will get stuck on loading if there are no jobs
    isLoadingJobs: !state.getIn(['jobs', 'all'], _.Map()).size,
    users: state.get('users', _.Map()),
    job: (state.getIn(['jobs', 'all'], _.List())
      .find((job) => {
        return job.get('id') === jobId
      }) || _.Map()).toJS(),
  }
}

export default connect(mapStateToProps, {})(AdminJobContainer)
