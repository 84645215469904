module.exports = {
  API_URL: 'https://api.nimway.com',
  AUTH_URL: 'https://auth.nimway.com',
  GRAPHQL: 'https://api.nimway.com/graphql',
  SENSOR_EVENTS_URL: 'https://api.nimway.com/sensorevents/',
  DOMAIN_IMPORT: 'https://api.nimway.com/domainimport/jobs',
  DOMAIN_VERSIONS: 'https://api.nimway.com/admin/domainimport/domainversions',
  FLOORPLAN_APP_EVENTS: 'https://proximity.nimway.com/floorplanappevents',
  DEVICE_ACTIONS: 'https://gateway.nimway.com/deviceaction',
  USER_URL: 'https://user.nimway.com',
}
