import {StyleSheet} from 'aphrodite/no-important'

const skRotate = {
  '100%': {
    transform: 'rotate(360deg)',
  },
}

const skBounce = {
  '0%': {
    transform: 'scale(0.0)',
  },
  '100%': {
    transform: 'scale(0.0)',
  },
  '50%': {
    transform: 'scale(1.0)',
  },
}

export default StyleSheet.create({
  spinner: {
    margin: '100px auto',
    width: '40px',
    height: '40px',
    position: 'relative',
    textAlign: 'center',
    animationName: skRotate,
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
  dot1: {
    width: '60%',
    height: '60%',
    display: 'inline-block',
    position: 'absolute',
    top: '0',
    backgroundColor: '#333',
    borderRadius: '100%',
    animationName: skBounce,
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
  },
  dot2: {
    width: '60%',
    height: '60%',
    display: 'inline-block',
    position: 'absolute',
    top: 'auto',
    backgroundColor: '#333',
    borderRadius: '100%',
    bottom: '0',
    animationName: skBounce,
    animationDuration: '2s',
    animationDelay: '-1.0s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
  },
})
