import React from 'react'
import PropTypes from 'prop-types'
import * as _ from 'immutable'
import {connect} from 'react-redux'
import getClasses from '../utils/getClasses'
import Main from '../components/elements/main'
import AdminVersionInfo from '../components/admin-version/admin-version-info'
import AdminVersionPublish from '../components/admin-version/admin-version-publish'
import MainHeader from '../components/elements/main-header'
import updateVersion from '../action/creators/update-version'
import Loading from './loading'

const c = getClasses({
  container: {
    width: '100%',
    // minHeight: 970,
    padding: '40px 0',
    background: '#fff',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.10)',
    flexDirection: 'row',
    display: 'flex',
    borderRadius: '3px',
  },
  containerPadding: {
    minWidth: '20px',
    flex: '0 1 40px',
  },
  innerContainer: {
    width: '100%',
  },

  divider: {
    display: 'block',
    width: '100%',
    height: 1,
    background: '#666',
    margin: '0 0 30px',
  },
})

class AdminVersionContainer extends React.Component {
  render() {
    const {props} = this
    const {version: {domainId, id}} = props

    return props.isLoadingVersions ?
      <Loading /> :
      <Main>
        <MainHeader>domains / {domainId} / versions / {id}</MainHeader>
        <div className={c.container}>
          <div className={c.containerPadding} />
          <div className={c.innerContainer}>
            <AdminVersionInfo {...props} />
            <div className={c.divider} />
            <AdminVersionPublish {...props} />
          </div>
          <div className={c.containerPadding} />
        </div>
      </Main>
  }
}

AdminVersionContainer.propTypes = {
  accessToken: PropTypes.string.isRequired,
  channels: PropTypes.array.isRequired,
  isLoadingVersions: PropTypes.bool.isRequired,
  users: PropTypes.instanceOf(_.Map).isRequired,
  version: PropTypes.instanceOf(_.Map).isRequired,
}

const mapStateToProps = (state, {versionId}) => {
  return {
    // will get stuck on loading if there are no domains with versions
    isLoadingVersions: !state.getIn(['versions', 'all'], _.Map()).size,
    accessToken: state.getIn(['session', 'access_token'], ''),
    channels: state.getIn(['channels', 'all'], _.List())
      .map((channel) => channel.get('id')).toJS(),
    users: state.get('users', _.Map()),
    version: (state.getIn(['versions', 'all'], _.List())
      .flatMap((domains) => {
        return domains.get('versions').map((version) => {
          return version.merge({domainId: domains.get('id')})
        })
      })
      .find((version) => {
        return version.get('id') === versionId
      }) || _.Map()).toJS(),
  }
}

export default connect(mapStateToProps, {updateVersion})(AdminVersionContainer)
