import React from 'react'
// import PropTypes from 'prop-types'
// import * as _ from 'immutable'
import format from 'date-fns/format'
import getClasses from '../../utils/getClasses'
// import ReactJson from 'react-json-view'

const c = getClasses({
  contentContainer: {
    width: '100%',
  },
  innerContainer: {
    width: '50%',
    paddingBottom: 10,
  },
  contentInnerContainer: {
    paddingBottom: 20,
  },
  contentHeader: {
    fontSize: '14px',
    lineHeight: '30px',
    fontWeight: '700',
    color: '#777',
  },
  contentInset: {
    display: 'inline-block',
    margin: '20px 0 20px 0',
    padding: 20,
    background: '#F6F8FA',
  },
  contentP: {
    display: 'block',
    fontSize: '18px',
    lineHeight: '30px',
    // paddingBottom: 20,
    overflow: 'hidden',
  },
  contentCode: {
    whiteSpace: 'pre-wrap',
  },
  divider: {
    display: 'block',
    width: '100%',
    height: 1,
    background: '#666',
    margin: '0 0 30px',
  },
})

const getFormattedTime = (time) => {
  return format(time, 'YYYY-MM-DD HH:mm Z')
}

const AdminJobInfo = ({
  job,
}) => {
  const {
    command,
    channelId,
    createdAt,
    modifiedAt,
    domainId,
    gitBranch,
    gitCommitMessage,
    gitCommitSha,
    id,
    user,
    error,
  } = job
  const {fullname, email} = user || {}

  return (
    <div className={c.contentContainer}>
      <div className={c.contentInnerContainer}>
        <div className={c.contentHeader}>Job ID</div>
        <div className={c.contentP}>{id}</div>
      </div>
      <div className={c.contentInnerContainer}>
        <div className={c.contentHeader}>Created</div>
        <div className={c.contentP}>{getFormattedTime(createdAt)}</div>
      </div>
      <div className={c.contentInnerContainer}>
        <div className={c.contentHeader}>Modified</div>
        <div className={c.contentP}>{getFormattedTime(modifiedAt)}</div>
      </div>
      <div className={c.contentInnerContainer}>
        <div className={c.contentHeader}>Command</div>
        <div className={c.contentP}>{command}</div>
      </div>
      <div className={c.contentInnerContainer}>
        <div className={c.contentHeader}>Channel ID</div>
        <div className={c.contentP}>{channelId}</div>
      </div>
      <div className={c.contentInnerContainer}>
        <div className={c.contentHeader}>Domain ID</div>
        <div className={c.contentP}>{domainId}</div>
      </div>
      <div className={c.divider} />
      <div className={c.contentInnerContainer}>
        <div className={c.contentHeader}>Git Branch</div>
        <div className={c.contentP}>{gitBranch}</div>
      </div>
      <div className={c.contentInnerContainer}>
        <div className={c.contentHeader}>Git Commit</div>
        <a
          className={c.contentP}
          href={`https://github.com/Nimway/so-domain-data/commit/${gitCommitSha}`}
        >{gitCommitSha}</a>
      </div>
      <div className={c.contentInnerContainer}>
        <div className={c.contentHeader}>Git Commit Message</div>
        <div className={c.contentP}>{gitCommitMessage ? gitCommitMessage : '-'}</div>
      </div>
      <div className={c.divider} />
      <div className={c.contentInnerContainer}>
        <div className={c.contentHeader}>Job created by</div>
        {
          fullname ?
            <div className={c.contentP}>{fullname} &lt;{email}&gt;</div> :
            <div className={c.contentP}>–</div>
        }
      </div>
      {
        error &&
          <React.Fragment>
            <div id="error" className={c.divider} />
            <div className={c.contentInnerContainer}>
              <div className={c.contentHeader}>Error</div>
              <div className={`${c.contentP} noColumn`}>
                <pre className={c.contentCode}>{error}</pre>
              </div>
            </div>
          </React.Fragment>
      }
    </div>
  )
}

export default AdminJobInfo
