import * as _ from 'immutable'
import setLocation from '../action/creators/set-location'
import {put, call} from 'redux-saga/effects'

const listen = (history) => {
  return new Promise((resolve) => {
    history.listen((location, action) => {
      resolve(_.fromJS({location, action}))
    })
  })
}

export default function* (history) {
  while (true) {
    // eslint-disable-line no-constant-condition
    const locationEvent = yield call(listen, history)
    yield put(setLocation(locationEvent))
  }
}
