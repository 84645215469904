import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'aphrodite/no-important'
import LoginHeader from './elements/login-header'
import LoginButton from './elements/login-button'
import styles from './login-pin.styles'

const LoginPin = ({authenticateError, email, setPin, removeEmail}) => {
  return (
    <form
      className={css(styles.form)}
      onSubmit={function (e) {
        e.preventDefault()
        setPin(e.currentTarget[0].value)
      }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        margin: '0 auto',
        paddingTop: '100px',
        paddingLeft: '120px',
        paddingBottom: '120px',
        paddingRight: '120px',
      }}
    >
      <LoginHeader>Sign In</LoginHeader>
      <div className={css(styles.email)}>{email}</div>
      <label className={css(styles.pinLabel)}>
        <div className={css(styles.pin)}>Pin</div>
        <input className={css(styles.pinInput)} autoFocus autoComplete="off" required />
      </label>
      {authenticateError && <div className={css(styles.error)}>{authenticateError}</div>}
      <div style={{height: '100%'}} />
      <div className={css(styles.changeEmailContainer)}>
        <a
          href="#"
          className={css(styles.link)}
          onClick={function (e) {
            e.preventDefault()
            removeEmail()
          }}
        >
          Change E-mail
        </a>
      </div>
      <div
        className={css(styles.loginButtonContainer)} style={{
          marginTop: '48px',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <LoginButton>Sign In</LoginButton>
      </div>
    </form>
  )
}

LoginPin.propTypes = {
  authenticateError: PropTypes.string,
  email: PropTypes.string.isRequired,
  removeEmail: PropTypes.func.isRequired,
  setPin: PropTypes.func.isRequired,
}

export default LoginPin
