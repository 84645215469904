import * as _ from 'immutable'
import {delay} from 'redux-saga'
import config from 'config'
import setSensorEventsRoom from '../action/creators/set-sensor-events-room'
import setSensorEventsRoomLoadingStart from '../action/creators/set-sensor-events-room-loading-start'
import setSensorEventsRoomLoadingEnd from '../action/creators/set-sensor-events-room-loading-end'
import clearSensorEventsRoom from '../action/creators/clear-sensor-events-room'
import renewAccessToken from '../action/creators/renew-access-token'
import * as types from '../action/types'
import {adminSensorEventsRoomIdRoute} from '../routes'
import {getAccessToken} from '../selectors'
import rejectOnGraphqlErrors from '../utils/reject-on-graphql-errors'
import navigateTo from '../utils/navigate-to'
import navigateFrom from '../utils/navigate-from'
import {call, cancel, fork, put, select, take, race} from 'redux-saga/effects'

const fetchSensorEvents = (accessToken, roomId) => {
  return fetch(`${config.API_URL}/sensorevents/?roomId=${roomId}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then((r) => {
      return r.json()
    })
    .then(rejectOnGraphqlErrors)
}

const backgroundFetch = function* (roomId) {
  while (true) {
    // eslint-disable-line no-constant-condition
    const accessToken = yield select(getAccessToken)

    if (accessToken) {
      try {
        const sensorEvents = yield call(fetchSensorEvents, accessToken, roomId)

        yield put(setSensorEventsRoom(_.fromJS(sensorEvents)))
      } catch (e) {
        console.error('[catch] watch-admin-navigate-load-sensor-events', e) // eslint-disable-line no-console
        const token = yield select(getAccessToken)

        if (token) {
          yield put(renewAccessToken())
        }
      }
      yield put(setSensorEventsRoomLoadingEnd())
    }

    yield race({
      updateOnNewAccessToken: take(types.SET_EXPIRE_IN),
      continue: call(delay, 1000 * 10),
    })
  }
}

export default function* () {
  while (true) {
    // eslint-disable-line no-constant-condition
    const action = yield take((a) => {return navigateTo(a, adminSensorEventsRoomIdRoute)})

    yield put(setSensorEventsRoomLoadingStart())
    const path = action.payload.getIn(['location', 'pathname'])
    const {roomId} = adminSensorEventsRoomIdRoute.match(path)
    const backgroundFetchTask = yield fork(backgroundFetch, roomId)
    yield take((a) => {return navigateFrom(a, adminSensorEventsRoomIdRoute)})
    yield cancel(backgroundFetchTask)
    yield put(clearSensorEventsRoom())
  }
}
