import {StyleSheet} from 'aphrodite/no-important'

export default StyleSheet.create({
  form: {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  input: {
    width: 150,
    height: 40,
    marginLeft: 20,
    padding: '0 10px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '40px',
    color: '#777',
    background: '#fff',
    boxShadow: '0 1px 3px rgba(77,77,77,0.1)',
    ':-ms-input-placeholder': {
      color: '#d6d6d6',
    },
    '::-moz-placeholder': {
      color: '#d6d6d6',
    },
    '::-webkit-input-placeholder': {
      color: '#d6d6d6',
    },
    ':hover': {
      opacity: '0.8',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
    ':focus': {
      opacity: '1',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
  },
  button: {
    width: '80px',
    height: '40px',
    marginLeft: 20,
    padding: '0',
    borderRadius: '4px',
    fontSize: '13px',
    lineHeight: '40px',
    color: '#fff',
    fontWeight: '700',
    textTransform: 'uppercase',
    backgroundColor: '#12aaeb',
    boxShadow: '0 1px 3px rgba(77,77,77,0.1)',
    cursor: 'pointer',
    ':hover': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
    ':focus': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
    ':active': {
      opacity: '0.9',
      boxShadow: '0 1px 3px rgba(22,22,22,0.1)',
    },
  },
})
