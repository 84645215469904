import React from 'react'
// import PropTypes from 'prop-types'
// import * as _ from 'immutable'
import format from 'date-fns/format'
import getClasses from '../../utils/getClasses'

const c = getClasses({
  contentContainer: {
    width: '100%',
  },
  contentInnerContainer: {
    paddingBottom: 20,
  },
  contentHeader: {
    fontSize: '14px',
    lineHeight: '30px',
    fontWeight: '700',
    color: '#777',
  },
  contentInset: {
    display: 'inline-block',
    margin: '20px 0 20px 0',
    padding: 20,
    background: '#F6F8FA',
  },
  contentP: {
    display: 'block',
    fontSize: '18px',
    lineHeight: '30px',
    // paddingBottom: 20,
  },
  divider: {
    display: 'block',
    width: '100%',
    height: 1,
    background: '#666',
    margin: '0 0 30px',
  },
})

const getFormattedTime = (time) => {
  return format(time, 'YYYY-MM-DD HH:mm Z')
}

const AdminPublicationInfo = ({
  publication,
  users,
}) => {
  const channelId = publication.get('channelId')
  const createdAt = publication.get('createdAt')

  const domainId = publication.getIn(['version', 'domainId'])
  // const gitCommitSha = publication.getIn(['version', 'gitCommitSha'])
  // const gitCommitMessage = publication.getIn(['version', 'gitCommitMessage'])

  const fullname = publication.getIn(['user', 'fullname'])
  const email = publication.getIn(['user', 'email'])
  // const avatarUrl = publication.getIn(['user', 'avatarUrl'])

  return (
    <div className={c.contentContainer}>
      <div className={c.contentInnerContainer}>
        <div className={c.contentHeader}>Domain ID</div>
        <div className={c.contentP}>{domainId}</div>
      </div>
      <div className={c.contentInnerContainer}>
        <div className={c.contentHeader}>Channel ID</div>
        <div className={c.contentP}>{channelId}</div>
      </div>
      <div className={c.contentInnerContainer}>
        <div className={c.contentHeader}>Published at</div>
        <div className={c.contentP}>{getFormattedTime(createdAt)}</div>
      </div>
      <div className={c.contentInnerContainer}>
        <div className={c.contentHeader}>Published by</div>
        {
          fullname ?
            <div className={c.contentP}>{fullname} &lt;{email}&gt;</div> :
            <div className={c.contentP}>–</div>
        }
      </div>
    </div>
  )
}

export default AdminPublicationInfo
