// import util from 'util'
import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import config from 'config'
import {css, StyleSheet} from 'aphrodite/no-important'
import {getAccessToken} from '../selectors'
import Loading from '../components/elements/loading'

const styles = StyleSheet.create({
  userAnalyticsInfo: {
    margin: 10,
    background: '#fff',
    padding: 20,
    boxShadow: '2px 2px 3px #ccc',
  },
  userAnalyticsLink: {
    margin: 10,
    background: '#fff',
    padding: 20,
    boxShadow: '2px 2px 3px #ccc',
    borderRadius: '4px',
    textTransform: 'uppercase',
    display: 'inline-block',
    color: '#46aaeb',
    fontWeight: '700',
    border: '2px solid #ccc',
  }
})

const fetchUserAnalyticsLinks = (accessToken) => {
  return fetch(
    `${config.API_URL}/analytics?type=userStatistics`,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    },
  )
    .then((response) => {
      return response.json()
    })
}

var accessToken

function AdminUserAnalyticsContainer(props) {
  accessToken = props.accessToken
  const [userAnalyticsLinks, setUserAnalyticsLinks] = useState({})
  // const [, setIsLoadingUserAnalyticsLinks] = useState(false)
  const [isLoadingUserAnalyticsLinks, setIsLoadingUserAnalyticsLinks] = useState(false)

  async function loadUserAnalyticsLinks() {
    setIsLoadingUserAnalyticsLinks(true)
    const newUserAnalyticsLinks = await fetchUserAnalyticsLinks(props.accessToken)
    setUserAnalyticsLinks(newUserAnalyticsLinks)
    setIsLoadingUserAnalyticsLinks(false)
  }

  useEffect(() => {
    loadUserAnalyticsLinks()
  }, [])

  return (
    <div>
      <Loading isLoading={isLoadingUserAnalyticsLinks} />
      <div className={css(styles.userAnalyticsInfo)}>
        <p>User statistics based on application usage, computed daily. Users can be in a few different states, New, Continue, Churn, Return and Lost. A user is considered :</p>
        <ul>
          <li><b>New</b> if first loggin is in the past 7 days.</li>
          <li><b>Continue</b> if active in the past 7 days and active 7 to 14 days ago.</li>
          <li><b>Churn</b> if not active in the past 7 days, but active in the past 14 days</li>
          <li><b>Return</b> if active in the past 7 days, but not active 7 to 14 days ago.</li>
          <li><b>Lost</b> if not active in the past 14 days</li>
        </ul>
        <p>A user can be in only one category. Active users is the sum of users in New, Continue and Return.</p>
        <p>An example of excel can be found on <a href={'https://sony.sharepoint.com/sites/Nimway1/Shared%20Documents/Forms/AllItems.aspx?viewid=8faf3829%2Dcf6e%2D44ac%2Da283%2Db2ff617c254b&id=%2Fsites%2FNimway1%2FShared%20Documents%2FSony%20Map%2FAnalytics%2FUser%20Statistics'}>sharepoint</a></p>
      </div>
      {userAnalyticsLinks.url ? (
        <a href={userAnalyticsLinks.url} className={css(styles.userAnalyticsLink)}>Download user analytics</a>
      ) : ''}
    </div>
  )
}

AdminUserAnalyticsContainer.propTypes = {
  accessToken: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
  return {
    accessToken: getAccessToken(state),
  }
}

export default connect(mapStateToProps, {})(AdminUserAnalyticsContainer)
