import * as _ from 'immutable'
import {delay} from 'redux-saga'
import {call, cancel, fork, put, select, take, race} from 'redux-saga/effects' // eslint-disable-line
import config from 'config' // eslint-disable-line
import setSensorEvents from '../action/creators/set-sensor-events'
import setSensorEventsLoadingStart from '../action/creators/set-sensor-events-loading-start'
import setSensorEventsLoadingEnd from '../action/creators/set-sensor-events-loading-end'
import clearSensorEvents from '../action/creators/clear-sensor-events'
import renewAccessToken from '../action/creators/renew-access-token'
import * as types from '../action/types'
import {adminSensorEventsRoute} from '../routes'
import {getAccessToken} from '../selectors'
import navigateTo from '../utils/navigate-to'
import navigateFrom from '../utils/navigate-from'

const fetchSensorEvents = (accessToken, floorId) => {
  return fetch(`${config.API_URL}/sensorevents/?floorId=${floorId}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then((r) => {
      return r.ok ? r : Promise.reject(new Error('fetch failed'))
    })
    .then((r) => {
      return r.json()
    })
}

const backgroundFetch = function* (floorId) {
  while (true) {
    // eslint-disable-line no-constant-condition
    const accessToken = yield select(getAccessToken)
    if (accessToken) {
      try {
        const sensorEvents = yield call(fetchSensorEvents, accessToken, floorId)
        // SET_SENSOR_EVENTS_LOADING_START
        // SET_SENSOR_EVENTS_LOADING_END
        // console.log('http://localhost:4000/admin/sensor-events/floor/376', sensorEvents)
        yield put(setSensorEvents(_.fromJS(sensorEvents)))
      } catch (e) {
        console.error('[catch] watch-admin-navigate-load-sensor-events', e) // eslint-disable-line no-console
        const token = yield select(getAccessToken)

        if (token) {
          yield put(renewAccessToken())
        }
      }
    }
    yield put(setSensorEventsLoadingEnd())
    yield race({
      updateOnNewAccessToken: take(types.SET_EXPIRE_IN),
      continue: call(delay, 1000 * 10),
    })
  }
}

export default function* () {
  while (true) {
    // eslint-disable-line no-constant-condition
    const action = yield take((a) => {return navigateTo(a, adminSensorEventsRoute)})
    yield put(setSensorEventsLoadingStart())
    const path = action.payload.getIn(['location', 'pathname'])
    const {floorId} = adminSensorEventsRoute.match(path)
    const backgroundFetchTask = yield fork(backgroundFetch, floorId)
    yield take((a) => {return navigateFrom(a, adminSensorEventsRoute)})
    yield cancel(backgroundFetchTask)
    yield put(clearSensorEvents())
  }
}
