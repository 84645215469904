import * as _ from 'immutable'
import * as types from '../action/types'

export default (state = _.Map(), action) => {
  switch (action.type) {
    case types.SET_VERSIONS:
      return state.set('versions', action.payload)
    default:
      return state
  }
}
